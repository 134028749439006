import { useState, useEffect, useContext } from 'react';
import Context from '../context/UserContext';
import { UserContextType } from '../models/User';
import { fetchDomainCount } from '../helpers/unsubscribed';

const useDomainCounter = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [loading, setLoading] = useState(false);
  const [countResult, setCountResult] = useState(0);

  const getCounter = () => {
    setLoading(true);
    fetchDomainCount(UserContext.id)
      .then(res => setCountResult(res.total))
      .catch(err => setCountResult(0))
      .finally(() => setLoading(false));
  };

  useEffect(getCounter, []);

  return { countResult, loading };
};

export default useDomainCounter;
