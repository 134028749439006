import { create } from 'zustand';
import { FiltersInterface, filtersObjUnclaimed } from '../../models/Filters';

interface FiltersUnclaimedStore {
  filterValuesDefault: FiltersInterface;
  filterValuesChange: FiltersInterface;
  updateFiltersChange: (obj: FiltersInterface) => void;
  updateFiltersDefault: (obj: FiltersInterface) => void;
}

const useFiltersUnclaimed = create<FiltersUnclaimedStore>(set => ({
  filterValuesDefault: filtersObjUnclaimed,
  filterValuesChange: filtersObjUnclaimed,
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersUnclaimed;
