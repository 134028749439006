import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import BoxAddSettings from '../BoxAddSettings';
import { TextFormField } from '../FormInputs';
import Spinner from '../../Loading/Spinner';
import { getTemplateTranslate } from '../../../helpers/templates';

interface Props {
  templateId: number;
  url: string;
  link_id: number;
  contact_name?: string;
}

const TemplateTab = ({ templateId, url, contact_name, link_id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    title: '',
    subject: '',
    message: '',
  });

  const translateTemplate = async () => {
    setIsLoading(true);
    try {
      const resp = await getTemplateTranslate(
        templateId,
        encodeURIComponent(url),
        link_id,
        encodeURIComponent(contact_name ?? ''),
      );
      setTemplateInfo({ ...resp, message: resp.body });
    } catch (err) {
      setTemplateInfo({ title: '', subject: '', message: '' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (templateId !== 0) translateTemplate();

    return () => {
      setTemplateInfo({ title: '', subject: '', message: '' });
    };
  }, [templateId, url]);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && templateId === 0 && (
        <Typography padding={2} fontSize={14}>
          You have to select a template first
        </Typography>
      )}
      {!isLoading && templateId !== 0 && (
        <BoxAddSettings title="Template Preview">
          <div className="form-grids">
            <div className="whole-grid">
              <TextFormField
                required
                disabled
                nameInput="title"
                label="Title"
                error={false}
                value={templateInfo.title}
                errorText=""
                placeholder="Title"
                onChange={() => {}}
              />
            </div>
            <div className="whole-grid">
              <TextFormField
                required
                disabled
                nameInput="subject"
                label="Subject"
                error={false}
                value={templateInfo.subject}
                errorText=""
                placeholder="Subject"
                onChange={() => {}}
              />
            </div>
            <div className="whole-grid">
              <TextFormField
                required
                multiline
                disabled
                nameInput="message"
                label="Message"
                error={false}
                rows={12}
                value={templateInfo.message}
                errorText=""
                placeholder=""
                onChange={() => {}}
              />
            </div>
          </div>
        </BoxAddSettings>
      )}
    </>
  );
};

export default TemplateTab;
