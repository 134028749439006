import { Box } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { createClient, updateClient } from '../../../helpers/clients';
import { fetchStates, fetchCities } from '../../../helpers/country';
import {
  onlyNumbers,
  validateLengthInputs,
  validateUrlInputs,
} from '../../../helpers/validateInputs';
import { ClientsForm, CreateClientInt } from '../../../models/Clients';
import { StateInt } from '../../../models/Country';
import {
  BasicArray,
  SeverityType,
  statusOptions,
  tabs,
} from '../../../models/General';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import FeedbackMessage from '../../FeedbackMessage';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import BoxAddSettings from '../BoxAddSettings';
import { BasicSelect, SelectFormInput, TextFormField } from '../FormInputs';
import StatusModal from '../StatusModal';
import FormNotes from '../FormNotes';
import FormStatus from '../FormStatus';
import { PropsClientForm } from '../../../models/Forms';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import useNotes from '../../../customHooks/useNotes';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { ROLES_CLIENTS } from '../../../constants/user';

const AddClient = ({
  setOpenDrawer,
  type,
  clientObj,
  createSuccess,
}: PropsClientForm) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [clientForm, setClientForm] = useState<ClientsForm>(
    clientObj || {
      name: '',
      address_1: '',
      address_2: '',
      city: { name: '', state: { id: 0, name: '' } },
      zip_code: '',
      primary_phone: '',
      international_data: '',
      url: '',
      primary_phone_extension: '',
      text_note: '',
    },
  );

  const [tabDrawer, setTabDrawer] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [messagesProps, setMessagesProps] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'error',
  });

  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: clientForm.id ?? 0,
    type: 'client',
  });
  const { history } = useHistoryTab({ id: clientForm.id ?? 0, type: 'client' });

  const [openMessage, setOpenMessage] = useState(false);
  const [originalState, setOriginalState] = useState('');
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [inputsErrors, setInputsErrors] = useState<number[]>([]);
  const [statesOptions, setStatesOptions] = useState<StateInt[]>([]);
  const [citiesOptions, setCitiesOptions] = useState<StateInt[]>([]);
  const [loading, setLoading] = useState({ state: false, city: false });
  const [disableButton, setDisableButton] = useState(true);

  const canEdit =
    type === 'edit'
      ? !sendBackObject(UserContext.profile.role, ROLES_CLIENTS).edit
      : !sendBackObject(UserContext.profile.role, ROLES_CLIENTS).add;

  const inputsArr = [
    {
      id: 0,
      required: true,
      value: clientForm.name,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="name"
          label="Name"
          error={false}
          value={clientForm.name}
          errorText=""
          placeholder="Name"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, name: value })
          }
        />
      ),
    },
    {
      id: 1,
      value: clientForm.address_1,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="address"
          label="Address"
          error={false}
          value={clientForm.address_1}
          errorText=""
          placeholder="Address"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, address_1: value })
          }
        />
      ),
    },
    {
      id: 2,
      value: clientForm.address_2,
      required: false,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <TextFormField
          disabled={canEdit}
          nameInput="address_2"
          label="Address 2"
          error={false}
          value={clientForm.address_2}
          errorText=""
          placeholder="Address 2"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, address_2: value })
          }
        />
      ),
    },
    {
      id: 3,
      value: clientForm.city.state.name,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <SelectFormInput
          required
          options={statesOptions}
          clearIcon
          disabled={loading.state || canEdit}
          placeholder="State"
          value={{
            id: clientForm.city.state.id || 0,
            name: clientForm.city.state.name,
          }}
          onChange={(value: BasicArray | null) => {
            setClientForm({
              ...clientForm,
              city: { ...clientForm.city, state: value ?? { id: 0, name: '' } },
            });
          }}
        />
      ),
    },
    {
      id: 4,
      value: clientForm.city.name,
      required: true,
      fullSpace: false,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          required
          clearIcon
          options={citiesOptions}
          disabled={
            loading.city || clientForm.city.state.name === '' || canEdit
          }
          placeholder="City"
          value={{
            id: clientForm.city.id || 0,
            name: clientForm.city.name,
          }}
          onChange={(value: BasicArray | null) => {
            setClientForm({
              ...clientForm,
              city: {
                ...clientForm.city,
                id: value ? (value.id as number) : 0,
                name: value ? value.name : '',
              },
            });
          }}
        />
      ),
    },
    {
      id: 5,
      value: clientForm.zip_code,
      required: true,
      fullSpace: false,
      validate: true,
      validation: validateLengthInputs(
        5,
        5,
        clientForm.zip_code,
        inputsErrors,
        9,
      ),
      body: (
        <TextFormField
          disabled={canEdit}
          nameInput="zip"
          label="Zip"
          onBlurValidate={() => {
            const validation = validateLengthInputs(
              5,
              5,
              clientForm.zip_code,
              inputsErrors,
              9,
            );
            setInputsErrors(validation);
          }}
          required
          error={inputsErrors.includes(5)}
          value={clientForm.zip_code}
          errorText={inputsErrors.includes(5) ? 'Insert valid Zip' : ''}
          placeholder="Zip"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, zip_code: onlyNumbers(value) })
          }
        />
      ),
    },
    {
      id: 6,
      value: clientForm.primary_phone_extension,
      required: false,
      fullSpace: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          disabled={canEdit}
          nameInput="primary_phone_extension"
          label="+4"
          error={false}
          value={clientForm.primary_phone_extension}
          errorText=""
          placeholder="+4"
          onChange={(value: string) =>
            setClientForm({
              ...clientForm,
              primary_phone_extension: onlyNumbers(value),
            })
          }
        />
      ),
    },
    {
      id: 7,
      value: clientForm.url,
      required: true,
      validate: true,
      fullSpace: true,
      validation: validateUrlInputs(clientForm.url, 7, inputsErrors),
      body: (
        <TextFormField
          disabled={canEdit}
          required
          nameInput="website"
          onBlurValidate={() => {
            const validate = validateUrlInputs(clientForm.url, 7, inputsErrors);
            setInputsErrors(validate);
          }}
          label="URL"
          error={inputsErrors.includes(7)}
          value={clientForm.url}
          errorText={inputsErrors.includes(7) ? 'Insert a valid URL' : ''}
          placeholder="URL"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, url: value })
          }
        />
      ),
    },
    {
      id: 8,
      value: clientForm.primary_phone,
      required: false,
      validate: true,
      validation: validateLengthInputs(
        10,
        8,
        clientForm.primary_phone,
        inputsErrors,
      ),
      fullSpace: true,
      body: (
        <TextFormField
          disabled={canEdit}
          nameInput="phone"
          label="Primary Phone Number"
          onBlurValidate={() => {
            const validation = validateLengthInputs(
              10,
              8,
              clientForm.primary_phone,
              inputsErrors,
            );
            setInputsErrors(validation);
          }}
          error={inputsErrors.includes(8)}
          value={clientForm.primary_phone}
          errorText={
            inputsErrors.includes(8) ? 'Insert a valid phone number' : ''
          }
          placeholder="Primary Phone Number"
          onChange={(value: string) =>
            setClientForm({ ...clientForm, primary_phone: onlyNumbers(value) })
          }
        />
      ),
    },
    {
      id: 10,
      value: clientForm.international_data || '',
      required: false,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <TextFormField
          disabled={canEdit}
          nameInput="international_data"
          label="International Address and/or Phone"
          error={false}
          multiline
          required={false}
          value={clientForm.international_data || ''}
          errorText=""
          placeholder="International Address and/or Phone"
          onChange={(value: string) =>
            setClientForm({
              ...clientForm,
              international_data: value,
            })
          }
        />
      ),
    },
  ];

  if (type === 'edit') {
    inputsArr.splice(1, 0, {
      id: 9,
      value: clientForm.status || '',
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <BasicSelect
          label="Status"
          required
          nameInput="status"
          disabled={canEdit}
          value={clientForm.status || ''}
          options={statusOptions}
          onChange={(value: string) => {
            if (value !== originalState) setOpenModal(true);
            else {
              setClientForm({ ...clientForm, status: value, text_note: '' });
            }
          }}
        />
      ),
    });
  }

  const updateStatus = (note: string) => {
    const newStatus = clientForm.status === 'active' ? 'inactive' : 'active';
    setClientForm({ ...clientForm, text_note: note, status: newStatus });
    setOpenModal(false);
  };

  const saveClient = async (obj: CreateClientInt) => {
    setLoadingCreate(true);
    try {
      if (type === 'add') await createClient(obj);
      if (type === 'edit') await updateClient(obj, clientForm.id ?? 0);
      setOpenDrawer(false);
      createSuccess(
        type === 'add'
          ? 'Client created succesfully'
          : 'Client updated succesfully',
        'client',
      );
    } catch (err) {
      setMessagesProps({
        severity: 'error',
        message: 'There was an error, please try again',
      });
      setOpenMessage(true);
    } finally {
      setLoadingCreate(false);
    }
  };

  const validationAll = () => {
    const purge = inputsArr
      .filter(item => item.value !== '' && item.validate)
      .map(item => item.validation)
      .flat();

    if (purge.length > 0) {
      setInputsErrors(purge);
      return;
    }
    const newObj: CreateClientInt = {
      name: clientForm.name,
      address_1: clientForm.address_1,
      address_2: clientForm.address_2,
      zip_code: clientForm.zip_code,
      url: clientForm.url,
      status: clientForm.status ?? 'active',
      text_note: type === 'add' ? 'New Client' : clientForm.text_note ?? '',
      international_data: clientForm.international_data ?? '',
      city: clientForm.city.id ?? 0,
    };

    if (clientForm.primary_phone !== '')
      newObj.primary_phone = clientForm.primary_phone;
    if (clientForm.primary_phone_extension !== '')
      newObj.primary_phone_extension = parseInt(
        clientForm.primary_phone_extension.replace(
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g,
          '',
        ),
        10,
      );
    saveClient(newObj);
  };

  const getCities = async () => {
    setLoading({ ...loading, city: true });
    try {
      const resp = await fetchCities((clientForm.city.state.id as number) ?? 0);
      if (!resp.find(item => item.id === clientForm.city.id ?? 0))
        setClientForm({
          ...clientForm,
          city: { ...clientForm.city, id: 0, name: '' },
        });
      setCitiesOptions(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, city: false });
    }
  };

  useEffect(() => {
    if (clientForm.city.state.name !== '') getCities();
  }, [clientForm.city.state.name]);

  useEffect(() => {
    const errors = inputsArr.filter(
      item => item.value.toString().trim() === '' && item.required,
    );
    if (errors.length > 0) setDisableButton(true);
    else setDisableButton(false);
  }, [clientForm]);

  const getStates = async () => {
    setLoading({ ...loading, state: true });
    try {
      const resp = await fetchStates();
      setStatesOptions(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, state: false });
    }
  };

  useEffect(() => {
    if (type === 'add') getStates();
    if (type === 'edit') {
      getStates();
      setOriginalState(clientForm.status ?? '');
    }
  }, []);

  return (
    <>
      <HeaderDrawer
        title={type === 'add' ? 'Add Client' : 'Edit Client'}
        threeButtons={false}
        saveFunction={validationAll}
        loading={loadingCreate}
        closeDrawer={setOpenDrawer}
        disabled={
          !(disableButton === false && inputsErrors.length === 0) || canEdit
        }
      />
      {type === 'edit' && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginTop: 1.3,
            marginLeft: 3,
            marginRight: 4,
          }}
        >
          <TabParent
            valueTab={tabDrawer}
            setTab={setTabDrawer}
            tabs={tabs}
            center
          />
        </Box>
      )}
      {type === 'add' && (
        <BoxAddSettings title="Client Info">
          <div className="form-grids">
            {inputsArr.map(input => (
              <div
                key={input.id}
                className={`mb-2 ${input.fullSpace ? 'whole-grid_three' : ''}`}
              >
                {input.body}
              </div>
            ))}
          </div>
        </BoxAddSettings>
      )}
      {type === 'edit' && (
        <>
          <TabPanel value={tabDrawer} index={0}>
            <BoxAddSettings title="Client Info">
              <div className="form-grids">
                {inputsArr.map(input => (
                  <div
                    key={input.id}
                    className={`mb-2 ${
                      input.fullSpace ? 'whole-grid_three' : ''
                    }`}
                  >
                    {input.body}
                  </div>
                ))}
              </div>
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="client"
                notes={notes}
                id={clientForm.id ?? 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={2}>
            <BoxAddSettings>
              <FormStatus history={history} />
            </BoxAddSettings>
          </TabPanel>
        </>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        updateStatus={updateStatus}
      />
    </>
  );
};

export default AddClient;
