import { useEffect, useState } from 'react';
import { ProjectsRequest } from '../models/Projects';
import { fetchProjects } from '../helpers/projects';

interface Props {
  id?: number;
  categoryId?: number;
}

const useProjects = ({ id, categoryId }: Props) => {
  const [projects, setProjects] = useState<ProjectsRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const getProjects = () => {
    setLoading(true);
    fetchProjects(id, categoryId)
      .then(res => setProjects(res))
      .catch(err => setProjects([]))
      .finally(() => setLoading(false));
  };

  useEffect(getProjects, []);

  return { projects, loading };
};

export default useProjects;
