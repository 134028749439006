import { StateInt } from '../models/Country';
import APIClient from '../services/APIClient';

export const fetchStates = async () => {
  const clientAPI = new APIClient();
  const statesResponse = await clientAPI.client.get<StateInt[]>('/states/');
  return statesResponse.data;
};

export const fetchCities = async (id: number) => {
  const clientAPI = new APIClient();
  const cityResponse = await clientAPI.client.get<StateInt[]>(
    `/cities/?state__id=${id}`,
  );
  return cityResponse.data;
};
