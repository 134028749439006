import { useState, useEffect } from 'react';
import { SitesRequestV2 } from '../models/Sites';
import { fetchSites } from '../helpers/sites';

interface Props {
  userId?: number;
  minimal?: boolean;
}

const useSites = ({ userId, minimal }: Props) => {
  const [sites, setSites] = useState<SitesRequestV2[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const refreshSite = () => {
    setLoading(true);
    fetchSites(userId, minimal)
      .then(resp => setSites(resp))
      .catch(err => {
        setSites([]);
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(refreshSite, []);

  return { sites, loading, error };
};

export default useSites;
