import {
  Modal,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import HeaderDrawer from '../Drawers/HeaderDrawer';
import GridForms from '../Tab/GridForms';
import { TextFormField } from '../Forms/FormInputs';
import { SitesRequestV2 } from '../../models/Sites';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import { FlexBox } from '../tableItems';
import RowSite from './RowSite';
import Spinner from '../Loading/Spinner';
import {
  changePassword,
  getProfileInfo,
  updateProfilePicture,
  updateUserInfo,
} from '../../helpers/user';
import FeedbackMessage from '../FeedbackMessage';
import { SeverityType } from '../../models/General';
import useProjects from '../../customHooks/useProjects';
import GridFormPhoto from '../Tab/GridFormPhoto';
import { ENVIRONMENTS, ROLES_USER_VALUES } from '../../constants/user';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  borderRadius: '2px',
  boxShadow: 24,
  overflowY: 'auto',
  pb: 5,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  sitesTable: SitesRequestV2[];
}
const adminRoles = ['administrator', 'dev admin'];
const MyProfile = ({ open, closeModal, sitesTable }: Props) => {
  const { UserContext, setUserContext } = useContext(
    Context,
  ) as UserContextType;

  const [loading, setLoading] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'success',
  });

  const { projects } = useProjects({ id: UserContext.id });
  const [profileForm, setProfileForm] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    avatar: '',
    preferred_name: '',
  });

  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const [preferredName, setPreferredName] = useState('');
  const [newPassword, setNewPassword] = useState({
    password: '',
    password_confirmation: '',
  });

  const inputsArr = [
    {
      id: 1,
      required: true,
      value: profileForm.firstName,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled
          nameInput="firstName"
          label="First Name"
          error={false}
          value={profileForm.firstName}
          errorText=""
          placeholder="First Name"
          onChange={(value: string) =>
            setProfileForm({ ...profileForm, firstName: value })
          }
        />
      ),
    },
    {
      id: 2,
      required: true,
      value: profileForm.lastName,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled
          nameInput="lastName"
          label="Last Name"
          error={false}
          value={profileForm.lastName}
          errorText=""
          placeholder="Last Name"
          onChange={(value: string) =>
            setProfileForm({ ...profileForm, lastName: value })
          }
        />
      ),
    },
    {
      id: 3,
      required: true,
      value: profileForm.email,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled
          nameInput="email"
          label="Email"
          error={false}
          value={profileForm.email}
          errorText=""
          placeholder="Email"
          onChange={(value: string) =>
            setProfileForm({ ...profileForm, email: value })
          }
        />
      ),
    },
    {
      id: 7,
      required: false,
      value: profileForm.preferred_name,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required={false}
          disabled={false}
          nameInput="preferred_name"
          label="Preferred Name"
          error={false}
          value={preferredName}
          errorText=""
          placeholder="Preferred Name"
          onChange={(value: string) => setPreferredName(value)}
        />
      ),
    },
    {
      id: 4,
      required: true,
      value: profileForm.username,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled
          nameInput="username"
          label="Username"
          error={false}
          value={profileForm.username}
          errorText=""
          placeholder="Username"
          onChange={(value: string) =>
            setProfileForm({ ...profileForm, username: value })
          }
        />
      ),
    },
    {
      id: 5,
      required: true,
      value: newPassword.password,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="password"
          label="Password"
          error={errorPassword}
          type="password"
          autocomplete="new-password"
          value={newPassword.password}
          errorText={errorPassword ? 'Passwords do not match' : ''}
          placeholder="New Password"
          onChange={(value: string) =>
            setNewPassword({ ...newPassword, password: value })
          }
        />
      ),
    },
    {
      id: 6,
      required: true,
      value: newPassword.password_confirmation,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="confirmPassword"
          label="Confirm Password"
          type="password"
          autocomplete="new-password"
          error={errorPassword}
          value={newPassword.password_confirmation}
          errorText={errorPassword ? 'Passwords do not match' : ''}
          placeholder="Confirm Password"
          onChange={(value: string) =>
            setNewPassword({ ...newPassword, password_confirmation: value })
          }
        />
      ),
    },
  ];

  const getProfileAfterSubmit = async () => {
    setLoading(true);
    const valueRole: string = JSON.parse(
      localStorage.getItem('backlink.roletemp') ?? JSON.stringify(''),
    );
    console.log(valueRole);
    try {
      const profile = await getProfileInfo();
      setProfileForm({
        id: profile.id,
        firstName: profile.first_name,
        lastName: profile.last_name,
        email: profile.email,
        username: profile.username,
        avatar: profile.profile.avatar,
        preferred_name: profile.profile.preferred_name ?? '',
      });
      setPreferredName(profile.profile.preferred_name ?? '');

      setUserContext({
        ...UserContext,
        avatar: profile.profile.avatar,
        profile: {
          ...UserContext.profile,
          training_env_allowed: profile.profile.training_env_allowed,
          role:
            adminRoles.includes(profile.profile.role) ||
            (profile.profile.training_env_allowed &&
              ROLES_USER_VALUES.includes(valueRole) &&
              ENVIRONMENTS.includes(process.env.REACT_APP_ENV ?? ''))
              ? valueRole
              : profile.profile.role,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getProfile = async () => {
    setProfileForm({
      id: UserContext.id,
      firstName: UserContext.first_name,
      lastName: UserContext.last_name,
      email: UserContext.email,
      username: UserContext.username,
      avatar: UserContext.profile.avatar,
      preferred_name: UserContext.profile.preferred_name ?? '',
    });
    setPreferredName(UserContext.profile.preferred_name ?? '');
  };

  const sendMessage = (message: string, severity: SeverityType) =>
    setFeedbackMessage({ message, severity });

  const submitPassword = async () => {
    try {
      await changePassword(newPassword, profileForm.id);
      setNewPassword({ password: '', password_confirmation: '' });
      if (!newAvatar && preferredName === profileForm.preferred_name) {
        sendMessage('Password Updated Succesfully', 'success');
        setFeedbackOpen(true);
        getProfileAfterSubmit();
      }
    } catch (err) {
      sendMessage('There was an error, try again', 'error');
      setFeedbackOpen(true);
    } finally {
      if (!newAvatar && preferredName === profileForm.preferred_name)
        setLoadingSubmit(false);
    }
  };

  const changeAvatar = async () => {
    try {
      if (newAvatar) {
        await updateProfilePicture(newAvatar, profileForm.id);
        setNewAvatar(null);
        if (
          newPassword.password === '' &&
          preferredName === profileForm.preferred_name
        ) {
          sendMessage('Avatar Updated Succesfully', 'success');
          setFeedbackOpen(true);
          getProfileAfterSubmit();
        }
      }
    } catch (err) {
      const error = err as any;
      sendMessage(error.data.image[0], 'error');
      setFeedbackOpen(true);
    } finally {
      if (
        newPassword.password === '' &&
        preferredName === profileForm.preferred_name
      )
        setLoadingSubmit(false);
    }
  };

  const updateUser = async () => {
    try {
      if (preferredName !== profileForm.preferred_name) {
        await updateUserInfo(profileForm.id, {
          profile: { preferred_name: preferredName },
        });
        if (newPassword.password === '' && !newAvatar) {
          sendMessage('Preferred Name Updated Succesfully', 'success');
          setFeedbackOpen(true);
          getProfileAfterSubmit();
        }
      }
    } catch (err) {
      const error = err as any;
      sendMessage(error.data.image[0], 'error');
      setFeedbackOpen(true);
    } finally {
      if (newPassword.password === '' && !newAvatar) setLoadingSubmit(false);
    }
  };

  const validAll = () => {
    setLoadingSubmit(true);
    setErrorPassword(false);
    if (
      newPassword.password === '' &&
      newPassword.password_confirmation === ''
    ) {
      if (newAvatar && preferredName !== profileForm.preferred_name) {
        Promise.all([changeAvatar(), updateUser()]).then(() => {
          sendMessage('Changes were made succesfully', 'success');
          setFeedbackOpen(true);
          setLoadingSubmit(false);
          getProfileAfterSubmit();
        });
      } else if (newAvatar) changeAvatar();
      else updateUser();
      return;
    }
    if (newPassword.password !== newPassword.password_confirmation) {
      setErrorPassword(true);
      setLoadingSubmit(false);
      return;
    }
    if (newAvatar || preferredName !== profileForm.preferred_name) {
      if (newAvatar && preferredName !== profileForm.preferred_name) {
        Promise.all([updateUser(), changeAvatar(), submitPassword()]).then(
          () => {
            sendMessage('Changes were made succesfully', 'success');
            setFeedbackOpen(true);
            setLoadingSubmit(false);
            getProfileAfterSubmit();
          },
        );
      } else if (newAvatar && preferredName === profileForm.preferred_name) {
        Promise.all([changeAvatar(), submitPassword()]).then(() => {
          sendMessage('Changes were made succesfully', 'success');
          setFeedbackOpen(true);
          setLoadingSubmit(false);
          getProfileAfterSubmit();
        });
      } else {
        Promise.all([updateUser(), submitPassword()]).then(() => {
          sendMessage('Changes were made succesfully', 'success');
          setFeedbackOpen(true);
          setLoadingSubmit(false);
          getProfileAfterSubmit();
        });
      }
    } else submitPassword();
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (
      newPassword.password === '' &&
      newPassword.password_confirmation === '' &&
      !newAvatar &&
      preferredName === profileForm.preferred_name
    )
      setDisabledButton(true);
    if (
      newPassword.password === '' &&
      newPassword.password_confirmation === '' &&
      (newAvatar || preferredName !== profileForm.preferred_name)
    )
      setDisabledButton(false);
    if (
      newPassword.password !== '' ||
      newPassword.password_confirmation !== ''
    ) {
      setDisabledButton(
        !(
          newPassword.password !== '' &&
          newPassword.password_confirmation !== ''
        ),
      );
    }
  }, [newAvatar, newPassword, preferredName]);
  const handleChangePhoto = (file: File | null) => {
    setNewAvatar(file);
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={style} className="profile-responsive">
          <HeaderDrawer
            title="Edit My Profile"
            threeButtons={false}
            saveFunction={validAll}
            closeDrawer={closeModal}
            loading={loadingSubmit}
            disabled={disabledButton}
          />
          <Box marginTop={1.7} paddingX={5.5}>
            {loading && <Spinner />}
            {!loading && (
              <>
                <GridFormPhoto
                  newAvatar={newAvatar}
                  avatar={profileForm.avatar}
                  changePhoto={handleChangePhoto}
                  noAvatarName={
                    UserContext.first_name !== ''
                      ? `${UserContext.first_name[0]}${UserContext.last_name[0]}`
                      : 'U'
                  }
                >
                  <Box marginTop={1}>
                    <Typography fontSize={24} fontWeight={900}>
                      {UserContext.first_name !== ''
                        ? `${UserContext.first_name} ${UserContext.last_name}`
                        : 'User'}
                    </Typography>
                    <Typography
                      fontSize={14}
                      marginTop={1}
                      fontWeight={400}
                      color="#373737"
                    >
                      {UserContext.is_superuser ? 'Admin' : 'Link Builder'}
                    </Typography>
                  </Box>
                </GridFormPhoto>
                <GridForms title="Name & Email">
                  <div className="form-grids">
                    {inputsArr.slice(0, 4).map(input => (
                      <div
                        key={input.id}
                        className={`mb-2 ${
                          input.fullSpace ? 'whole-grid' : ''
                        }`}
                      >
                        {input.body}
                      </div>
                    ))}
                  </div>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    color="#787878"
                    marginTop={1}
                  >
                    Note: Please contact your manager to change your name and/or
                    email address.
                  </Typography>
                </GridForms>
                <GridForms title="Login">
                  <div className="form-grids mb-2">
                    <div
                      key={inputsArr[4].id}
                      className={`mb-2 ${
                        inputsArr[4].fullSpace ? 'whole-grid' : ''
                      }`}
                    >
                      {inputsArr[4].body}
                    </div>
                  </div>
                  <div className="form-grids">
                    {inputsArr.slice(5).map(input => (
                      <div
                        key={input.id}
                        className={`mb-2 ${
                          input.fullSpace ? 'whole-grid' : ''
                        }`}
                      >
                        {input.body}
                      </div>
                    ))}
                  </div>
                </GridForms>
                <Box marginTop={2}>
                  <Typography
                    color="rgba(0, 0, 0, 0.8)"
                    fontSize={14}
                    fontWeight={600}
                  >
                    Projects & Sites Access
                  </Typography>
                  <Box marginTop={3}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontSize: '15px' }}>
                              <FlexBox>
                                <Typography fontWeight={600} fontSize={14}>
                                  Project
                                </Typography>
                                <SubdirectoryArrowRightIcon
                                  fontSize="inherit"
                                  color="disabled"
                                  sx={{ marginTop: 0.5, marginX: 0.4 }}
                                />
                                <Typography fontWeight={600} fontSize={14}>
                                  Site
                                </Typography>
                              </FlexBox>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                              }}
                            >
                              Role
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontSize: '13px',
                                fontWeight: 600,
                              }}
                            >
                              Start Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projects.map(project => (
                            <RowSite
                              key={project.id}
                              project={project.name}
                              sites={project.sites}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="center"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
    </div>
  );
};

export default MyProfile;
