import { Box, Typography } from '@mui/material';
import React from 'react';
import { MainButton } from '../buttons';

interface Props {
  title: string;
  buttonText?: string;
  buttonFunction?: () => void;
  disabled?: boolean;
  button: boolean;
}

const TitleButton = ({
  title,
  buttonText,
  buttonFunction,
  button,
  disabled,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography fontSize={24} fontWeight={900}>
        {title}
      </Typography>
      {button && (
        <MainButton
          disabled={disabled}
          variant="contained"
          sx={{
            color: 'black',
            backgroundColor: '#BAF372',
            '&:hover': {
              backgroundColor: '#BAF372',
            },
          }}
          type="submit"
          onClick={buttonFunction}
        >
          {buttonText}
        </MainButton>
      )}
    </Box>
  );
};

export default TitleButton;
