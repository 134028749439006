/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Accordion as MuiAccordion,
  styled,
  AccordionProps,
  AccordionSummaryProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderTop: `1px solid rgba(0, 0, 0, 0.06)`,
  borderBottom: `1px solid rgba(0, 0, 0, 0.06)`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: '11px', color: '#000000' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  paddingTop: 7,
  paddingBottom: 7,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: `1px solid rgba(0, 0, 0, 0.06)`,
}));
