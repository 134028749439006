import React, { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';
import { SeverityType } from '../../models/General';
import { CreateTemplateInt } from '../../models/Templates';
import { updateTemplate } from '../../helpers/templates';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface BulkProspectProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  objTemplate: CreateTemplateInt;
  showMessage: (message: string, severity: SeverityType, type: string) => void;
  status: string;
}

const ApproveRejectTemp = ({
  open,
  closeModal,
  objTemplate,
  showMessage,
  status,
}: BulkProspectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateTheTemplate = async () => {
    setIsLoading(true);
    try {
      await updateTemplate(objTemplate, objTemplate.id ?? 0);
      showMessage('Template updated successfully!', 'success', 'edit');
      closeModal(false);
    } catch (err) {
      showMessage('Error while updating template, try again', 'error', 'edit');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            {status} Template
          </Typography>
          <Typography marginTop={2} fontSize={14} textAlign="center">
            Are you sure you want to{' '}
            {status === 'active' ? 'approve' : 'reject'} this template?
          </Typography>
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={updateTheTemplate}
                disabled={false}
                loading={isLoading}
                sx={{ marginLeft: 1 }}
              >
                Confirm
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default ApproveRejectTemp;
