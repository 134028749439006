import React from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectSitesSearch } from '../../../../models/Projects';

interface Props {
  projects: ProjectSitesSearch[];
  sites: ProjectSitesSearch[];
  handleSearch: (item: ProjectSitesSearch) => void;
}

interface TextProps {
  item: ProjectSitesSearch;
}

const QueryString = ({ item }: TextProps) => {
  return (
    <Typography
      fontSize={12}
      color={item.status === 'active' ? 'black' : 'gray'}
    >
      {item.startPos !== 0 && item.endPos < item.name.length && (
        <>
          <span>{item.name.slice(0, item.startPos)}</span>
          <span className="bold-letter">
            {item.name.slice(item.startPos, item.endPos)}
          </span>
          <span>{item.name.slice(item.endPos)}</span>
        </>
      )}
      {item.startPos === 0 && (
        <>
          <span className="bold-letter">{item.name.slice(0, item.endPos)}</span>
          <span>{item.name.slice(item.endPos)}</span>
        </>
      )}
      {item.startPos !== 0 && item.endPos === item.name.length && (
        <>
          <span>{item.name.slice(0, item.startPos)}</span>
          <span className="bold-letter">{item.name.slice(item.startPos)}</span>
        </>
      )}
      {item.projectName && <span> | {item.projectName}</span>}
    </Typography>
  );
};

const SearchBox = ({ projects, sites, handleSearch }: Props) => {
  return (
    <Box
      border="1px solid #C7C7C7"
      borderTop="none"
      borderRadius="0 0 5px 5px"
      position="absolute"
      width="100%"
      zIndex={100}
      maxHeight={340}
      boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      overflow="auto"
      sx={{ background: 'white' }}
    >
      {projects.length === 0 && sites.length === 0 && (
        <Box padding={2}>
          <Typography fontSize={15} fontWeight={600}>
            There are no results found
          </Typography>
        </Box>
      )}
      {projects.length > 0 && (
        <>
          <Box
            sx={{
              background: 'rgba(217, 217, 217, 0.6)',
              border: '1px solid #D9D9D9',
              padding: '10px 18px',
            }}
          >
            <Typography fontSize={15} fontWeight={600}>
              Projects
            </Typography>
          </Box>
          {projects.map(item => (
            <Box
              key={item.id}
              sx={{
                border: ' 1px solid #D7D7D7',
                padding: '10px 18px',
                cursor: 'pointer',
                '&:hover': {
                  background: '#F5F5F5',
                },
              }}
              onClick={() => handleSearch(item)}
            >
              <QueryString item={item} />
            </Box>
          ))}
        </>
      )}
      {sites.length > 0 && (
        <>
          <Box
            sx={{
              background: 'rgba(217, 217, 217, 0.6)',
              border: '1px solid #D9D9D9',
              padding: '10px 18px',
            }}
          >
            <Typography fontSize={15} fontWeight={600}>
              Sites
            </Typography>
          </Box>
          {sites.map(item => (
            <Box
              key={item.id}
              sx={{
                border: ' 1px solid #D7D7D7',
                padding: '10px 18px',
                cursor: 'pointer',
                '&:hover': {
                  background: '#F5F5F5',
                },
              }}
              onClick={() => handleSearch(item)}
            >
              <QueryString item={item} />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default SearchBox;
