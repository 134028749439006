import { create } from 'zustand';
import {
  FiltersAssignedInterface,
  filtersAssigned,
} from '../../models/Filters';

interface FiltersAssignedStore {
  filterValuesDefault: FiltersAssignedInterface;
  filterValuesChange: FiltersAssignedInterface;
  updateFiltersChange: (obj: FiltersAssignedInterface) => void;
  updateFiltersDefault: (obj: FiltersAssignedInterface) => void;
}

const useFiltersAssigned = create<FiltersAssignedStore>(set => ({
  filterValuesDefault: filtersAssigned(0),
  filterValuesChange: filtersAssigned(0),
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersAssigned;
