import { useState, useEffect } from 'react';
import { ProjectSitesInt, ProjectSitesSearch } from '../models/Projects';
import { findWords } from '../helpers/helpersfunctions';

interface Props {
  query: string;
  projects: ProjectSitesInt[];
  sites: ProjectSitesInt[];
}

const useSearch = ({ query, projects, sites }: Props) => {
  const [searchProjects, setSearchProjects] = useState<ProjectSitesSearch[]>(
    [],
  );
  const [searchSites, setSearchSites] = useState<ProjectSitesSearch[]>([]);

  const searchWords = () => {
    const newProjects = findWords(query, projects);
    setSearchProjects(newProjects);
    const newSites = findWords(query, sites);
    setSearchSites(newSites);
  };

  useEffect(() => {
    if (query.trim() === '') {
      setSearchProjects([]);
      setSearchSites([]);
      return;
    }
    searchWords();
  }, [query]);

  return { searchProjects, searchSites };
};

export default useSearch;
