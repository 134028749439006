import React, { useState } from 'react';
import { TemplateVisibilityColumns } from '../../models/Templates';
import FiltersTemplates from './Filters/FiltersTemplates';
import TemplatesContainer from './Table/TemplatesContainer';

const Templates = () => {
  const [clean, setClean] = useState(false);
  const [visibleColumns, setVisibleColumns] =
    useState<TemplateVisibilityColumns>({
      site__name: true,
      owner__username: true,
      affinity: true,
      audience: true,
      created: true,
      modified: true,
      status: true,
      notes_count: true,
    });

  return (
    <div className="grid_container">
      <div className="left_columns">
        <FiltersTemplates
          columnsVisibility={visibleColumns}
          setColumnsVisible={setVisibleColumns}
          clean={clean}
        />
      </div>
      <div className="right_columns">
        <TemplatesContainer
          columnsVisibility={visibleColumns}
          setClean={setClean}
          clean={clean}
        />
      </div>
    </div>
  );
};

export default Templates;
