/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import { editClaimLink, getClaimInfo } from '../../../helpers/claims';
import {
  ClaimPostPutInt,
  ClaimsInt,
  claimPostObjDefault,
  claimsObjDef,
} from '../../../models/Links';
import ClaimTab from './ClaimTab';
import useOwners from '../../../customHooks/useOwners';
import Spinner from '../../Loading/Spinner';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { sendErrorSubmit } from '../../../helpers/links';

interface EditClaimProps {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  fetchInfo: () => Promise<void>;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  claimLink: number;
  disabledAll?: boolean;
}

const EditClaim = ({
  setOpenDrawer,
  handleMessage,
  fetchInfo,
  claimLink,
  disabledAll,
}: EditClaimProps) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [claimObj, setClaimObj] =
    useState<ClaimPostPutInt>(claimPostObjDefault);
  const [disabledClaimSave, setDisabledClaimSave] = useState(false);
  const [loadingSubmitClaimLink, setLoadingSubmitClaimLink] = useState(false);
  const { owners } = useOwners({ withFields: true });
  const [claimLinkEdit, setClaimLinkEdit] = useState<ClaimsInt>(claimsObjDef);
  const [loading, setLoading] = useState(false);

  const saveClaimLink = async (obj?: ClaimPostPutInt, id?: number) => {
    setLoadingSubmitClaimLink(true);
    const copyObject = { ...claimObj };
    if (obj) {
      if (obj.audience === 0 || obj.audience === undefined) {
        delete obj.audience;
      }
    } else if (claimObj.audience === 0 || claimObj.audience === undefined) {
      delete copyObject.audience;
    }
    try {
      const resp = await editClaimLink(obj ?? copyObject, claimLinkEdit.id);
      handleMessage(`Claim updated successfully`, 'success');
      fetchInfo();
      setOpenDrawer(false);
      console.log(resp);
    } catch (err) {
      const error = err as any;

      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/${claimLinkEdit.id}/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? copyObject),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleMessage('Malformed link for Destination Url', 'error');
      } else {
        handleMessage(`Error updating claim`, 'error');
      }
    } finally {
      setLoadingSubmitClaimLink(false);
    }
  };

  const handleClaimDisabled = (value: boolean) => setDisabledClaimSave(value);
  const handleUpdateClaimObj = (obj: ClaimPostPutInt) => setClaimObj(obj);

  const fetchClaimInfo = async () => {
    try {
      const resp = await getClaimInfo(claimLink);
      setClaimLinkEdit(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchClaimInfo();
  }, [claimLink]);

  const disabledEdit = () => {
    const firstResp = [
      'manager',
      'account manager',
      'administrator',
      'dev admin',
      'strategist',
    ].includes(UserContext.profile.role);
    if (firstResp) return false;
    if (claimLinkEdit.owner.id === UserContext.id) return false;
    return true;
  };

  return (
    <>
      <HeaderDrawer
        saveFunction={saveClaimLink}
        title="Edit Claim"
        threeButtons={false}
        loading={loadingSubmitClaimLink}
        disabled={disabledClaimSave || disabledEdit()}
        closeDrawer={setOpenDrawer}
      />
      {loading && <Spinner />}
      {!loading && (
        <ClaimTab
          readMode={disabledAll}
          loading={loadingSubmitClaimLink}
          owners={owners}
          handleClaimDisabled={handleClaimDisabled}
          claimLinks={claimLinkEdit}
          handleSaveForm={saveClaimLink}
          sites={[{ id: claimLinkEdit.site.id, name: claimLinkEdit.site.name }]}
          handleUpdateClaimObj={handleUpdateClaimObj}
        />
      )}
    </>
  );
};

export default EditClaim;
