import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import ReactPaginate from 'react-paginate';
import { FlexBox } from '../tableItems';

interface Props {
  pageSize: number;
  countItems: number;
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
}

const TablePagination = ({
  pageSize,
  countItems,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}: Props) => {
  return (
    <FlexBox marginY={2} justifyContent="right">
      <FlexBox>
        <Typography fontSize={14}>Rows per page: </Typography>
        <Select
          value={pageSize}
          onChange={e => handleChangeRowsPerPage(e.target.value as number)}
          sx={{
            '& .MuiInputBase-input': {
              border: 'none',
              outline: 'none',
              padding: '5px 12px',
              fontSize: 14,
              color: '#373737',
            },

            marginX: 1,
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={300}>300</MenuItem>
        </Select>
      </FlexBox>
      <Typography fontSize={14}>
        Showing {page * pageSize - (pageSize - 1)} -{' '}
        {countItems < page * pageSize ? countItems : page * pageSize} of{' '}
        {countItems} items
      </Typography>

      <ReactPaginate
        pageCount={Math.ceil(countItems / pageSize)}
        forcePage={page - 1}
        onPageChange={e => handleChangePage(e, e.selected + 1)}
        nextLabel=">"
        previousLabel="<"
        pageRangeDisplayed={9}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        breakLinkClassName="page-dots"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        disabledLinkClassName="page-disabled"
        prevPageRel={null}
        activeLinkClassName="page-active"
      />
    </FlexBox>
  );
};

export default TablePagination;
