import { Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React, { useContext } from 'react';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import { sendBackObject } from '../../helpers/helpersfunctions';
import {
  ROLES_CATEGORIES,
  ROLES_CLIENTS,
  ROLES_PROJECTS,
  ROLES_SITES,
} from '../../constants/user';

interface Props {
  text: string;
  disabled: { sort: boolean; add: boolean };
  id: number;
  orderSort: 'asc' | 'desc';
  orderFunction: (
    order: 'asc' | 'desc',
    type: 'client' | 'category' | 'projects' | 'sites',
  ) => void;
  type: 'client' | 'category' | 'projects' | 'sites';
  addFunction: (id: number) => void;
}

const SettingsHeaders = ({
  text,
  disabled,
  id,
  addFunction,
  orderSort,
  orderFunction,
  type,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;

  const ROLES_OBJECTS = {
    client: !sendBackObject(UserContext.profile.role, ROLES_CLIENTS).add,
    category: !sendBackObject(UserContext.profile.role, ROLES_CATEGORIES).add,
    projects: !sendBackObject(UserContext.profile.role, ROLES_PROJECTS).add,
    sites: !sendBackObject(UserContext.profile.role, ROLES_SITES).add,
  };

  return (
    <FlexBoxBetween paddingX={2} paddingTop={1.8} paddingBottom={1}>
      <FlexBox>
        <Typography
          textTransform="uppercase"
          fontSize={13}
          color="rgba(0, 0, 0, 0.4)"
          fontWeight={700}
        >
          {text}
        </Typography>
        <IconButton
          disabled={disabled.sort}
          sx={{ marginLeft: 0.1 }}
          className={orderSort === 'desc' ? 'sort_direction' : ''}
          onClick={() => orderFunction(orderSort, type)}
        >
          <ArrowUpwardIcon fontSize="small" />
        </IconButton>
      </FlexBox>
      <IconButton
        disabled={disabled.add || ROLES_OBJECTS[type]}
        onClick={() => addFunction(id)}
      >
        <AddIcon />
      </IconButton>
    </FlexBoxBetween>
  );
};

export default SettingsHeaders;
