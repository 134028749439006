import { useState, useEffect } from 'react';
import { fetchClientsNotes } from '../helpers/clients';
import { NotesReq } from '../models/Notes';
import { fetchCategoryNotes } from '../helpers/category';
import { fetchProjectNotes } from '../helpers/projects';
import { fetchSiteNotes } from '../helpers/sites';
import { fetchTemplateNotes } from '../helpers/templates';
import { fetchLinkNotes } from '../helpers/links';

interface Props {
  id: number;
  type: 'category' | 'client' | 'project' | 'site' | 'template' | 'link';
}

const useNotes = ({ id, type }: Props) => {
  const [notes, setNotes] = useState<NotesReq[]>([]);
  const [loading, setLoading] = useState(false);

  const refreshNotes = (newId: number) => {
    if (newId === 0) return;
    setLoading(true);
    if (type === 'category') {
      fetchCategoryNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    } else if (type === 'client') {
      fetchClientsNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    } else if (type === 'project') {
      fetchProjectNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    } else if (type === 'template') {
      fetchTemplateNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    } else if (type === 'link') {
      fetchLinkNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    } else {
      fetchSiteNotes(newId)
        .then(resp => setNotes(resp))
        .catch(err => setNotes([]))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    refreshNotes(id);
  }, []);

  return { notes, loadingNotes: loading, refreshNotes };
};

export default useNotes;
