import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import SettingsHeaders from '../../../../components/Columns/SettingsHeaders';
import SettingsList from '../../../../components/List/SettingsList';
import { ProjectsSitesProps } from '../../../../models/Projects';

const ColumnsProjects = ({
  text,
  disabled,
  id,
  orderSort,
  orderFunction,
  type,
  addFunction,
  loading,
  options,
  onEdit,
  selected,
  setSelected,
  handleDelete,
}: ProjectsSitesProps) => {
  return (
    <>
      <SettingsHeaders
        addFunction={addFunction}
        id={id}
        orderFunction={orderFunction}
        orderSort={orderSort}
        text={text}
        disabled={disabled}
        type={type}
      />
      {loading && (
        <Box display="flex" justifyContent="center" marginTop={4.4}>
          <CircularProgress sx={{ color: '#ABABAB' }} />
        </Box>
      )}
      {!loading && options.length > 0 && (
        <SettingsList
          onEdit={onEdit}
          type={type}
          handleDelete={handleDelete}
          options={options}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </>
  );
};

export default ColumnsProjects;
