import { List, ListItem, ListItemButton, Typography, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { ClientsRequest } from '../../models/Clients';
import LittleMenu from '../Drawers/LittleMenu';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import { sendBackObject } from '../../helpers/helpersfunctions';
import {
  ROLES_CATEGORIES,
  ROLES_CLIENTS,
  ROLES_PROJECTS,
  ROLES_SITES,
} from '../../constants/user';

interface Props {
  options: { id: number; name: string; status: string }[] | ClientsRequest[];
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  onEdit: (id: number) => void;
  type: 'client' | 'category' | 'projects' | 'sites';
  handleDelete: (
    type: 'client' | 'category' | 'projects' | 'sites',
    id: number,
    option: 'Deactivate' | 'Delete' | 'Activate',
  ) => void;
}

const SettingsList = ({
  options,
  selected,
  setSelected,
  onEdit,
  type,
  handleDelete,
}: Props) => {
  const [open, setOpen] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { UserContext } = useContext(Context) as UserContextType;

  const ROLES_OBJECTS = {
    client: {
      canEdit: sendBackObject(UserContext.profile.role, ROLES_CLIENTS).edit,
      canDelete: sendBackObject(UserContext.profile.role, ROLES_CLIENTS).edit,
    },
    category: {
      canEdit: sendBackObject(UserContext.profile.role, ROLES_CATEGORIES).edit,
      canDelete: sendBackObject(UserContext.profile.role, ROLES_CATEGORIES)
        .delete,
    },
    projects: {
      canEdit: sendBackObject(UserContext.profile.role, ROLES_PROJECTS).edit,
      canDelete: sendBackObject(UserContext.profile.role, ROLES_PROJECTS)
        .delete,
    },
    sites: {
      canEdit: sendBackObject(UserContext.profile.role, ROLES_SITES).edit,
      canDelete: sendBackObject(UserContext.profile.role, ROLES_SITES).delete,
    },
  };

  const menuOptions: any = [
    {
      id: 1,
      title: ROLES_OBJECTS[type].canEdit ? 'Edit' : 'View',
      click: (id: number) => {
        setOpen(0);
        onEdit(id);
      },
    },
  ];

  const menuOptionsActivate: any = [
    {
      id: 1,
      title: ROLES_OBJECTS[type].canEdit ? 'Edit' : 'View',
      click: (id: number) => {
        setOpen(0);
        onEdit(id);
      },
    },
  ];

  if (ROLES_OBJECTS[type].canEdit) {
    menuOptions.push({
      id: 2,
      title: 'Deactivate',
      click: (id: number) => {
        setOpen(0);
        handleDelete(type, id, 'Deactivate');
      },
    });

    menuOptionsActivate.push({
      id: 2,
      title: 'Activate',
      click: (id: number) => {
        setOpen(0);
        handleDelete(type, id, 'Activate');
      },
    });
  }

  return (
    <List>
      {options.map((item, index) => (
        <ListItem
          key={item.id}
          secondaryAction={
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <LittleMenu
                anchorEl={anchorEl}
                hideEffect
                setAnchorEl={setAnchorEl}
                open={open}
                id={item.id}
                setOpen={setOpen}
                menuOptions={
                  item.status === 'active' ? menuOptions : menuOptionsActivate
                }
              />
            </Box>
          }
          disablePadding
          className={`list_item-parent ${
            index === 0 ? 'list-first-button' : 'list-buttons'
          }`}
        >
          <ListItemButton
            disabled={!(item.status === 'active')}
            onClick={() => setSelected(item.id)}
            selected={selected === item.id}
            sx={{
              '&.Mui-selected': {
                border: '1px solid gray',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.19)' },
              },
            }}
          >
            <Typography
              fontSize={16}
              paddingY={0.7}
              fontWeight={selected === item.id ? 700 : 400}
            >
              {item.name}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default SettingsList;
