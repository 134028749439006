import { create } from 'zustand';
import {
  FiltersClaimsInterface,
  filtersObjValuesWithOwner,
} from '../../models/Filters';

interface FiltersClaimsStore {
  filterValuesDefault: FiltersClaimsInterface;
  filterValuesChange: FiltersClaimsInterface;
  updateFiltersChange: (obj: FiltersClaimsInterface) => void;
  updateFiltersDefault: (obj: FiltersClaimsInterface) => void;
}

const useFiltersClaims = create<FiltersClaimsStore>(set => ({
  filterValuesDefault: filtersObjValuesWithOwner(0),
  filterValuesChange: filtersObjValuesWithOwner(0),
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersClaims;
