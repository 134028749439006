import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import React from 'react';
import { FlexBox } from '../../../tableItems';

interface Props {
  children: React.ReactNode;
}

const TableSiteAccess = ({ children }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: '15px' }}>
              <FlexBox>
                <Typography fontWeight={600} fontSize={14}>
                  Project
                </Typography>
                <SubdirectoryArrowRightIcon
                  fontSize="inherit"
                  color="disabled"
                  sx={{ marginTop: 0.5, marginX: 0.4 }}
                />
                <Typography fontWeight={600} fontSize={14}>
                  Site
                </Typography>
              </FlexBox>
            </TableCell>

            <TableCell
              align="right"
              sx={{
                fontSize: '13px',
                fontWeight: 600,
              }}
            >
              Start Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSiteAccess;
