import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';
import { TextFormField } from '../Forms/FormInputs';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface BulkProspectProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  id: number;
  loading: boolean;
  type: string;
  changeStatus: (
    id: number,
    option: string,
    newId?: number,
    obj?: { status: string; text_note: string },
  ) => Promise<void>;
}

const SiteStatusChange = ({
  open,
  closeModal,
  id,
  status,
  changeStatus,
  loading,
  type,
}: BulkProspectProps) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    return () => {
      setNote('');
    };
  }, [open]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            {status} {type}
          </Typography>
          <Typography marginTop={2} fontSize={14} textAlign="center">
            In order to {status} this {type.toLowerCase()} you need to write a
            note first
          </Typography>
          <Box marginTop={3.5}>
            <TextFormField
              disabled={false}
              nameInput="text_note"
              label="Note"
              error={false}
              multiline
              required
              value={note}
              errorText=""
              placeholder="Note"
              onChange={(value: string) => setNote(value)}
            />
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() =>
                  changeStatus(id, type, undefined, {
                    status: status === 'Deactivate' ? 'inactive' : 'active',
                    text_note: note,
                  })
                }
                disabled={note === ''}
                loading={loading}
                sx={{ marginLeft: 1 }}
              >
                Confirm
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default SiteStatusChange;
