import { Box, Typography, Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FlexBox } from '../../tableItems';
import { MainButton } from '../../buttons';
import { ContactsFormLink } from '../../../models/Forms';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 3,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOk: () => void;
  contacts: ContactsFormLink[];
  loading?: boolean;
}

const isLowerThan90Days = (day: string) => {
  const diffMilSeconds = Math.abs(+new Date(day) - +new Date());
  const diffDays = Math.ceil(diffMilSeconds / (1000 * 60 * 60 * 24));
  return diffDays < 90;
};

const ConfirmEmail = ({
  open,
  closeModal,
  handleOk,
  contacts,
  loading,
}: Props) => {
  const [contactsFinal, setContactsFinal] = useState<ContactsFormLink[]>([]);

  useEffect(() => {
    const final = contacts.filter(contact =>
      isLowerThan90Days(contact.last_outreach ?? ''),
    );
    setContactsFinal(final);
  }, [contacts]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          fontWeight={700}
          fontSize={17}
          textAlign="center"
          textTransform="capitalize"
        >
          Outreach contacts
        </Typography>
        <Box marginTop={1}>
          <Typography fontSize={14} marginBottom={0.5} textAlign="center">
            Are you sure you would like to proceed with the outreach attempt?
          </Typography>
        </Box>
        {contactsFinal.length > 0 && (
          <>
            <Box marginTop={2}>
              <Typography
                fontSize={12}
                marginBottom={0.5}
                textAlign="left"
                color="red"
              >
                Please note: The following contacts were recently emailed
              </Typography>
            </Box>
            <Box
              marginTop={1}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              gap={2}
            >
              {contactsFinal.map(contact => (
                <Typography
                  fontSize={14}
                  marginBottom={0.5}
                  marginRight={1}
                  key={contact.id}
                >
                  -{' '}
                  {contact.form_text !== ''
                    ? contact.form_text
                    : `${contact.name} + ${contact.email}`}
                </Typography>
              ))}
            </Box>
          </>
        )}
        <Box marginTop={3.5}>
          <FlexBox marginTop={3} justifyContent="center">
            <MainButton
              onClick={() => closeModal(false)}
              variant="contained"
              sx={{
                backgroundColor: '#F0F0F0',
                color: 'black',
                marginRight: 1,
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
            >
              Cancel
            </MainButton>
            <MainButton
              variant="contained"
              loading={loading}
              onClick={handleOk}
              sx={{ marginLeft: 1 }}
            >
              Ok
            </MainButton>
          </FlexBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmEmail;
