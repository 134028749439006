/* eslint-disable react/jsx-props-no-spreading */
import { Box, TextField } from '@mui/material';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';

const calendarIcon = () => {
  return (
    <CalendarMonthIcon
      sx={{
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.2)',
        '&:hover': { color: 'rgba(0, 0, 0, 0.4)' },
      }}
    />
  );
};

interface Props {
  site: {
    id: number;
    date: string;
  };
  handleChangeDate: (date: string, id?: number) => void;
  disabled: boolean;
}

const DateInputPS = ({ site, handleChangeDate, disabled }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={site.date}
          disabled={disabled}
          components={{ OpenPickerIcon: calendarIcon }}
          InputProps={{
            sx: {
              flexDirection: 'row-reverse',
              fontSize: 13,
              width: '135px',
            },
            readOnly: true,
          }}
          renderInput={params => (
            <Box position="relative" display="inline-block">
              <TextField
                onKeyDown={e => e.preventDefault()}
                {...params}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingRight: '5px',
                    paddingLeft: '7px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D7D7D7',
                      borderRadius: '6px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ABABAB',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ABABAB',
                    },
                  },
                }}
              />
            </Box>
          )}
          onChange={newValue =>
            handleChangeDate(dayjs(newValue).format('MM/DD/YYYY'), site.id)
          }
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateInputPS;
