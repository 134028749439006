import React from 'react';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { SearchProjectsSites } from '../../../inputs';

interface Props {
  onChangeFunc: (value: string) => void;
  onBlurFunc: () => void;
  onFocusFunc: () => void;
}

const SearchInputModal = ({ onChangeFunc, onBlurFunc, onFocusFunc }: Props) => {
  const searchFunction = debounce(onChangeFunc, 700);
  return (
    <SearchProjectsSites
      disabled={false}
      onBlur={onBlurFunc}
      onFocus={onFocusFunc}
      onChange={e => searchFunction(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      size="small"
      fullWidth
      type="text"
      placeholder="Search Projects/Sites"
      id="projects_sites"
    />
  );
};

export default SearchInputModal;
