import axios from 'axios';
import APIClient from '../services/APIClient';

export const unsubscribeUser = async (
  id: string,
  email: string,
  body?: { feedback: string },
) => {
  const { REACT_APP_API_BASE_URL } = process.env;
  const response = await axios.post(
    `${REACT_APP_API_BASE_URL}/api/unsubscribe/?id=${id}&email=${email}`,
    body,
  );
  return response.data;
};

export const revertSubscribeUser = async (id: string, email: string) => {
  const { REACT_APP_API_BASE_URL } = process.env;
  const response = await axios.post(
    `${REACT_APP_API_BASE_URL}/api/unsubscribe/revert/?id=${id}&email=${email}`,
  );
  return response.data;
};

export const fetchUnsubscribeCount = async (userId?: number) => {
  const clientAPI = new APIClient();
  const unsubscribeCountResponse = await clientAPI.client.get<{
    total: number;
    previous_month: number;
  }>(`/v2/links/unsubscribe-counter/${userId ? `?owner=${userId}` : ''}`);
  return unsubscribeCountResponse.data;
};

export const fetchDomainCount = async (userId?: number) => {
  const clientAPI = new APIClient();
  const domainCountResponse = await clientAPI.client.get<{
    total: number;
  }>(`/v2/links/exceptions-remaining/${userId ? `?user_id=${userId}` : ''}`);
  return domainCountResponse.data;
};
