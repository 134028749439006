import { LoadingButton } from '@mui/lab';
import { styled, Button, ButtonProps, IconButton } from '@mui/material';

export const ColorButton = styled(LoadingButton)<ButtonProps>(() => ({
  backgroundColor: '#000000',
  color: '#FFFFFF',
  fontWeight: 'bold',
  letterSpacing: '1.7px',
  '&:hover': {
    backgroundColor: '#211F1F',
  },
}));

export const MainButton = styled(LoadingButton)<ButtonProps>(() => ({
  backgroundColor: '#000000',
  color: '#FFFFFF',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  boxShadow: 'none',
  letterSpacing: '1px',
  '&:hover': {
    backgroundColor: '#211F1F',
  },
}));

export const ApproveRejectButton = styled(LoadingButton)<ButtonProps>(() => ({
  color: '#FFFFFF',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  boxShadow: 'none',
  letterSpacing: '1px',
  padding: '2.3px 12px',
  borderRadius: '4px',
  width: '150px',
}));

export const BulkButton = styled(LoadingButton)<ButtonProps>(() => ({
  backgroundColor: 'white',
  color: 'black',
  fontWeight: 'bold',
  fontSize: 12,
  padding: '4px 10px',
  textTransform: 'capitalize',
  boxShadow: 'none',
  border: '1px solid #787878',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: '#F2F2F2',
  },
}));

export const GoogleButton = styled(Button)<ButtonProps>(() => ({
  fontWeight: 'bold',
  textTransform: 'none',
}));

export const IconButtonCustom = styled(IconButton)({
  padding: 0.3,
  color: '#C7C7C7',
  '&:hover': { color: 'rgba(0, 0, 0, 0.5)' },
});
