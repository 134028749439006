import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../Accordion/Accordion';
import { FlexBox, FlexBoxBetween } from '../../tableItems';
import { filtersObj } from '../../../models/Filters';
import useFavFilters from '../../../customHooks/useFavFilters';
import Spinner from '../../Loading/Spinner';
import { DefaultCheckbox } from '../../inputs';
import { MessagesInt } from '../../../models/General';
import { createFavFilter, deleteFavFilter } from '../../../helpers/user';
import { FavoriteFilter } from '../../../models/User';
import AddNewFilter from './AddNewFilter';
import { LoadingContextType } from '../../../models/Context';
import LoadingContext from '../../../context/LoadingContext';
import DeleteFavFilter from './DeleteFavFilter';
import useFiltersLinks from '../../../stores/filters/useFiltersLinks';

interface Props {
  disabledAdd: boolean;
  showMessage: (obj: MessagesInt) => void;
  setClearAll: React.Dispatch<React.SetStateAction<boolean>>;
  clearAll: boolean;
  updateFavFilterId: (id: number) => void;
  favFilterId: number;
}

const FavFilters = ({
  disabledAdd,
  showMessage,
  setClearAll,
  clearAll,
  updateFavFilterId,
  favFilterId,
}: Props) => {
  const filtersLinks = useFiltersLinks();
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const [loadingActions, setLoadingActions] = useState(false);
  const [id, setId] = useState(0);
  const [deleteObj, setDeleteObj] = useState({ id: 0, name: '' });
  const [open, setOpen] = useState(false);
  const [render, setRender] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const { filters: filtersList, getFiltersList, loading } = useFavFilters();

  const addNewFavFilter = async (name: string) => {
    setLoadingActions(true);
    try {
      const obj: FavoriteFilter = {
        name,
        type: 'Default',
        body: JSON.stringify(filtersLinks.filterValuesChange),
      };
      await createFavFilter(obj);
      showMessage({
        severity: 'success',
        message: 'Favorite filter added successfully',
      });
      setOpen(false);
      getFiltersList();
    } catch (err) {
      showMessage({
        severity: 'error',
        message: 'Error adding new favorite filter',
      });
    } finally {
      setLoadingActions(false);
    }
  };

  const handleDelete = async () => {
    setLoadingActions(true);
    try {
      await deleteFavFilter(deleteObj.id);
      showMessage({
        severity: 'success',
        message: 'Favorite filter deleted successfully',
      });
      setOpenDelete(false);
      if (deleteObj.id === id) {
        setId(0);
        updateFavFilterId(0);
        filtersLinks.updateFiltersChange(filtersObj);
        filtersLinks.updateFiltersDefault(filtersObj);
        setClearAll(!clearAll);
      }
      getFiltersList();
    } catch (err) {
      showMessage({
        severity: 'error',
        message: 'Error deleting favorite filter',
      });
    } finally {
      setLoadingActions(false);
    }
  };

  const handleCheck = (idFilter: number, body: string) => {
    if (idFilter === id) {
      setId(0);
      updateFavFilterId(0);
      filtersLinks.updateFiltersChange(filtersObj);
      filtersLinks.updateFiltersDefault(filtersObj);

      setClearAll(!clearAll);
      return;
    }
    setId(idFilter);
    updateFavFilterId(idFilter);
    const newObj = JSON.parse(body);
    if (!newObj.show_only_emails)
      newObj.show_only_emails = { id: 'show_only_emails', value: '' };
    if (!newObj.show_cannibalization_attempts)
      newObj.show_cannibalization_attempts = {
        id: 'show_cannibalization_attempts',
        value: '',
      };
    filtersLinks.updateFiltersChange(newObj);
    filtersLinks.updateFiltersDefault(newObj);
  };

  useEffect(() => {
    if (render > 0) {
      setId(0);
    }
  }, [clearAll]);

  useEffect(() => {
    if (favFilterId !== id) setId(favFilterId);
  }, [favFilterId]);

  useEffect(() => {
    setRender(1);
  }, []);

  return (
    <>
      <Accordion>
        <AccordionSummary
          disabled={false}
          aria-controls="fav-filters"
          id="fav-filters-header"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <FlexBox>
              <Typography
                textTransform="capitalize"
                fontSize={12}
                color="#000000"
                fontWeight={700}
              >
                Favorite Filters
              </Typography>
            </FlexBox>
            <IconButton
              sx={{ padding: 0.3 }}
              disabled={disabledAdd}
              onClick={e => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              <AddIcon
                sx={{
                  fontSize: '18px',
                }}
              />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {loading && <Spinner />}
          {!loading && (
            <>
              {filtersList.length === 0 && (
                <Typography fontSize={13}>
                  There are no favorite filters
                </Typography>
              )}
              {filtersList.length > 0 && (
                <Box padding={0}>
                  {filtersList.map(filter => (
                    <FlexBoxBetween key={filter.id}>
                      <FlexBox>
                        <DefaultCheckbox
                          size="small"
                          sx={{ paddingY: 1 }}
                          disabled={isLoadingContext.loading}
                          checked={filter.id === id}
                          onClick={() => handleCheck(filter.id, filter.body)}
                        />
                        <Typography
                          fontSize={13}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '130px',
                          }}
                        >
                          {filter.name}
                        </Typography>
                      </FlexBox>
                      <IconButton
                        sx={{ padding: 0.3 }}
                        onClick={e => {
                          e.stopPropagation();
                          setDeleteObj({ id: filter.id, name: filter.name });
                          setOpenDelete(true);
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: '15px',
                          }}
                        />
                      </IconButton>
                    </FlexBoxBetween>
                  ))}
                </Box>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <AddNewFilter
        open={open}
        closeModal={setOpen}
        loading={loadingActions}
        addNewFavFilter={addNewFavFilter}
      />
      <DeleteFavFilter
        open={openDelete}
        closeModal={setOpenDelete}
        name={deleteObj.name}
        loading={loadingActions}
        deleteFunc={handleDelete}
      />
    </>
  );
};

export default FavFilters;
