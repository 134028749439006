/* eslint-disable react/jsx-props-no-spreading */
import { FormControl } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { TagsSelect } from '../Forms/FormInputs';
import { BasicArray } from '../../models/General';

interface Props {
  options: BasicArray[];
  value: BasicArray[];
  id: string;
  updateFiltersValue: (id: string, value: string) => void;
  clearAll: boolean;
  disabledInput: boolean;
  placeholder: string;
  onEnter?: () => void;
}

const AutoCompleteSelect = ({
  value,
  id,
  updateFiltersValue,
  disabledInput,
  options,
  clearAll,
  placeholder,
  onEnter,
}: Props) => {
  const [render, setRender] = useState(0);
  const [values, setValues] = useState<BasicArray[]>([]);

  const onChangeValues = () => {
    const newValues = values.map(item => item.id).join(',');
    const valueOld = value.map(item => item.id).join(',');
    if (newValues !== valueOld) updateFiltersValue(id, newValues);
  };

  useEffect(() => {
    const newValues = values.map(item => item.id).join(',');
    const valueOld = value.map(item => item.id).join(',');
    if (newValues !== valueOld) setValues(value);
  }, [value]);

  useEffect(() => {
    if (render !== 0 && options.length === 0) setValues([]);
  }, [options]);

  useEffect(() => {
    if (render !== 0) {
      setValues([]);
    }
  }, [clearAll]);

  useEffect(() => {
    setRender(1);
  }, []);

  useEffect(() => {
    if (onEnter) onChangeValues();
  }, [values]);

  const updateValue = (val: BasicArray[]) => setValues(val);

  return (
    <FormControl fullWidth>
      <TagsSelect
        value={values}
        options={options}
        disabled={disabledInput}
        placeholder={placeholder}
        required
        limitTags={0}
        hideLabel
        onEnter={onEnter}
        updateSelect={updateValue}
        onBlurFunc={onChangeValues}
      />
    </FormControl>
  );
};

export default AutoCompleteSelect;
