import {
  Box,
  Divider,
  FormControlLabel,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect, useContext } from 'react';
import { fetchAffinities } from '../../../helpers/affinities';
import { fetchAudiences } from '../../../helpers/audience';
import { addSite, fetchSingleSite, updateSite } from '../../../helpers/sites';
import {
  onlyNumbers,
  validateUrlInputs,
} from '../../../helpers/validateInputs';
import {
  PropsSitesForm,
  SiteCreateInt,
  SiteFormInt,
  UsersAssignedSelected,
  UsersSites,
} from '../../../models/Forms';
import {
  BasicArray,
  SeverityType,
  statusOptions,
  tabs,
} from '../../../models/General';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import FeedbackMessage from '../../FeedbackMessage';
import Spinner from '../../Loading/Spinner';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import BoxAddSettings from '../BoxAddSettings';
import {
  BasicSelect,
  SelectFormInput,
  TagsSelect,
  TextFormField,
} from '../FormInputs';
import FormNotes from '../FormNotes';
import FormStatus from '../FormStatus';
import StatusModal from '../StatusModal';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import useNotes from '../../../customHooks/useNotes';
import Context from '../../../context/UserContext';
import { UserContextType, UserResponse } from '../../../models/User';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { ROLES_SITES } from '../../../constants/user';
import { DefaultCheckbox } from '../../inputs';
import useProjects from '../../../customHooks/useProjects';
import useSites from '../../../customHooks/useSites';
import { MainButton } from '../../buttons';
import UsersSelection from './UsersSelection';
import UsersSiteTable from './UsersSiteTable';
import UsersAssignedSelection from './UsersAssignedSelection';
import { getAllUsers } from '../../../helpers/user';

const statusLinkOptions = [
  { id: 'text_link', name: 'Text Link' },
  { id: 'active', name: 'Active' },
  { id: 'approved', name: 'Approved' },
  { id: 'closed', name: 'Closed' },
  { id: 'pending', name: 'Pending' },
  { id: 'open', name: 'Open' },
  { id: 'to_do', name: 'To Do' },
  { id: 'email_bounced', name: 'Email Bounced' },
  { id: 'contact_not_found', name: 'No Contact Found' },
  { id: 'not_found', name: '404' },
  { id: 'link_removed', name: 'Link Removed' },
  { id: 'deleted', name: 'Deleted' },
  { id: 'curl_error', name: 'cURL Error' },
];

const AddSite = ({
  setOpenDrawer,
  clientsOptions,
  categoryOptions,
  clientValue,
  categoryValue,
  type,
  createSuccess,
  clientsCategories,
  projectValue,
  siteEdit,
}: PropsSitesForm) => {
  const [openModalUsers, setOpenModalUsers] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [usersArr, setUsersArr] = useState<UserResponse[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [openModalUsersAssigned, setOpenModalUsersAssigned] = useState(false);
  const { UserContext } = useContext(Context) as UserContextType;
  const [originalState, setOriginalState] = useState('');
  const { projects } = useProjects({});
  const { projects: projectsToChange } = useProjects({
    categoryId: categoryValue,
  });
  // const { owners } = useOwners({ withFields: true });
  const { sites } = useSites({ minimal: true, userId: UserContext.id });
  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: siteEdit ?? 0,
    type: 'site',
  });
  const { history } = useHistoryTab({ id: siteEdit ?? 0, type: 'site' });

  const [openModal, setOpenModal] = useState(false);
  const [tabDrawer, setTabDrawer] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [messagesProps, setMessagesProps] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'error',
  });
  const [openMessage, setOpenMessage] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [inputErrors, setInputErrors] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [projectsArr, setProjectsArr] = useState<
    { id: number; name: string }[]
  >([]);
  const [projectsArrayToChange, setProjectsArrToChange] = useState<
    { id: number; name: string }[]
  >([]);
  const [sitesArr, setSitesArr] = useState<{ id: number; name: string }[]>([]);
  const [categoriesArr, setCategoriesArr] = useState(categoryOptions);
  const [render, setRender] = useState(0);
  const [affinities, setAffinities] = useState<{ id: number; name: string }[]>(
    [],
  );
  const [audiences, setAudiences] = useState<{ id: number; name: string }[]>(
    [],
  );

  const [siteForm, setSiteForm] = useState<SiteFormInt>({
    name: '',
    domain: '',
    status: '',
    text_note: '',
    client: clientValue,
    category: categoryValue,
    project: projectValue ?? 0,
    default_percentage: '0',
    affinities: [],
    audiences: [],
    link_claims_allowed: false,
    allow_cannibalization: true,
    physical_addr: '',
    site_title: '',
    days_before_cannibalization: '60',
    flat_amount: '',
    default_min_links_required: '50',
    blacklisted_status_list: ['closed', 'pending'],
    blacklisted_projects: [],
    blacklisted_sites: [],
    user_cannibalization_rule: [],
    users_assigned: [],
  });

  const canEdit =
    type === 'edit'
      ? !sendBackObject(UserContext.profile.role, ROLES_SITES).edit
      : !sendBackObject(UserContext.profile.role, ROLES_SITES).add;

  const inputsArr = [
    {
      id: 45,
      value: siteForm.site_title,
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={canEdit}
          nameInput="site_title"
          label="Site Title"
          error={false}
          value={siteForm.site_title}
          errorText=""
          placeholder="Site Title"
          onChange={(value: string) =>
            setSiteForm({ ...siteForm, site_title: value })
          }
        />
      ),
    },
    {
      id: 44,
      value: siteForm.physical_addr,
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={canEdit}
          nameInput="Physical Address"
          label="Physical Address"
          error={false}
          value={siteForm.physical_addr}
          errorText=""
          placeholder="Physical Address"
          onChange={(value: string) =>
            setSiteForm({ ...siteForm, physical_addr: value })
          }
        />
      ),
    },
    {
      id: 0,
      value: siteForm.name,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="name"
          label="Name"
          error={false}
          value={siteForm.name}
          errorText=""
          placeholder="Name"
          onChange={(value: string) =>
            setSiteForm({ ...siteForm, name: value })
          }
        />
      ),
    },
    {
      id: 6,
      value: siteForm.domain,
      fullSpace: true,
      required: true,
      validate: true,
      validation: validateUrlInputs(siteForm.domain, 6, inputErrors),
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="domain"
          label="Domain"
          onBlurValidate={() => {
            const validate = validateUrlInputs(siteForm.domain, 6, inputErrors);
            setInputErrors(validate);
          }}
          error={inputErrors.includes(6)}
          value={siteForm.domain}
          errorText={inputErrors.includes(6) ? 'Insert a valid domain' : ''}
          placeholder="Domain"
          onChange={(value: string) =>
            setSiteForm({ ...siteForm, domain: value })
          }
        />
      ),
    },
    {
      id: 25,
      value: siteForm.project === 0 ? '' : 'true',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={projectsToChange}
          disabled={canEdit}
          placeholder="Project"
          value={
            projectsToChange.find(item => item.id === siteForm.project) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setSiteForm({
              ...siteForm,
              project: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 4,
      value: siteForm.affinities.length > 0 ? 'true' : '',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TagsSelect
          value={affinities.filter(
            aff => siteForm.affinities.indexOf(aff.id) !== -1,
          )}
          options={affinities}
          disabled={canEdit}
          placeholder="Affinity"
          required
          updateSelect={(value: BasicArray[]) =>
            setSiteForm({
              ...siteForm,
              affinities: value.map(item => {
                const id = item.id as number;
                return id;
              }),
            })
          }
        />
      ),
    },
    {
      id: 5,
      value: siteForm.audiences.length > 0 ? 'true' : '',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TagsSelect
          value={audiences.filter(
            aud => siteForm.audiences.indexOf(aud.id) !== -1,
          )}
          options={audiences}
          disabled={canEdit}
          placeholder="Audience"
          required
          updateSelect={(value: BasicArray[]) =>
            setSiteForm({
              ...siteForm,
              audiences: value.map(item => {
                const id = item.id as number;
                return id;
              }),
            })
          }
        />
      ),
    },
    {
      id: 18,
      value: siteForm.link_claims_allowed ? 'true' : 'false',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Link claims allowed"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mb: 2,
              mt: '-5px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={false}
              sx={{ mt: '-5px', ml: 1, mb: 2 }}
              size="medium"
              checked={siteForm.link_claims_allowed}
              onClick={e =>
                setSiteForm({
                  ...siteForm,
                  link_claims_allowed: !siteForm.link_claims_allowed,
                })
              }
            />
          }
        />
      ),
    },
    {
      id: 19,
      value: siteForm.allow_cannibalization ? 'true' : 'false',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Allow Cannibalization"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-5px',
              mb: 2,
            },
          }}
          control={
            <DefaultCheckbox
              disabled={false}
              sx={{ mt: '-5px', ml: 1, mb: 2 }}
              size="medium"
              checked={siteForm.allow_cannibalization}
              onClick={e =>
                setSiteForm({
                  ...siteForm,
                  allow_cannibalization: !siteForm.allow_cannibalization,
                })
              }
            />
          }
        />
      ),
    },
    {
      id: 20,
      value: siteForm.days_before_cannibalization,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="days-cannibalization"
          label="Days Before Cannibalization"
          value={siteForm.days_before_cannibalization}
          error={false}
          type="number"
          errorText=""
          placeholder="0"
          onChange={(value: string) =>
            setSiteForm({
              ...siteForm,
              days_before_cannibalization: onlyNumbers(value),
            })
          }
        />
      ),
    },
    {
      id: 21,
      value: siteForm.default_min_links_required,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="min-links"
          label="Minimum links required for cannibalization"
          value={siteForm.default_min_links_required}
          error={false}
          type="number"
          errorText=""
          placeholder="0"
          onChange={(value: string) =>
            setSiteForm({
              ...siteForm,
              default_min_links_required: onlyNumbers(value),
            })
          }
        />
      ),
    },
    {
      id: 32,
      value: siteForm.default_percentage,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="default-percentage"
          label="Default Percentage"
          value={siteForm.default_percentage}
          error={false}
          type="number"
          errorText=""
          placeholder="0"
          onChange={(value: string) => {
            setSiteForm({
              ...siteForm,
              default_percentage: onlyNumbers(value),
            });
          }}
        />
      ),
    },
    // {
    //   id: 22,
    //   value: siteForm.blacklisted_status_list.length > 0 ? 'true' : '',
    //   fullSpace: true,
    //   required: false,
    //   validate: false,
    //   validation: [],
    //   body: (
    //     <TagsSelect
    //       value={statusLinkOptions.filter(
    //         item => siteForm.blacklisted_status_list.indexOf(item.id) !== -1,
    //       )}
    //       options={statusLinkOptions}
    //       disabled={canEdit}
    //       placeholder="Status list to disable cannibalization"
    //       required
    //       updateSelect={(value: BasicArray[]) =>
    //         setSiteForm({
    //           ...siteForm,
    //           blacklisted_status_list: value.map(item => {
    //             const id = item.id as string;
    //             return id;
    //           }),
    //         })
    //       }
    //     />
    //   ),
    // },

    {
      id: 25,
      value: siteForm.flat_amount,
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="flat-amount"
          label="Flat Amount"
          value={siteForm.flat_amount ?? ''}
          error={false}
          type="number"
          errorText=""
          placeholder="0"
          onChange={(value: string) => {
            setSiteForm({
              ...siteForm,
              flat_amount: onlyNumbers(value),
            });
          }}
        />
      ),
    },
    {
      id: 23,
      value: siteForm.blacklisted_projects.length > 0 ? 'true' : '',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TagsSelect
          value={projectsArr.filter(
            item => siteForm.blacklisted_projects.indexOf(item.id) !== -1,
          )}
          options={projectsArr}
          disabled={canEdit}
          placeholder="Projects to disable cannibalization"
          required
          updateSelect={(value: BasicArray[]) =>
            setSiteForm({
              ...siteForm,
              blacklisted_projects: value.map(item => {
                const id = item.id as number;
                return id;
              }),
            })
          }
        />
      ),
    },
    {
      id: 24,
      value: siteForm.blacklisted_sites.length > 0 ? 'true' : '',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TagsSelect
          value={sitesArr.filter(
            item => siteForm.blacklisted_sites.indexOf(item.id) !== -1,
          )}
          options={sitesArr}
          disabled={canEdit}
          placeholder="Sites to disable cannibalization"
          required
          updateSelect={(value: BasicArray[]) =>
            setSiteForm({
              ...siteForm,
              blacklisted_sites: value.map(item => {
                const id = item.id as number;
                return id;
              }),
            })
          }
        />
      ),
    },
  ];

  if (type === 'edit') {
    inputsArr.splice(3, 0, {
      id: 7,
      value: siteForm.status,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <BasicSelect
          label="Status"
          required
          nameInput="status"
          disabled={canEdit}
          value={siteForm.status}
          options={statusOptions}
          onChange={(value: string) => {
            if (value !== originalState) setOpenModal(true);
            else {
              setSiteForm({
                ...siteForm,
                status: value,
                text_note: '',
              });
            }
          }}
        />
      ),
    });
  }

  const getAffinities = async () => {
    try {
      const resp = await fetchAffinities();
      setAffinities(
        resp.map((item: { id: number; name: string }) => {
          return { id: item.id, name: item.name };
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getAudiences = async () => {
    try {
      const resp = await fetchAudiences();
      setAudiences(
        resp.map((item: { id: number; name: string }) => {
          return { id: item.id, name: item.name };
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getSite = async () => {
    try {
      const resp = await fetchSingleSite(siteEdit ?? 0);
      setSiteForm({
        ...siteForm,
        name: resp.name,
        domain: resp.domain,
        status: resp.status,
        affinities: resp.affinities.map(item => item.id),
        audiences: resp.audiences.map(item => item.id),
        link_claims_allowed: resp.link_claims_allowed,
        physical_addr: resp.physical_addr ?? '',
        site_title: resp.site_title ?? '',
        flat_amount: resp.flat_amount ? resp.flat_amount.toString() : '',
        allow_cannibalization: resp.allow_cannibalization,
        default_percentage: resp.default_percentage.toString(),
        days_before_cannibalization:
          resp.days_before_cannibalization.toString(),
        default_min_links_required: resp.default_min_links_required.toString(),
        blacklisted_status_list: resp.blacklisted_status_list,
        blacklisted_projects: resp.blacklisted_projects.map(item => item.id),
        blacklisted_sites: resp.blacklisted_sites.map(item => item.id),
        users_assigned: resp.users_assigned.map(item => {
          return {
            ...item,
            date_effective: item.date_effective
              ? dayjs(item.date_effective).format('MM/DD/YYYY')
              : dayjs().format('MM/DD/YYYY'),
          };
        }),
        user_cannibalization_rule: resp.user_cannibalization_rule.map(item => {
          return {
            id: item.user.id,
            minimum_links: item.minimum_links.toString(),
            username: item.user.username,
            percentage_allowed: item.percentage_allowed.toString(),
          };
        }),
      });
      setOriginalState(resp.status);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const saveSite = async (obj: SiteCreateInt) => {
    try {
      if (type === 'add') await addSite(obj);
      if (type === 'edit') await updateSite(obj, siteEdit ?? 0);
      setOpenDrawer(false);
      createSuccess(
        type === 'add'
          ? 'Site created succesfully'
          : 'Site updated succesfully',
        'site',
      );
    } catch (err) {
      const error = err as any;
      const msgs = Object.values(error.data).join(' - ');
      if (error.data.name) {
        setMessagesProps({
          severity: 'error',
          message: msgs,
        });
      } else {
        setMessagesProps({
          severity: 'error',
          message: 'There was an error, please try again',
        });
      }
      setOpenMessage(true);
    } finally {
      setLoadingSave(false);
    }
  };

  const validateAll = () => {
    setLoadingSave(true);
    const purge = inputsArr
      .filter(item => item.value !== '' && item.validation)
      .map(item => item.validation)
      .flat();
    if (purge.length > 0) {
      setInputErrors(purge);
      setLoadingSave(false);
      return;
    }
    const obj: SiteCreateInt = {
      name: siteForm.name,
      domain: siteForm.domain,
      status: type === 'add' ? 'active' : siteForm.status,
      text_note: type === 'add' ? 'New Site' : siteForm.text_note,
      project: siteForm.project,
      physical_addr: siteForm.physical_addr,
      site_title: siteForm.site_title,
      affinities: siteForm.affinities,
      audiences: siteForm.audiences,
      link_claims_allowed: siteForm.link_claims_allowed,
      allow_cannibalization: siteForm.allow_cannibalization,
      default_percentage: parseInt(siteForm.default_percentage, 10),
      days_before_cannibalization: parseInt(
        siteForm.days_before_cannibalization,
        10,
      ),
      flat_amount:
        siteForm.flat_amount !== '' ? parseInt(siteForm.flat_amount, 10) : 0,
      default_min_links_required: parseInt(
        siteForm.default_min_links_required,
        10,
      ),
      blacklisted_status_list: siteForm.blacklisted_status_list,
      blacklisted_projects: siteForm.blacklisted_projects,
      blacklisted_sites: siteForm.blacklisted_sites,
      users_assigned:
        siteForm.users_assigned.length === 0
          ? []
          : siteForm.users_assigned.map(item => {
              return {
                id: item.id,
                date_effective: dayjs(item.date_effective).format(),
              };
            }),
      user_cannibalization_rule:
        siteForm.user_cannibalization_rule.length === 0
          ? []
          : siteForm.user_cannibalization_rule.map(item => {
              return {
                id: item.id,
                minimum_links: parseInt(item.minimum_links, 10),
                percentage_allowed: parseInt(item.percentage_allowed, 10),
              };
            }),
    };
    saveSite(obj);
  };

  const updateUsers = (usersArray: UsersSites[]) =>
    setSiteForm({ ...siteForm, user_cannibalization_rule: usersArray });

  const updateUsersAssigned = (usersArray: UsersAssignedSelected[]) =>
    setSiteForm({ ...siteForm, users_assigned: usersArray });

  const updateStatus = (note: string) => {
    const newStatus = siteForm.status === 'active' ? 'inactive' : 'active';
    setSiteForm({ ...siteForm, text_note: note, status: newStatus });
    setOpenModal(false);
  };

  useEffect(() => {
    if (render === 1 && siteForm.category !== 0) {
      setSiteForm({ ...siteForm, project: 0 });
    }
  }, [siteForm.category]);

  useEffect(() => {
    if (render === 1) {
      setProjectsArr([]);
      setSiteForm({ ...siteForm, category: 0, project: 0 });
      const cats = clientsCategories.find(item => item.id === siteForm.client);
      setCategoriesArr(cats ? cats.categories : []);
    }
  }, [siteForm.client]);

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const users = await getAllUsers(1, 10, '', 'username');
      setUsersArr(users.results);
      setUsersCount(users.count);
    } catch (err) {
      setUsersArr([]);
      setUsersCount(0);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const getAllPromises = async () =>
    Promise.all([getAffinities(), getAudiences(), fetchUsers()]);

  const getAllPromisesEdit = async () =>
    Promise.all([getSite(), getAffinities(), getAudiences(), fetchUsers()]);

  useEffect(() => {
    if (projectsToChange.length > 0) {
      setProjectsArrToChange(
        projectsToChange
          .filter(item => item.status === 'active')
          .map(item => ({ id: item.id, name: item.name })),
      );
    }
  }, [projectsToChange]);

  useEffect(() => {
    if (projects.length > 0) {
      setProjectsArr(
        projects
          .filter(item => item.status === 'active')
          .map(item => ({ id: item.id, name: item.name })),
      );
    }
  }, [projects]);

  useEffect(() => {
    if (sites.length > 0) {
      setSitesArr(sites.map(item => ({ id: item.id, name: item.name })));
    }
  }, [sites]);

  useEffect(() => {
    const errors = inputsArr.filter(
      item => item.value.trim() === '' && item.required,
    );
    if (errors.length > 0) setDisableButton(true);
    else setDisableButton(false);
  }, [siteForm]);

  useEffect(() => {
    setLoading(true);
    if (type === 'add') getAllPromises();
    if (type === 'edit') getAllPromisesEdit();
    setRender(1);
  }, []);

  return (
    <>
      <HeaderDrawer
        title={type === 'add' ? 'Add Site' : 'Edit Site'}
        threeButtons={false}
        loading={loadingSave}
        closeDrawer={setOpenDrawer}
        disabled={
          !(disableButton === false && inputErrors.length === 0) || canEdit
        }
        saveFunction={validateAll}
      />
      {type === 'add' && (
        <>
          <BoxAddSettings title="Site Info" mb={0}>
            <div className="form-grids">
              {inputsArr.slice(2, 5).map(input => (
                <div
                  key={input.id}
                  className={`mb-2 ${
                    input.fullSpace ? 'whole-grid_three' : ''
                  }`}
                >
                  {input.body}
                </div>
              ))}
            </div>
            <Box marginTop={4}>
              <Typography
                marginBottom={2}
                color="rgba(0, 0, 0, 0.8)"
                fontWeight={600}
                fontSize={15}
              >
                Signature
              </Typography>
              <Divider />
              <Box marginTop={2}>
                <div className="form-grids">
                  {inputsArr.slice(0, 2).map(input => (
                    <div
                      key={input.id}
                      className={`mb-2 ${
                        input.fullSpace ? 'whole-grid_three' : ''
                      }`}
                    >
                      {input.body}
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
            <Box marginTop={4}>
              <Typography
                marginBottom={2}
                color="rgba(0, 0, 0, 0.8)"
                fontWeight={600}
                fontSize={15}
              >
                Affinity & Audience
              </Typography>
              <Divider />
              <Box marginTop={2}>
                <div className="form-grids">
                  {inputsArr.slice(5, 7).map(input => (
                    <div
                      key={input.id}
                      className={`mb-2 ${
                        input.fullSpace ? 'whole-grid_three' : ''
                      }`}
                    >
                      {input.body}
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
          </BoxAddSettings>
          <BoxAddSettings title="Rules" pt={3}>
            <div className="form-grids">
              <div>{inputsArr[7].body}</div>
              <div>{inputsArr[8].body}</div>
            </div>
            <div className="form-grids">
              {inputsArr.slice(9).map(input => (
                <div
                  key={input.id}
                  className={`mb-2 ${
                    input.fullSpace ? 'whole-grid_three' : ''
                  }`}
                >
                  {input.body}
                </div>
              ))}
            </div>
          </BoxAddSettings>
          <BoxAddSettings
            pt={0}
            mb={6}
            title="User Rules"
            rightTitle={
              siteForm.user_cannibalization_rule.length > 0
                ? {
                    title: 'Manage',
                    onClick: () => setOpenModalUsers(true),
                  }
                : undefined
            }
          >
            {siteForm.user_cannibalization_rule.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography textAlign="center">
                  There are no users selected yet
                </Typography>
                <MainButton
                  variant="contained"
                  sx={{
                    background: 'rgba(0, 0, 0, 0.1)',
                    color: 'black',
                    marginTop: 2,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  onClick={() => setOpenModalUsers(true)}
                >
                  Add New
                </MainButton>
              </Box>
            )}
            {siteForm.user_cannibalization_rule.length > 0 && (
              <UsersSiteTable
                columnsNames={{
                  first: 'User',
                  second: 'Minimum Links for Cannibalization',
                  third: 'Percentage Allowed',
                }}
              >
                {siteForm.user_cannibalization_rule.map(user => (
                  <TableRow key={user.id} hover>
                    <TableCell component="th" scope="row">
                      {user.username}
                    </TableCell>
                    <TableCell align="center">{user.minimum_links}</TableCell>
                    <TableCell align="right">
                      {user.percentage_allowed}
                    </TableCell>
                  </TableRow>
                ))}
              </UsersSiteTable>
            )}
          </BoxAddSettings>
          <BoxAddSettings
            pt={0}
            mb={6}
            title="Users Assigned"
            rightTitle={
              siteForm.users_assigned.length > 0
                ? {
                    title: 'Manage',
                    onClick: () => setOpenModalUsersAssigned(true),
                  }
                : undefined
            }
          >
            {siteForm.users_assigned.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography textAlign="center">
                  There are no users selected yet
                </Typography>
                <MainButton
                  variant="contained"
                  sx={{
                    background: 'rgba(0, 0, 0, 0.1)',
                    color: 'black',
                    marginTop: 2,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  onClick={() => setOpenModalUsersAssigned(true)}
                >
                  Add New
                </MainButton>
              </Box>
            )}
            {siteForm.users_assigned.length > 0 && (
              <UsersSiteTable columnsNames={{ first: 'Name', second: 'Date' }}>
                {siteForm.users_assigned.map(user => (
                  <TableRow key={user.id} hover>
                    <TableCell component="th" scope="row">
                      {user.first_name} {user.last_name}
                    </TableCell>
                    <TableCell align="right">{user.date_effective}</TableCell>
                  </TableRow>
                ))}
              </UsersSiteTable>
            )}
          </BoxAddSettings>
        </>
      )}
      {type === 'edit' && (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: 1.3,
              marginLeft: 3,
              marginRight: 4,
            }}
          >
            <TabParent
              valueTab={tabDrawer}
              setTab={setTabDrawer}
              tabs={tabs}
              center
            />
          </Box>
          <TabPanel index={0} value={tabDrawer}>
            {loading && <Spinner />}
            {!loading && (
              <>
                <BoxAddSettings title="Site Info">
                  <div className="form-grids">
                    {inputsArr.slice(2, 6).map(input => (
                      <div
                        key={input.id}
                        className={`mb-2 ${
                          input.fullSpace ? 'whole-grid_three' : ''
                        }`}
                      >
                        {input.body}
                      </div>
                    ))}
                  </div>
                  <Box marginTop={4}>
                    <Typography
                      marginBottom={2}
                      color="rgba(0, 0, 0, 0.8)"
                      fontWeight={600}
                      fontSize={15}
                    >
                      Signature
                    </Typography>
                    <Divider />
                    <Box marginTop={2}>
                      <div className="form-grids">
                        {inputsArr.slice(0, 2).map(input => (
                          <div
                            key={input.id}
                            className={`mb-2 ${
                              input.fullSpace ? 'whole-grid_three' : ''
                            }`}
                          >
                            {input.body}
                          </div>
                        ))}
                      </div>
                    </Box>
                  </Box>
                  <Box marginTop={4}>
                    <Typography
                      marginBottom={2}
                      color="rgba(0, 0, 0, 0.8)"
                      fontWeight={600}
                      fontSize={15}
                    >
                      Affinity & Audience
                    </Typography>
                    <Divider />
                    <Box marginTop={2}>
                      <div className="form-grids">
                        {inputsArr.slice(6, 8).map(input => (
                          <div
                            key={input.id}
                            className={`mb-2 ${
                              input.fullSpace ? 'whole-grid_three' : ''
                            }`}
                          >
                            {input.body}
                          </div>
                        ))}
                      </div>
                    </Box>
                  </Box>
                </BoxAddSettings>
                <BoxAddSettings title="Rules" pt={0}>
                  <div className="form-grids">
                    <div>{inputsArr[8].body}</div>
                    <div>{inputsArr[9].body}</div>
                  </div>
                  <div className="form-grids">
                    {inputsArr.slice(10).map(input => (
                      <div
                        key={input.id}
                        className={`mb-2 ${
                          input.fullSpace ? 'whole-grid_three' : ''
                        }`}
                      >
                        {input.body}
                      </div>
                    ))}
                  </div>
                </BoxAddSettings>
                <BoxAddSettings
                  pt={0}
                  mb={6}
                  title="User Rules"
                  rightTitle={
                    siteForm.user_cannibalization_rule.length > 0
                      ? {
                          title: 'Manage',
                          onClick: () => setOpenModalUsers(true),
                        }
                      : undefined
                  }
                >
                  {siteForm.user_cannibalization_rule.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography textAlign="center">
                        There are no users selected yet
                      </Typography>
                      <MainButton
                        variant="contained"
                        sx={{
                          background: 'rgba(0, 0, 0, 0.1)',
                          color: 'black',
                          marginTop: 2,
                          '&:hover': {
                            backgroundColor: '#F2F2F2',
                          },
                        }}
                        onClick={() => setOpenModalUsers(true)}
                      >
                        Add New
                      </MainButton>
                    </Box>
                  )}
                  {siteForm.user_cannibalization_rule.length > 0 && (
                    <UsersSiteTable
                      columnsNames={{
                        first: 'User',
                        second: 'Minimum Links for Cannibalization',
                        third: 'Percentage Allowed',
                      }}
                    >
                      {siteForm.user_cannibalization_rule.map(user => (
                        <TableRow key={user.id} hover>
                          <TableCell component="th" scope="row">
                            {user.username}
                          </TableCell>
                          <TableCell align="center">
                            {user.minimum_links}
                          </TableCell>
                          <TableCell align="right">
                            {user.percentage_allowed}
                          </TableCell>
                        </TableRow>
                      ))}
                    </UsersSiteTable>
                  )}
                </BoxAddSettings>
                <BoxAddSettings
                  pt={0}
                  mb={6}
                  title="Users Assigned"
                  rightTitle={
                    siteForm.users_assigned.length > 0
                      ? {
                          title: 'Manage',
                          onClick: () => setOpenModalUsersAssigned(true),
                        }
                      : undefined
                  }
                >
                  {siteForm.users_assigned.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography textAlign="center">
                        There are no users selected yet
                      </Typography>
                      <MainButton
                        variant="contained"
                        sx={{
                          background: 'rgba(0, 0, 0, 0.1)',
                          color: 'black',
                          marginTop: 2,
                          '&:hover': {
                            backgroundColor: '#F2F2F2',
                          },
                        }}
                        onClick={() => setOpenModalUsersAssigned(true)}
                      >
                        Add New
                      </MainButton>
                    </Box>
                  )}
                  {siteForm.users_assigned.length > 0 && (
                    <UsersSiteTable
                      columnsNames={{ first: 'Name', second: 'Date' }}
                    >
                      {siteForm.users_assigned.map(user => (
                        <TableRow key={user.id} hover>
                          <TableCell component="th" scope="row">
                            {user.first_name} {user.last_name}
                          </TableCell>
                          <TableCell align="right">
                            {user.date_effective}
                          </TableCell>
                        </TableRow>
                      ))}
                    </UsersSiteTable>
                  )}
                </BoxAddSettings>
              </>
            )}
          </TabPanel>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="site"
                notes={notes}
                id={siteEdit ?? 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={2}>
            <BoxAddSettings>
              <FormStatus history={history} />
            </BoxAddSettings>
          </TabPanel>
        </>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        updateStatus={updateStatus}
      />
      <UsersSelection
        open={openModalUsers}
        closeModal={setOpenModalUsers}
        updateUsers={updateUsers}
        usersDefaultArray={usersArr}
        usersDefaultCount={usersCount}
        usersDefaultLoading={isLoadingUsers}
        selectedSitesForm={siteForm.user_cannibalization_rule}
      />
      <UsersAssignedSelection
        open={openModalUsersAssigned}
        closeModal={setOpenModalUsersAssigned}
        updateUsers={updateUsersAssigned}
        usersDefaultArray={usersArr}
        usersDefaultCount={usersCount}
        usersDefaultLoading={isLoadingUsers}
        selectedUsersForm={siteForm.users_assigned}
      />
    </>
  );
};

export default AddSite;
