import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import psl from 'psl';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TableWrapper from '../../components/Table/TableWrapper';
import ProspectingHeadTable from './ProspectingHeadTable';
import { prospectingHeadsCell } from '../../models/Table';
import {
  FlexBox,
  FlexBoxBetween,
  TableCellCustom,
} from '../../components/tableItems';
import { DefaultCheckbox } from '../../components/inputs';
import {
  SelectFormBulk,
  SelectFormProspecting,
} from '../../components/Forms/FormInputs';
import {
  ProspectFieldsAssign,
  ProspectingResponse,
} from '../../models/Prospecting';
import Context from '../../context/UserContext';
import { Owners, UserContextType } from '../../models/User';
import BulkProspect from '../../components/Modals/BulkProspect';
import { BasicArray } from '../../models/General';
import { submitProspect } from '../../helpers/prospecting';
import SubmitProspectConfirm from '../../components/Modals/SubmitProspectConfirm';
import { TemplatesRequest } from '../../models/Templates';
import { SitesRequestV2 } from '../../models/Sites';
import { StyledTableRow } from '../../components/LinksTable/LinksTable';

const defaultProspectProps = {
  prospecting_result: 0,
  user: 0,
  site: 0,
  affinity: 0,
  audience: 0,
  template: 0,
};

interface ProspectingTableProps {
  pageSize: number;
  updatePages: () => void;
  loading: boolean;
  error: boolean;
  prospects: ProspectingResponse[];
  fetchInfo: (
    newPage?: number,
    page_size?: number,
    filter?: string,
  ) => Promise<void>;
  prospectsProps: ProspectFieldsAssign[];
  handleUpdateProps: (
    arrId: number,
    field: keyof typeof defaultProspectProps,
    value: number,
  ) => void;
  handleBulkUpdate: (
    obj: ProspectFieldsAssign,
    selected: number[],
    selectedIndexes: number[],
    templateBulk?: TemplatesRequest[],
    audienceBulk?: BasicArray[],
    affinityBulk?: BasicArray[],
  ) => void;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  owners: Owners[];
  sites: SitesRequestV2[];
  audiences: BasicArray[][];
  affinities: BasicArray[][];
  templatesProspecting: TemplatesRequest[][];
}

const cannotAssignUser = ['linkbuilder', 'contractor'];

const ProspectingTable = ({
  loading,
  error,
  prospects,
  fetchInfo,
  handleUpdateProps,
  prospectsProps,
  handleBulkUpdate,
  handleMessage,
  owners,
  sites,
  audiences,
  affinities,
  templatesProspecting,
  pageSize,
  updatePages,
}: ProspectingTableProps) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectALL, setSelectALL] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    window.scrollTo(0, 0);
  };

  const handleSelect = (id: number, index: number) => {
    const newSelected = [...selected];
    const newSelectedIndexes = [...selectedIndexes];
    const selectedIndex = newSelected.indexOf(id);
    if (selectedIndex === -1) {
      newSelected.push(id);
      newSelectedIndexes.push(index);
    } else {
      newSelected.splice(selectedIndex, 1);
      newSelectedIndexes.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
    setSelectedIndexes(newSelectedIndexes);
  };

  const handleBulkSelectAll = () => {
    setSelectALL(false);
    const newSelected = prospects.map(n => n.id);
    const newSelectedIndexes = prospects.map((n, index) => index);
    setSelectedIndexes(newSelectedIndexes);
    setSelected(newSelected);
  };

  const handleSelectAll = () => {
    if (selected.length === prospects.length) {
      setSelectALL(false);
      setSelected([]);
      setSelectedIndexes([]);
    } else if (pageSize !== 300) {
      setSelectALL(true);
      updatePages();
      fetchInfo(1, 300);
    } else handleBulkSelectAll();
  };

  const openModal = () => setOpen(true);

  const handleUpdate = (
    obj: ProspectFieldsAssign,
    templateBulk?: TemplatesRequest[],
    audienceBulk?: BasicArray[],
    affinityBulk?: BasicArray[],
  ) => {
    handleBulkUpdate(
      obj,
      selected,
      selectedIndexes,
      templateBulk,
      audienceBulk,
      affinityBulk,
    );
    setOpen(false);
  };

  const removeZeroValues = (obj: ProspectFieldsAssign) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach(key => {
      const keyObj = key as keyof typeof defaultProspectProps;
      if (newObj[keyObj] === 0) delete newObj[keyObj];
    });
    return newObj;
  };

  const submitProspects = async (objects: ProspectFieldsAssign[]) => {
    try {
      await submitProspect(objects);
      handleMessage('Prospects submited succesfully', 'success');
      fetchInfo();
      setOpenConfirm(false);
      setOpen(false);
    } catch (err) {
      handleMessage('There was an error', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (selected.length === 0) {
      const newArrayOfObjects = prospectsProps.map(item =>
        removeZeroValues(item),
      );
      submitProspects(newArrayOfObjects);
      return;
    }
    const getAllObjects = prospectsProps.filter(item =>
      selected.includes(item.prospecting_result ?? 0),
    );
    const newArrayOfObjects = getAllObjects.map(item => removeZeroValues(item));
    submitProspects(newArrayOfObjects);
  };

  const handleSubmitModal = () => setOpenConfirm(true);

  const submitFromModal = (newProspects: ProspectFieldsAssign[]) => {
    setIsLoading(true);
    if (selected.length === 0) {
      const newArrayOfObjects = newProspects.map(item =>
        removeZeroValues(item),
      );
      submitProspects(newArrayOfObjects);
      return;
    }
    const getAllObjects = newProspects.filter(item =>
      selected.includes(item.prospecting_result ?? 0),
    );
    const newArrayOfObjects = getAllObjects.map(item => removeZeroValues(item));
    submitProspects(newArrayOfObjects);
  };

  const handleSubmitConfirm = (obj: ProspectFieldsAssign) => {
    const newProps = [...prospectsProps];
    const transform = newProps.map(item => {
      if (selected.includes(item.prospecting_result ?? 0)) {
        return {
          prospecting_result: item.prospecting_result,
          user: obj.user,
          site: obj.site,
          affinity: obj.affinity,
          audience: obj.audience,
          template: obj.template,
        };
      }
      return item;
    });
    submitFromModal(transform);
  };

  useEffect(() => {
    if (selectALL) handleBulkSelectAll();
    else setSelected([]);
  }, [prospects]);

  return (
    <>
      <TableWrapper
        loading={loading}
        error={error}
        type="prospecting"
        pagination={false}
        totalCells={5}
        fetchInfo={fetchInfo}
        itemsLength={prospects.length}
        countItems={1}
        pageSize={10}
        page={1}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <ProspectingHeadTable
            order="asc"
            orderBy="url"
            headCells={prospectingHeadsCell}
            checkbox={{
              checked:
                prospects.length > 0 && prospects.length === selected.length,
              indeterminate:
                selected.length > 0 && selected.length < prospects.length,
              click: handleSelectAll,
            }}
            selected={selected}
            onRequestSort={() => {}}
            openModal={openModal}
            handleSubmit={handleSubmitModal}
            length={prospects.length}
          />
        }
      >
        {prospects.length > 0 &&
          !loading &&
          prospectsProps.length > 0 &&
          prospectsProps.length === prospects.length && (
            <>
              {prospects.map((row, index) => {
                const labelId = `prospect-table-checkbox-${index}`;
                const templatesTransform = templatesProspecting[index].map(
                  item => ({
                    id: item.id,
                    name: item.title,
                  }),
                );
                const domainName = row.url
                  ? psl.get(new URL(row.url).hostname)
                  : '';

                const defaultDescription = row.description ?? '';

                return (
                  <StyledTableRow
                    hover
                    selected={false}
                    role="checkbox"
                    key={row.id}
                    className="column-row"
                  >
                    <TableCell
                      className="first_column"
                      component="th"
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#eeeeee' : '',
                      }}
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <FlexBoxBetween
                        sx={{
                          color: '#373737',
                          paddingTop: 2.3,
                          paddingBottom: 2.3,
                          paddingRight: 1,
                          paddingLeft: 1,
                        }}
                      >
                        <FlexBox>
                          <DefaultCheckbox
                            checked={selected.indexOf(row.id) !== -1}
                            onClick={() => handleSelect(row.id, index)}
                            size="small"
                          />
                          <Box>
                            <Box marginLeft={1}>
                              <Typography
                                color="#373737"
                                fontWeight={500}
                                fontSize={13}
                                className="table-link_text-prospect"
                              >
                                {domainName}
                              </Typography>
                              <Link
                                href={row.url}
                                target="_blank"
                                color="#373737"
                                fontSize={12.2}
                                fontWeight={350}
                                underline="hover"
                                className="table-link_text-prospect"
                              >
                                {row.url ?? ''}
                              </Link>
                            </Box>
                            <Box marginTop={1} marginLeft={1}>
                              <Typography
                                color="#373737"
                                fontWeight={600}
                                fontSize={14}
                                className="table-link_text-prospect"
                                marginBottom={0.8}
                              >
                                {row.title}
                              </Typography>
                              <Typography
                                color="#373737"
                                fontWeight={400}
                                fontSize={12}
                                className="table-link_description-prospect"
                              >
                                {defaultDescription.length > 200
                                  ? `${defaultDescription.slice(0, 200)}...`
                                  : defaultDescription}
                              </Typography>
                            </Box>
                          </Box>
                        </FlexBox>
                        <Tooltip title="Remove Assign">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() => {}}
                          >
                            <ClearIcon
                              sx={{
                                color: '#EB9393',
                                '&:hover': { color: '#EB9393' },
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </FlexBoxBetween>
                    </TableCell>
                    <TableCellCustom align="center">
                      <SelectFormBulk
                        required
                        options={owners}
                        disabled={
                          owners.length === 0 ||
                          cannotAssignUser.includes(UserContext.profile.role)
                        }
                        placeholder="Select assigned"
                        value={
                          owners.find(
                            item => item.id === prospectsProps[index].user,
                          ) ?? {
                            id: 0,
                            username: '',
                          }
                        }
                        onChange={(value: Owners | null) =>
                          handleUpdateProps(index, 'user', value?.id ?? 0)
                        }
                      />
                    </TableCellCustom>
                    <TableCellCustom>
                      <SelectFormProspecting
                        required
                        options={sites}
                        disabled
                        placeholder="Select site"
                        value={
                          sites.find(
                            item => item.id === prospectsProps[index].site,
                          ) ?? {
                            id: 0,
                            name: '',
                          }
                        }
                        onChange={(value: BasicArray | null) =>
                          handleUpdateProps(
                            index,
                            'site',
                            (value?.id as number) ?? 0,
                          )
                        }
                      />
                    </TableCellCustom>

                    <TableCellCustom>
                      <SelectFormProspecting
                        required
                        options={audiences[index]}
                        disabled={audiences[index].length === 0}
                        placeholder="Select audience"
                        value={
                          audiences[index].find(
                            item => item.id === prospectsProps[index].audience,
                          ) ?? {
                            id: 0,
                            name: '',
                          }
                        }
                        onChange={(value: BasicArray | null) =>
                          handleUpdateProps(
                            index,
                            'audience',
                            (value?.id as number) ?? 0,
                          )
                        }
                      />
                    </TableCellCustom>
                    <TableCellCustom>
                      <SelectFormProspecting
                        required
                        options={affinities[index]}
                        disabled={affinities[index].length === 0}
                        placeholder="Select affinity"
                        value={
                          affinities[index].find(
                            item => item.id === prospectsProps[index].affinity,
                          ) ?? {
                            id: 0,
                            name: '',
                          }
                        }
                        onChange={(value: BasicArray | null) =>
                          handleUpdateProps(
                            index,
                            'affinity',
                            (value?.id as number) ?? 0,
                          )
                        }
                      />
                    </TableCellCustom>
                    <TableCellCustom>
                      <SelectFormProspecting
                        required
                        options={templatesTransform}
                        disabled={templatesTransform.length === 0}
                        placeholder="Select template"
                        value={
                          templatesTransform.find(
                            item => item.id === prospectsProps[index].template,
                          ) ?? {
                            id: 0,
                            name: '',
                          }
                        }
                        onChange={(value: BasicArray | null) =>
                          handleUpdateProps(
                            index,
                            'template',
                            (value?.id as number) ?? 0,
                          )
                        }
                      />
                    </TableCellCustom>
                  </StyledTableRow>
                );
              })}
            </>
          )}
      </TableWrapper>
      <BulkProspect
        open={open}
        isLoading={isLoading}
        closeModal={setOpen}
        sites={sites}
        handleSubmitConfirm={handleSubmitConfirm}
        managers={owners}
        handleBulkUpdate={handleUpdate}
      />
      <SubmitProspectConfirm
        open={openConfirm}
        handleSubmit={handleSubmit}
        closeModal={setOpenConfirm}
        isLoading={isLoading}
        selected={selected.length}
      />
    </>
  );
};

export default ProspectingTable;
