import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import routesNames from '../../routes/customRoutes';
import LeftColumns from '../Columns/LeftColumns';

interface Props {
  option: number;
}

const SettingsOptions = ({ option }: Props) => {
  const navigate = useNavigate();

  return (
    <LeftColumns>
      <Typography fontWeight={900} fontSize={16} paddingX={3}>
        Settings
      </Typography>
      <List sx={{ marginTop: 1.3 }}>
        <ListItem disablePadding sx={{ paddingLeft: 2 }}>
          <ListItemButton
            sx={{
              borderRadius: '20px 0 0 20px',
              '&.Mui-selected': { backgroundColor: 'rgba(22, 18, 207, 0.08)' },
            }}
            selected={option === 1}
            onClick={() => navigate(routesNames.users)}
          >
            <Typography
              paddingY={0.3}
              sx={
                option === 1
                  ? { fontWeight: 700, color: '#1612CF' }
                  : { fontWeight: 400 }
              }
            >
              Users
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ paddingLeft: 2, marginTop: 0.5 }}>
          <ListItemButton
            sx={{
              borderRadius: '20px 0 0 20px',
              '&.Mui-selected': { backgroundColor: 'rgba(22, 18, 207, 0.08)' },
            }}
            selected={option === 2}
            onClick={() => navigate(routesNames.audienceaff)}
          >
            <Typography
              paddingY={0.3}
              sx={
                option === 2
                  ? { fontWeight: 700, color: '#1612CF' }
                  : { fontWeight: 400 }
              }
            >
              Audience & Affinity
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ paddingLeft: 2, marginTop: 0.5 }}>
          <ListItemButton
            sx={{
              borderRadius: '20px 0 0 20px',
              '&.Mui-selected': { backgroundColor: 'rgba(22, 18, 207, 0.08)' },
            }}
            selected={option === 3}
            onClick={() => navigate(routesNames.projectSites)}
          >
            <Typography
              paddingY={0.3}
              sx={
                option === 3
                  ? { fontWeight: 700, color: '#1612CF' }
                  : { fontWeight: 400 }
              }
            >
              Client & Sites
            </Typography>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding sx={{ paddingLeft: 2, marginTop: 0.5 }}>
          <ListItemButton
            sx={{
              borderRadius: '20px 0 0 20px',
              '&.Mui-selected': { backgroundColor: 'rgba(22, 18, 207, 0.08)' },
            }}
            selected={option === 4}
            onClick={() => navigate(routesNames.rules)}
          >
            <Typography
              paddingY={0.3}
              sx={
                option === 4
                  ? { fontWeight: 700, color: '#1612CF' }
                  : { fontWeight: 400 }
              }
            >
              Rules
            </Typography>
          </ListItemButton>
        </ListItem> */}
      </List>
    </LeftColumns>
  );
};

export default SettingsOptions;
