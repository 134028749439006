import {
  Box,
  Typography,
  IconButton,
  Divider,
  FormControlLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import {
  FlexBox,
  FlexBoxBetween,
  SwitchCustom,
} from '../../../../components/tableItems';
import SearchInput from '../../../../components/Search/SearchInput';
import { DefaultCheckbox, SelectBulk } from '../../../../components/inputs';
import Spinner from '../../../../components/Loading/Spinner';
// import LittleMenu from '../../../../components/Drawers/LittleMenu';
import AlphScroll from '../../../../components/AlphScroll/AlphScroll';
import FeedbackMessage from '../../../../components/FeedbackMessage';
import { SeverityType } from '../../../../models/General';
import { BulkButton } from '../../../../components/buttons';
import { UserContextType } from '../../../../models/User';
import Context from '../../../../context/UserContext';
import { sendBackObject } from '../../../../helpers/helpersfunctions';
import { ROLES_AUD_AFF } from '../../../../constants/user';
import LittleMenu from '../../../../components/Drawers/LittleMenu';
import { SitesRequestV2 } from '../../../../models/Sites';

interface Props {
  title: string;
  sites: SitesRequestV2[];
  options: { id: number; name: string; status: string }[];
  loading: boolean;
  loadInfo: (
    sort?: 'asc' | 'desc',
    status?: string,
    siteNumber?: number,
  ) => void;
  add: () => void;
  updateStatus: (
    obj: {
      status: 'active' | 'inactive';
    },
    id: number,
    option: string,
  ) => Promise<boolean>;
  feedbackOpen: boolean;
  setFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  feedbackMessage: string;
  severity: SeverityType;
  updateBulk: (
    obj: {
      ids: number[];
    },
    option: string,
  ) => Promise<boolean>;
  handleDelete: (title: string, id: number, dataType: string) => void;
  alphabet: [string, unknown][];
  current: string;
  changeQuery: (letter: string) => void;
  searchFunc: (query: string) => void;
  updateArr: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        name: string;
        status: string;
      }[]
    >
  >;
}

const ColumnInfo = ({
  title,
  options,
  loading,
  loadInfo,
  add,
  alphabet,
  updateStatus,
  feedbackOpen,
  setFeedbackOpen,
  feedbackMessage,
  handleDelete,
  updateBulk,
  severity,
  current,
  changeQuery,
  searchFunc,
  updateArr,
  sites,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [open, setOpen] = useState<number>(0);
  const [loadBulk, setLoadBulk] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [optionsSelected, setOptionsSelected] = useState<number[]>([]);
  // const [orderSort, setOrderSort] = useState<'asc' | 'desc'>('asc');
  const [statusSelect, setStatusSelect] = useState('all');
  const [siteSelected, setSiteSelected] = useState(0);

  const handleAllClick = () => {
    const ids = options.map(item => item.id);
    if (optionsSelected.length < options.length) {
      setOptionsSelected(ids);
      return;
    }
    setOptionsSelected([]);
  };

  const handleClick = (id: number) => {
    const copyArr = [...optionsSelected];
    const selectedIndex = copyArr.indexOf(id);

    if (selectedIndex === -1) copyArr.push(id);
    else copyArr.splice(selectedIndex, 1);

    setOptionsSelected(copyArr);
  };

  // const handleSort = () => {
  //   loadInfo(orderSort === 'asc' ? 'desc' : 'asc');
  //   setOrderSort(orderSort === 'asc' ? 'desc' : 'asc');
  // };

  const handleStatus = (value: string) => {
    loadInfo(undefined, value);
    setStatusSelect(value);
  };

  const handleSite = (value: number) => {
    loadInfo(undefined, undefined, value === 0 ? -10 : value);
    setSiteSelected(value);
  };

  const handleUpdate = async (
    status: 'active' | 'inactive',
    id: number,
    index: number,
  ) => {
    const arr = [...options];
    const copyOjb = arr[index];
    copyOjb.status = status;
    arr.splice(index, 1, copyOjb);
    const resp = await updateStatus({ status }, id, title);
    if (resp) updateArr(arr);
  };

  const handleBulkUpdate = async () => {
    setLoadBulk(true);
    const resp = await updateBulk({ ids: optionsSelected }, title);
    if (resp) {
      setOptionsSelected([]);
      loadInfo();
    }
    setLoadBulk(false);
  };

  const menuOptions = [
    {
      id: 0,
      title: 'Delete',
      click: (id: number, name?: string) => {
        handleDelete(name ?? '', id, title);
      },
      delete: true,
    },
  ];

  return (
    <>
      <Box className="width-fixed" pt={3}>
        <Box sx={{ px: 3 }}>
          <FlexBoxBetween>
            <Typography
              textTransform="uppercase"
              fontSize={14}
              color="rgba(0, 0, 0, 0.4)"
              fontWeight={700}
            >
              {title}
            </Typography>
            <IconButton
              disabled={
                !sendBackObject(UserContext.profile.role, ROLES_AUD_AFF).add
              }
              onClick={add}
            >
              <AddIcon />
            </IconButton>
          </FlexBoxBetween>
          <Box marginTop={2} pr={1}>
            <SearchInput
              filter={false}
              placeholder=""
              disabled={false}
              id={title}
              onChange={searchFunc}
              // loadingInfo={loadingSearch}
            />
          </Box>
        </Box>
        {optionsSelected.length > 0 &&
          sendBackObject(UserContext.profile.role, ROLES_AUD_AFF).edit && (
            <FlexBoxBetween pl={3} pr={2} mt={1}>
              <Typography fontSize={14} color="#787878">
                {optionsSelected.length} Selected
              </Typography>
              <BulkButton loading={loadBulk} onClick={handleBulkUpdate}>
                Bulk Update Status
              </BulkButton>
            </FlexBoxBetween>
          )}
        <Divider sx={{ marginTop: optionsSelected.length > 0 ? 0.8 : 5.7 }} />
      </Box>
      <Box marginTop={21.5} position="relative">
        {loading && <Spinner />}
        {!loading && (
          <>
            <FlexBoxBetween pl={3} pr={2}>
              <FlexBox>
                {sendBackObject(UserContext.profile.role, ROLES_AUD_AFF)
                  .edit && (
                  <DefaultCheckbox
                    sx={{ padding: 0 }}
                    disabled={false}
                    checked={
                      options
                        ? optionsSelected.length === options.length
                        : false
                    }
                    indeterminate={
                      options
                        ? optionsSelected.length > 0 &&
                          optionsSelected.length < options.length
                        : false
                    }
                    onClick={handleAllClick}
                  />
                )}
                <Typography
                  fontWeight={600}
                  fontSize={13}
                  textTransform="capitalize"
                  marginLeft={1.4}
                >
                  {title}
                </Typography>
                {/* <IconButton
                  disabled={false}
                  sx={{ marginLeft: 0.1 }}
                  className={orderSort === 'desc' ? 'sort_direction' : ''}
                  onClick={handleSort}
                >
                  <ArrowUpwardIcon fontSize="small" />
                </IconButton> */}
              </FlexBox>
              <FlexBox>
                <Select
                  value={siteSelected}
                  name="siteSelected"
                  id="siteSelected"
                  disabled={false}
                  displayEmpty
                  fullWidth
                  onChange={e => handleSite(e.target.value as number)}
                  sx={{ fontSize: 12, marginRight: 2 }}
                  IconComponent={KeyboardArrowDownIcon}
                  size="small"
                  input={<SelectBulk />}
                >
                  <MenuItem value={0} sx={{ fontSize: 12 }}>
                    All
                  </MenuItem>
                  {sites.map(item => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{ fontSize: 12 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={statusSelect}
                  name="bulk"
                  id="bulk"
                  disabled={false}
                  displayEmpty
                  fullWidth
                  onChange={e => handleStatus(e.target.value)}
                  sx={{ fontSize: 12 }}
                  IconComponent={KeyboardArrowDownIcon}
                  size="small"
                  input={<SelectBulk />}
                >
                  <MenuItem value="all" sx={{ fontSize: 12 }}>
                    All Status
                  </MenuItem>
                  <MenuItem value="active" sx={{ fontSize: 12 }}>
                    Active
                  </MenuItem>
                  <MenuItem value="inactive" sx={{ fontSize: 12 }}>
                    Inactive
                  </MenuItem>
                </Select>

                {/* <Typography
                  fontWeight={600}
                  fontSize={13}
                  textTransform="capitalize"
                  marginLeft={1.4}
                >
                  Status
                </Typography> */}
                {/* <IconButton
                  disabled={false}
                  sx={{ marginLeft: 0.1 }}
                  className={
                    orderSort.status === 'desc' ? 'sort_direction' : ''
                  }
                  onClick={() => handleSort('status')}
                >
                  <ArrowUpwardIcon fontSize="small" />
                </IconButton> */}
              </FlexBox>
            </FlexBoxBetween>
            <Box marginTop={2.9}>
              {options.length === 0 && (
                <Typography textAlign="center">
                  There are no items with that letter
                </Typography>
              )}
              {options.map((item, index) => (
                <div key={item.id} id={`${title}-${item.id.toString()}`}>
                  <Box
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      pl: 3,
                      pr: 2,
                      py: 3,
                    }}
                  >
                    <FlexBoxBetween>
                      <FlexBox>
                        {sendBackObject(UserContext.profile.role, ROLES_AUD_AFF)
                          .edit && (
                          <DefaultCheckbox
                            sx={{ padding: 0 }}
                            disabled={false}
                            checked={optionsSelected.indexOf(item.id) !== -1}
                            onClick={() => handleClick(item.id)}
                          />
                        )}
                        <Typography
                          fontSize={13}
                          textTransform="capitalize"
                          marginLeft={1.4}
                        >
                          {item.name}
                        </Typography>
                      </FlexBox>
                      <FlexBox>
                        {sendBackObject(UserContext.profile.role, ROLES_AUD_AFF)
                          .edit && (
                          <FormControlLabel
                            sx={{
                              '.MuiFormControlLabel-label': {
                                fontSize: 13,
                                marginLeft: 1.3,
                                textTransform: 'capitalize',
                              },
                            }}
                            control={
                              <SwitchCustom
                                checked={item.status === 'active'}
                                onChange={() =>
                                  handleUpdate(
                                    item.status === 'active'
                                      ? 'inactive'
                                      : 'active',
                                    item.id,
                                    index,
                                  )
                                }
                                size="small"
                              />
                            }
                            label={item.status}
                          />
                        )}
                        {!sendBackObject(
                          UserContext.profile.role,
                          ROLES_AUD_AFF,
                        ).edit && (
                          <Typography fontSize={13} textTransform="capitalize">
                            {item.status}
                          </Typography>
                        )}
                        {sendBackObject(UserContext.profile.role, ROLES_AUD_AFF)
                          .delete && (
                          <Box
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <LittleMenu
                              anchorEl={anchorEl}
                              setAnchorEl={setAnchorEl}
                              open={open}
                              name={item.name}
                              id={item.id}
                              setOpen={setOpen}
                              menuOptions={menuOptions}
                            />
                          </Box>
                        )}
                      </FlexBox>
                    </FlexBoxBetween>
                  </Box>
                </div>
              ))}
              <AlphScroll
                arr={alphabet}
                current={current}
                changeQuery={changeQuery}
              />
            </Box>
          </>
        )}
      </Box>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal={title === 'Affinity' ? 'right' : 'center'}
        severity={severity}
        message={feedbackMessage}
      />
    </>
  );
};

export default ColumnInfo;
