import React, { useContext, useState } from 'react';
import { MainButton } from '../../../buttons';
import { ContactsFormLink } from '../../../../models/Forms';
import { UserContextType } from '../../../../models/User';
import Context from '../../../../context/UserContext';
import CloseContactModal from './CloseContactModal';
import CloseLinkModal from './CloseLinkModal';
import { ContactsValuesType } from '../../../../helpers/contacts';

interface StatusClosedInterface {
  site: number;
  disabled: boolean;
  disabledSave: boolean;
  contacts: ContactsFormLink[];
  allContacts: ContactsFormLink[];
  contactsValidations: ContactsValuesType[];
  updateNewContact: (newContact: ContactsFormLink) => void;
  updateLinkClosed: (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => void;
  updateLinkClosedAndSave: (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => void;
  updateContactsClosed: (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => void;
  updateContactsClosedAndSave: (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => void;
}

interface LinkChangesProps {
  closeType: string;
  closeReason: string;
  relevance: boolean;
}

interface ContactChangesProps {
  contactIds: number[];
  contactNamesEmails: string[];
  closeType: string;
  closeReason: string;
}

const StatusClosed = ({
  disabled,
  site,
  contacts,
  updateLinkClosed,
  updateLinkClosedAndSave,
  allContacts,
  contactsValidations,
  updateContactsClosed,
  updateContactsClosedAndSave,
  disabledSave,
  updateNewContact,
}: StatusClosedInterface) => {
  const { UserContext } = useContext(Context) as UserContextType;

  // Check if the person already fill one modal to ask them if they want to fill the other one
  const [finishedLink, setFinishedLink] = useState(false);
  const [finishedContact, setFinishedContact] = useState(false);

  // Modals Open
  const [selectOptionModal, setSelectOptionModal] = useState(false);
  const [linkOpenModal, setLinkOpenModal] = useState(false);
  const [contactOpenModal, setContactOpenModal] = useState(false);

  const [linkChanges, setLinkChanges] = useState<LinkChangesProps>();
  const [contactChanges, setContactChanges] = useState<ContactChangesProps>();

  const [note, setNote] = useState('');

  const openSpecificModal = () => {
    if (
      !finishedLink &&
      (contacts.filter(item => item.email !== '' || item.is_form).length ===
        0 ||
        finishedContact)
    )
      setLinkOpenModal(true);
    else setContactOpenModal(true);
  };

  const closeModalLink = () => setLinkOpenModal(false);
  const closeModalContact = () => setContactOpenModal(false);

  const closeConfirmLink = (linkChangesConfirm: LinkChangesProps) => {
    setLinkOpenModal(false);
    setLinkChanges(linkChangesConfirm);
    setFinishedLink(true);
  };

  const closeLinkOpenContact = (linkChangesConfirm: LinkChangesProps) => {
    setLinkOpenModal(false);
    setLinkChanges(linkChangesConfirm);
    setFinishedLink(true);
    setContactOpenModal(true);
  };

  const closeConfirmContact = (contactsChangesConfirm: ContactChangesProps) => {
    setContactOpenModal(false);
    setContactChanges(contactsChangesConfirm);
    setFinishedContact(true);
    if (
      !finishedLink &&
      contactsChangesConfirm.contactIds.length === contacts.length &&
      contactsChangesConfirm.closeType === 'invalid_contact'
    )
      setFinishedLink(true);
  };

  const closeContactOpenLink = (
    contactsChangesConfirm: ContactChangesProps,
  ) => {
    setContactOpenModal(false);
    setContactChanges(contactsChangesConfirm);
    setFinishedContact(true);
    setLinkOpenModal(true);
  };

  const goBackToOptions = () => {
    setLinkOpenModal(false);
    setContactOpenModal(false);
    setSelectOptionModal(true);
  };

  const updateNote = (noteChange: string) => setNote(noteChange);

  return (
    <>
      <MainButton
        loading={false}
        variant="contained"
        sx={{
          backgroundColor: '#EB9393',
          color: 'black',
          '&:hover': {
            backgroundColor: '#EB9393',
          },
        }}
        disabled={
          disabled ||
          (finishedLink && finishedContact) ||
          (finishedLink &&
            contacts.filter(item => item.email !== '' || item.is_form)
              .length === 0)
        }
        onClick={openSpecificModal}
      >
        Close
      </MainButton>
      {/* 
      <OptionModal
        open={selectOptionModal}
        closeModal={closeOptionModal}
        optionModalChoice={optionModalChoice}
      /> */}

      <CloseLinkModal
        open={linkOpenModal}
        contactChanges={contactChanges}
        updateLinkClosedAndSave={updateLinkClosedAndSave}
        closeModal={closeModalLink}
        updateLinkClosed={updateLinkClosed}
        goBackToOptions={goBackToOptions}
        closeLinkOpenContact={closeLinkOpenContact}
        closeConfirmLink={closeConfirmLink}
        disabledSave={disabledSave}
        note={note}
        updateNote={updateNote}
        disableContactsModal={
          contacts.length === 0 ||
          contacts.filter(item => item.email !== '').length === 0 ||
          finishedContact
        }
        userRole={UserContext.profile.role}
      />

      <CloseContactModal
        note={note}
        allContacts={allContacts}
        contactsValidations={contactsValidations}
        updateNewContact={updateNewContact}
        site={site}
        updateContactsClosedAndSave={updateContactsClosedAndSave}
        disabledSave={disabledSave}
        updateNote={updateNote}
        open={contactOpenModal}
        contacts={contacts.map((item, index) => {
          const formContactsNames = contacts
            .filter(
              contactForm => contactForm.is_form && contactForm.name === '',
            )
            .map((thisContact, indexContact) => {
              return { ...thisContact, nameIndex: indexContact + 1 };
            });

          const hasName =
            item.name !== ''
              ? item.name
              : `Unnamed Contact #${
                  formContactsNames.find(
                    itemContact => itemContact.id === item.id,
                  )?.nameIndex
                }`;
          return {
            id: index,
            contactId: item.id ?? 0,
            name: item.is_form ? hasName : item.email,
            status: item.status ?? '',
            is_form: item.is_form,
          };
        })}
        closeModal={closeModalContact}
        linkChanges={linkChanges}
        goBackToOptions={goBackToOptions}
        updateContactsClosed={updateContactsClosed}
        closeConfirmContact={closeConfirmContact}
        closeContactOpenLink={closeContactOpenLink}
        disabledLinksModal={finishedLink}
      />
    </>
  );
};

export default StatusClosed;
