import React, { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';
import { SeverityType } from '../../models/General';
import { TextFormField } from '../Forms/FormInputs';
import { updateUserInfo } from '../../helpers/user';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface BulkProspectProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  showMessage: (message: string, severity: SeverityType) => void;
  is_active: boolean;
  id: number;
  reloadData: () => void;
}

const UserStatusChange = ({
  open,
  closeModal,
  showMessage,
  reloadData,
  id,
  is_active,
}: BulkProspectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState('');

  const updateUser = async () => {
    setIsLoading(true);
    const obj = { is_active, text_note: note };
    try {
      await updateUserInfo(id, obj);
      showMessage('User updated', 'success');
      setNote('');
      closeModal(false);
      reloadData();
    } catch (err) {
      showMessage('Error updating user', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            {is_active ? 'Activate' : 'Deactivate'} User
          </Typography>
          <Typography marginTop={2} fontSize={14} textAlign="center">
            In order to {is_active ? 'Activate' : 'Deactivate'} this user you
            need to write a note first
          </Typography>
          <Box marginTop={3.5}>
            <TextFormField
              disabled={false}
              nameInput="text_note"
              label="Note"
              error={false}
              multiline
              required
              value={note}
              errorText=""
              placeholder="Note"
              onChange={(value: string) => setNote(value)}
            />
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={updateUser}
                disabled={note === ''}
                loading={isLoading}
                sx={{ marginLeft: 1 }}
              >
                Confirm
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default UserStatusChange;
