import React from 'react';
import { Box, Toolbar } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const LeftColumns = ({ children }: Props) => {
  return (
    <Box sx={{ borderRight: 1, borderColor: '#D9D9D9', height: '100vh' }}>
      <Toolbar />
      <Box sx={{ pt: 5 }}>{children}</Box>
    </Box>
  );
};

export default LeftColumns;
