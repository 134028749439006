/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import React from 'react';
import { CLOSE_TYPE_OPTIONS, OPTIONS_SELECT } from './ConstantsClosedStatus';
import { FlexBox } from '../../../tableItems';
import { BasicSelect } from '../../FormInputs';

interface ButtonGroupSelectProps {
  value: string;
  changeValue: (value: string) => void;
  reasonValue: string;
  type: 'link' | 'contact';
  changeReasonValue: (value: string) => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const tooltipInformationType = (typeClose: string) => {
  return (
    <>
      <Box>
        {typeClose === CLOSE_TYPE_OPTIONS[0].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Site Close: No one from this site should reach out to this website
              again, but other sites have the ability to.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `Your content is out of date.`
            </Typography>
          </>
        )}{' '}
        {typeClose === CLOSE_TYPE_OPTIONS[1].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Project Close: No one from this project should reach out to this
              website again, but other projects have the ability to.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              *i.e If a contact is marked as a Project Close on ConsumerNotice,
              no one from any of the Mass Torts projects will be able to reach
              out to them.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `We can only share information from government
              agencies or non-profits.`
            </Typography>
          </>
        )}
        {typeClose === CLOSE_TYPE_OPTIONS[2].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Global Close: No one from Launch That Linkbuilding should reach
              out again to this website.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `If you email us again, we will report you for
              harassment and pursue legal action.`
            </Typography>
          </>
        )}
        {typeClose === CLOSE_TYPE_OPTIONS[3].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Invalid Contact: The contacts for this entry are not valid.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `An “Email Bounced” error message.“XYZ no longer
              works at this company.”“I am not responsible for this, XYZ is the
              director of this program.”`
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const tooltipInformationTypeContact = (typeClose: string) => {
  return (
    <>
      <Box>
        {typeClose === CLOSE_TYPE_OPTIONS[0].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Site Close: No one from this site should reach out to this contact
              again, but other sites have the ability to.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `Your content is out of date.`
            </Typography>
          </>
        )}{' '}
        {typeClose === CLOSE_TYPE_OPTIONS[1].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Project Close: No one from this project should reach out to this
              contact again, but other projects have the ability to.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              *i.e If a contact is marked as a Project Close on ConsumerNotice,
              no one from any of the Mass Torts projects will be able to reach
              out to them.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `We can only share information from government
              agencies or non-profits.`
            </Typography>
          </>
        )}
        {typeClose === CLOSE_TYPE_OPTIONS[2].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Global Close: No one from Launch That Linkbuilding should reach
              out again.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `If you email us again, we will report you for
              harassment and pursue legal action.`
            </Typography>
          </>
        )}
        {typeClose === CLOSE_TYPE_OPTIONS[3].value && (
          <>
            <Typography fontSize={13} fontWeight={500}>
              Invalid Contact: The contacts for this entry are not valid.
            </Typography>
            <Typography fontSize={13} fontWeight={400}>
              Examples include: `An “Email Bounced” error message.“XYZ no longer
              works at this company.”“I am not responsible for this, XYZ is the
              director of this program.”`
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const ButtonGroupSelect = ({
  value,
  changeValue,
  reasonValue,
  changeReasonValue,
  type,
}: ButtonGroupSelectProps) => {
  const returnBackgroundColor = (option: string) =>
    option === value ? 'black' : '';

  const returnTextColor = (option: string) =>
    option === value ? 'white' : 'black';

  const returnHoverColor = (option: string) =>
    option === value ? 'black' : '#F2F2F2';

  const tooltipInformation = () => {
    return (
      <>
        <Box>
          <Typography fontSize={13} fontWeight={500}>
            Site: Relevance
          </Typography>
          <Typography fontSize={13} fontWeight={500}>
            Project: Not a Fit
          </Typography>
          <Typography fontSize={13} fontWeight={500}>
            Global: Do not Contact
          </Typography>

          <Typography fontSize={13} fontWeight={500}>
            Invalid Contact: Contact/Email is not good
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box>
      <FlexBox marginTop={2} textAlign="center" justifyContent="center">
        <Typography fontSize={15}>Select a Close Type</Typography>
        <HtmlTooltip title={tooltipInformation()}>
          <IconButton sx={{ fontSize: 16 }}>
            <HelpRoundedIcon fontSize="inherit" />
          </IconButton>
        </HtmlTooltip>
      </FlexBox>

      <Box marginTop={2} display="flex" justifyContent="center">
        <ButtonGroup>
          {CLOSE_TYPE_OPTIONS.map((item, index) => (
            <>
              <Button
                key={item.value}
                variant="outlined"
                sx={{
                  borderColor: 'black',
                  fontSize: 11,
                  backgroundColor: returnBackgroundColor(
                    CLOSE_TYPE_OPTIONS[index].value,
                  ),
                  color: returnTextColor(CLOSE_TYPE_OPTIONS[index].value),
                  '&:hover': {
                    backgroundColor: returnHoverColor(
                      CLOSE_TYPE_OPTIONS[index].value,
                    ),
                    borderColor: 'black',
                  },
                }}
                onClick={() => changeValue(CLOSE_TYPE_OPTIONS[index].value)}
              >
                {CLOSE_TYPE_OPTIONS[index].label}
                <HtmlTooltip
                  title={
                    type === 'link'
                      ? tooltipInformationType(CLOSE_TYPE_OPTIONS[index].value)
                      : tooltipInformationTypeContact(
                          CLOSE_TYPE_OPTIONS[index].value,
                        )
                  }
                >
                  <IconButton
                    sx={{
                      fontSize: 15,
                      color: returnTextColor(CLOSE_TYPE_OPTIONS[index].value),
                    }}
                  >
                    <HelpRoundedIcon fontSize="inherit" />
                  </IconButton>
                </HtmlTooltip>
              </Button>
            </>
          ))}
        </ButtonGroup>
      </Box>

      <Box marginTop={3} px={4}>
        <Typography fontSize={15} textAlign="center">
          Select a Closing Reason
        </Typography>
        <Box marginTop={2}>
          <BasicSelect
            required
            nameInput="option"
            disabled={value === ''}
            value={reasonValue}
            options={OPTIONS_SELECT(value)}
            onChange={(optionValue: string) => changeReasonValue(optionValue)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ButtonGroupSelect;
