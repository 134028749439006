import { Box, Toolbar, Typography } from '@mui/material';
import React from 'react';
import notFound from '../../assets/logos/404.svg';

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '90vh',
      }}
    >
      <Toolbar />
      <img src={notFound} alt="Not Found" width={550} />
      <Typography fontSize={20} fontWeight={600} marginTop={3}>
        Page not found
      </Typography>
    </Box>
  );
};

export default NotFound;
