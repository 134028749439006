const colorsStatus = {
  grey: { bgColor: '#DBDBDB', fontColor: 'rgb(120, 120, 120)' },
  purple: { bgColor: '#DEC5F9', fontColor: '#8534DD' },
  blue: { bgColor: '#BAE7F9', fontColor: '#449CC1' },
  green: { bgColor: '#D5FDA2', fontColor: '#5FAB00' },
  orange: { bgColor: '#FFEDB8', fontColor: '#DBA500' },
  red: { bgColor: '#FFD4D4', fontColor: '#CB3E3E' },
};

const greyColor = ['to_do'];
const purpleColor = ['pending'];
const blueColor = ['open', 'hold'];
const greenColor = ['approved', 'active'];
const orangeColor = [
  'text_link',
  'email_bounced',
  'contact_not_found',
  'link_removed',
  'not_found',
  'curl_error',
  'invalid_contact',
];
const redColor = ['project_closed', 'site_closed', 'global_closed', 'deleted'];

const returnColorsStatus = (status: string) => {
  if (greyColor.includes(status)) return colorsStatus.grey;
  if (purpleColor.includes(status)) return colorsStatus.purple;
  if (blueColor.includes(status)) return colorsStatus.blue;
  if (greenColor.includes(status)) return colorsStatus.green;
  if (orangeColor.includes(status)) return colorsStatus.orange;
  if (redColor.includes(status)) return colorsStatus.red;
  return colorsStatus.grey;
};

export default returnColorsStatus;
