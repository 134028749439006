import React, { useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Select,
  MenuItem,
  Modal,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeadCell } from '../../../models/Table';
import { FlexBox, FlexBoxBetween } from '../../tableItems';
import { DefaultCheckbox, SelectBulk } from '../../inputs';
import { MainButton } from '../../buttons';

interface TableHeadProps {
  onRequestSort: (property: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  columnsVisibility: any;
  headCells: HeadCell[];
  checkbox?: { checked: boolean; indeterminate: boolean; click: () => void };
  bulk?: { disabled: boolean };
  handleBulk?: (option: 'active' | 'inactive') => Promise<void>;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 3,
  pb: 2,
};

const TableHeadComp = ({
  onRequestSort,
  order,
  orderBy,
  columnsVisibility,
  headCells,
  checkbox,
  handleBulk,
  bulk,
}: TableHeadProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [bulkValue, setbulkValue] = useState<'active' | 'inactive'>('active');

  const getKeyValue = (key: string) => (obj: Record<any, any>) => obj[key];

  const getClassName = (name: string): string =>
    getKeyValue(name)(columnsVisibility) ? 'present-column' : 'hide-column';

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sx={{
                minWidth: 140,
                color: '#787878',
                fontSize: 13,
              }}
              align="left"
              className={
                headCell.main ? 'first_column' : getClassName(headCell.id)
              }
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.main ? (
                <>
                  {checkbox && (
                    <FlexBoxBetween>
                      <FlexBox>
                        <DefaultCheckbox
                          sx={{ marginLeft: 1 }}
                          checked={checkbox.checked}
                          indeterminate={checkbox.indeterminate}
                          onClick={() => checkbox.click()}
                        />
                        <TableSortLabel
                          sx={{ paddingLeft: 1 }}
                          active={orderBy === headCell.id}
                          disabled={headCell.disableSort}
                          direction={order}
                          onClick={() => {
                            onRequestSort(headCell.id);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </FlexBox>
                      {bulk && (
                        <Box paddingRight={2}>
                          <Select
                            value=""
                            name="bulk"
                            id="bulk"
                            disabled={bulk?.disabled}
                            displayEmpty
                            fullWidth
                            onChange={e => {
                              setbulkValue(
                                e.target.value as 'active' | 'inactive',
                              );
                              setOpenModal(true);
                            }}
                            sx={{ fontSize: 12 }}
                            IconComponent={KeyboardArrowDownIcon}
                            size="small"
                            input={<SelectBulk />}
                          >
                            <MenuItem value="" disabled sx={{ fontSize: 12 }}>
                              Bulk Status Update
                            </MenuItem>
                            <MenuItem value="active" sx={{ fontSize: 12 }}>
                              Active
                            </MenuItem>
                            <MenuItem value="inactive" sx={{ fontSize: 12 }}>
                              Inactive
                            </MenuItem>
                          </Select>
                        </Box>
                      )}
                    </FlexBoxBetween>
                  )}
                  {!checkbox && (
                    <Box>
                      <TableSortLabel
                        sx={{ paddingLeft: 1.5 }}
                        active={orderBy === headCell.id}
                        direction={order}
                        onClick={() => {
                          onRequestSort(headCell.id);
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </Box>
                  )}
                </>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  disabled={headCell.disableSort}
                  direction={order}
                  onClick={() => {
                    onRequestSort(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <Modal open={openModal}>
        <Box sx={style}>
          <FlexBoxBetween>
            <Typography fontWeight={700} fontSize={17}>
              Bulk Status Update
            </Typography>
            <IconButton onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </IconButton>
          </FlexBoxBetween>
          <Typography color="#373737" fontSize={14}>
            Are you sure you want to update this templates in bulk?
          </Typography>
          <Box marginTop={3}>
            <FlexBox marginTop={3} justifyContent="end">
              <MainButton
                onClick={() => setOpenModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                sx={{ marginX: 1.5 }}
                disabled={false}
                onClick={() => {
                  if (handleBulk) handleBulk(bulkValue);
                  setOpenModal(false);
                }}
              >
                Okay
              </MainButton>
            </FlexBox>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TableHeadComp;
