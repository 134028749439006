/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Box, IconButton, Link, TableCell, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FlexBox, FlexBoxBetween } from '../../tableItems';
import { MenuOptionsDropdown } from '../../../models/General';
import LittleMenu from '../../Drawers/LittleMenu';
import { DefaultCheckbox } from '../../inputs';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { ROLES_TEMPLATES } from '../../../constants/user';

interface Props {
  labelId: string;
  type: 'link' | 'template';
  changeEditIcon?: boolean;
  title: string;
  index?: number;
  body?: string;
  id: number;
  menuOptions: MenuOptionsDropdown[];
  buttons?: 'builder' | 'strategist';
  review?: boolean;
  notes?: number;
  disabledClone?: boolean;
  checkbox?: { checked: boolean; onClick: (id: number) => void };
  handle?: (
    id: number,
    option: 'view' | 'clone' | 'edit',
    tabNotes?: number,
  ) => void;
  handleEdit?: (id: number, tab?: number, openNewContact?: boolean) => void;
  colorButtonIcon?: string;
}

const TableCellMain = ({
  labelId,
  type,
  title,
  id,
  menuOptions,
  body,
  buttons,
  notes,
  review,
  checkbox,
  disabledClone,
  handle,
  handleEdit,
  index,
  changeEditIcon,
  colorButtonIcon,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [open, setOpen] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <TableCell
      className="first_column"
      component="th"
      sx={index ? { backgroundColor: index % 2 === 0 ? '#eeeeee' : '' } : {}}
      id={labelId}
      scope="row"
      padding="none"
    >
      <FlexBoxBetween
        sx={{
          color: '#373737',
          paddingTop: 2.3,
          paddingBottom: 2.3,
          paddingRight: 1,
          paddingLeft: 1,
        }}
      >
        <FlexBox>
          {type === 'link' && (
            <Link
              href={title}
              target="_blank"
              color="#373737"
              underline="hover"
              className="table-link_text"
            >
              {title}
            </Link>
          )}
          {type === 'template' && (
            <>
              {checkbox && (
                <DefaultCheckbox
                  checked={checkbox?.checked}
                  onClick={() => checkbox.onClick(id)}
                />
              )}
              <Box marginLeft={1}>
                <Typography
                  color="#373737"
                  fontWeight={600}
                  className="table-link_text"
                >
                  {title}
                </Typography>
                <Typography
                  color="#373737"
                  marginTop={0.7}
                  sx={{ fontSize: '12px !important' }}
                  className="table-link_text"
                >
                  {body}
                </Typography>
              </Box>
            </>
          )}
        </FlexBox>
        <FlexBox sx={{ marginLeft: 2 }}>
          {buttons === 'builder' && review && (
            <>
              <FlexBox sx={{ flexGrow: 1 }}>
                <Tooltip title="Notes">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => (handle ? handle(id, 'edit', 1) : {})}
                  >
                    <ChatOutlinedIcon
                      sx={{
                        color: '#84D0F0',
                        '&:hover': { color: '#84D0F0' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Typography
                  fontSize={14}
                  marginRight={1}
                  color="rgba(0, 0, 0, 0.5)"
                >
                  {notes}
                </Typography>
              </FlexBox>
              <Box sx={{ flexGrow: 1 }}>
                <Tooltip title="View Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => (handle ? handle(id, 'view') : {})}
                  >
                    <DescriptionOutlinedIcon
                      sx={{
                        color: 'purple',
                        '&:hover': { color: 'purple' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          {buttons === 'builder' && !review && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Tooltip title="View Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => (handle ? handle(id, 'view') : {})}
                  >
                    <DescriptionOutlinedIcon
                      sx={{
                        color: 'purple',
                        '&:hover': { color: 'purple' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              {sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                .add && (
                <Box sx={{ flexGrow: 1 }}>
                  <Tooltip title="Clone Template">
                    <IconButton
                      disabled={disabledClone}
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => (handle ? handle(id, 'clone') : {})}
                    >
                      <ContentCopyIcon
                        sx={{
                          color: '#84D0F0',
                          '&:hover': { color: '#84D0F0' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <Tooltip title="Edit Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => (handle ? handle(id, 'edit') : {})}
                  >
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: '#7F7F7F',
                        '&:hover': { color: '#7F7F7F' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          {buttons === 'strategist' && (
            <>
              {review && (
                <FlexBox sx={{ flexGrow: 1 }}>
                  <Tooltip title="Notes">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => (handle ? handle(id, 'edit', 1) : {})}
                    >
                      <ChatOutlinedIcon
                        sx={{
                          color: '#84D0F0',
                          '&:hover': { color: '#84D0F0' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    fontSize={14}
                    marginRight={1}
                    color="rgba(0, 0, 0, 0.5)"
                  >
                    {notes}
                  </Typography>
                </FlexBox>
              )}
              {!review &&
                sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                  .add && (
                  <Box sx={{ flexGrow: 1 }}>
                    <Tooltip title="Clone Template">
                      <IconButton
                        disabled={disabledClone}
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        onClick={() => (handle ? handle(id, 'clone') : {})}
                      >
                        <ContentCopyIcon
                          sx={{
                            color: '#84D0F0',
                            '&:hover': { color: '#84D0F0' },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              <Box sx={{ flexGrow: 1, position: 'relative' }}>
                <Tooltip title="Approve Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => menuOptions[0].click(id)}
                  >
                    <CheckIcon
                      sx={{
                        color: '#6EBD0C',
                        '&:hover': { color: '#6EBD0C' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  position: 'relative',
                }}
              >
                <Tooltip title="Reject Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => menuOptions[1].click(id)}
                  >
                    <ClearIcon
                      sx={{
                        color: '#EB9393',
                        '&:hover': { color: '#EB9393' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Tooltip title="Edit Template">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => (handle ? handle(id, 'edit') : {})}
                  >
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: '#7F7F7F',
                        '&:hover': { color: '#7F7F7F' },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          {!buttons && (
            <>
              {type === 'link' && notes !== undefined && (
                <FlexBox sx={{ flexGrow: 1 }}>
                  <Tooltip title="Notes">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      sx={{
                        '&:hover': {
                          backgroundColor:
                            colorButtonIcon === '#e7e7e7'
                              ? '#dddddd'
                              : colorButtonIcon === '#BAF372'
                              ? '#afe56a'
                              : '',
                        },
                        backgroundColor: colorButtonIcon,
                      }}
                      onClick={() => {
                        if (handleEdit) handleEdit(id, 1);
                      }}
                    >
                      <ChatOutlinedIcon
                        sx={{
                          color: '#84D0F0',
                          '&:hover': { color: '#84D0F0' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    fontSize={12}
                    marginRight={1}
                    color="rgba(0, 0, 0, 0.5)"
                  >
                    {notes}
                  </Typography>
                </FlexBox>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <Tooltip title="Edit Link">
                  {changeEditIcon ? (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => {
                        if (handleEdit) handleEdit(id, undefined, true);
                      }}
                    >
                      <PersonIcon
                        sx={{
                          color: '#7F7F7F',
                          '&:hover': { color: '#7F7F7F' },
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => {
                        if (handleEdit) handleEdit(id);
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon
                        sx={{
                          color: '#7F7F7F',
                          '&:hover': { color: '#7F7F7F' },
                        }}
                      />
                    </IconButton>
                  )}
                </Tooltip>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  position: 'relative',
                }}
              >
                <LittleMenu
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  colorButtonIcon={colorButtonIcon}
                  open={open}
                  id={id}
                  setOpen={setOpen}
                  menuOptions={menuOptions}
                />
              </Box>
            </>
          )}
        </FlexBox>
      </FlexBoxBetween>
    </TableCell>
  );
};

export default TableCellMain;
