import { create } from 'zustand';
import { FiltersInterface, filtersObjWithDate } from '../../models/Filters';

interface FiltersLinksStore {
  filterValuesDefault: FiltersInterface;
  filterValuesChange: FiltersInterface;
  updateFiltersChange: (obj: FiltersInterface) => void;
  updateFiltersDefault: (obj: FiltersInterface) => void;
}

const useFiltersLinks = create<FiltersLinksStore>(set => ({
  filterValuesDefault: filtersObjWithDate(),
  filterValuesChange: filtersObjWithDate(),
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersLinks;
