import {
  Box,
  Modal,
  Typography,
  TableCell,
  TableRow,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import SearchInputModal from '../User/ProjectsSites/SearchInputModal';
import { UserResponse } from '../../../models/User';
import { FlexBox } from '../../tableItems';
import { DefaultCheckbox } from '../../inputs';
import { getAllUsers } from '../../../helpers/user';
import Spinner from '../../Loading/Spinner';
import UsersSiteTable from './UsersSiteTable';
import { UsersAssignedSelected } from '../../../models/Forms';
import DateInputPS from '../User/ProjectsSites/DateInputPS';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  borderRadius: '2px',
  boxShadow: 24,
  overflowY: 'auto',
  pb: 5,
};

interface UsersSelectionProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateUsers: (usersArray: UsersAssignedSelected[]) => void;
  selectedUsersForm: UsersAssignedSelected[];
  usersDefaultArray: UserResponse[];
  usersDefaultCount: number;
  usersDefaultLoading: boolean;
}

const UsersAssignedSelection = ({
  open,
  closeModal,
  updateUsers,
  selectedUsersForm,
  usersDefaultArray,
  usersDefaultCount,
  usersDefaultLoading,
}: UsersSelectionProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersArr, setUsersArr] = useState<UserResponse[]>([]);
  const [selected, setSelected] =
    useState<UsersAssignedSelected[]>(selectedUsersForm);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  const fetchUsers = async (
    pageNumber?: number,
    pageSizePagination?: number,
    searchValue?: string,
  ) => {
    setIsLoading(true);
    try {
      const users = await getAllUsers(
        pageNumber ?? page + 1,
        pageSizePagination ?? pageSize,
        searchValue ?? search,
        'username',
      );
      setUsersArr(users.results);
      setCount(users.count);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelected(selectedUsersForm);
  }, [selectedUsersForm]);

  useEffect(() => {
    setUsersArr(usersDefaultArray);
  }, [usersDefaultArray]);

  useEffect(() => {
    setCount(usersDefaultCount);
  }, [usersDefaultCount]);

  useEffect(() => {
    setIsLoading(usersDefaultLoading);
  }, [usersDefaultLoading]);

  useEffect(() => {
    if (!open && search !== '') {
      setSearch('');
      setPage(0);
      fetchUsers(1, pageSize, '');
    }
  }, [open]);

  const handleChecked = (
    id: number,
    first_name: string,
    last_name: string,
    username: string,
    date: string,
  ) => {
    const copySelected = [...selected];
    const index = copySelected.findIndex(user => user.id === id);
    if (index !== -1) {
      copySelected.splice(index, 1);
    } else {
      copySelected.push({
        id,
        username,
        first_name,
        last_name,
        date_effective: date,
      });
    }
    setSelected(copySelected);
  };

  const isSelected = (id: number) =>
    selected.findIndex(user => user.id === id) !== -1;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    fetchUsers(1, parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchUsers(newPage + 1);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPage(0);
    fetchUsers(1, pageSize, value);
  };

  const getUserDate = (id: number) => {
    const userDate = selected.find(user => user.id === id);
    return userDate ? userDate.date_effective : dayjs().format('MM/DD/YYYY');
  };

  const handleChangeOfValue = (value: string, id?: number) => {
    const copySelected = [...selected];
    const indexSelected = copySelected.findIndex(user => user.id === id);
    if (indexSelected !== -1) {
      copySelected[indexSelected].date_effective = value;
      setSelected(copySelected);
    }
  };

  return (
    <Modal open={open}>
      <Box sx={style} className="site_access-responsive">
        <HeaderDrawer
          title="Users Assigned"
          threeButtons={false}
          saveFunction={() => {
            updateUsers(selected);
            closeModal(false);
          }}
          closeDrawer={closeModal}
          loading={false}
          disabled={false}
        />
        <Box paddingX={2.5} paddingY={2.4}>
          <Typography fontSize={12} marginBottom={1.3}>
            To selecte a user, search below
          </Typography>
          <Box position="relative">
            <SearchInputModal
              onChangeFunc={(value: string) => handleSearch(value)}
              onBlurFunc={() => {}}
              onFocusFunc={() => {}}
            />
          </Box>
          <Box marginTop={3}>
            <UsersSiteTable columnsNames={{ first: 'Name', second: 'Date' }}>
              {isLoading && <Spinner />}
              {!isLoading && usersArr.length === 0 && (
                <Typography textAlign="center" marginTop={2} fontWeight={600}>
                  No results found
                </Typography>
              )}
              {!isLoading && usersArr.length > 0 && (
                <>
                  {usersArr.map(user => {
                    return (
                      <TableRow
                        key={user.id}
                        hover
                        role="checkbox"
                        selected={isSelected(user.id)}
                      >
                        <TableCell component="th" scope="row">
                          <FlexBox>
                            <DefaultCheckbox
                              size="small"
                              sx={{ p: 0, marginRight: 1 }}
                              disabled={false}
                              onClick={() =>
                                handleChecked(
                                  user.id,
                                  user.first_name,
                                  user.last_name,
                                  user.username,
                                  getUserDate(user.id),
                                )
                              }
                              checked={isSelected(user.id)}
                            />
                            <Typography fontSize={14}>
                              {user.first_name} {user.last_name}
                            </Typography>
                          </FlexBox>
                        </TableCell>
                        <TableCell align="right">
                          <DateInputPS
                            disabled={!isSelected(user.id)}
                            site={{
                              id: user.id,
                              date: getUserDate(user.id),
                            }}
                            handleChangeDate={handleChangeOfValue}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </UsersSiteTable>
            {!isLoading && usersArr.length > 0 && (
              <TablePagination
                component="div"
                count={count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UsersAssignedSelection;
