import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

interface Props {
  children: React.ReactNode;
  newAvatar: File | null;
  avatar: string;
  noAvatarName: string;
  disabled?: boolean;
  changePhoto: (value: File | null) => void;
}

const GridFormPhoto = ({
  children,
  newAvatar,
  avatar,
  noAvatarName,
  disabled,
  changePhoto,
}: Props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: 1,
        borderColor: '#D9D9D9',
        paddingTop: 2.3,
        paddingBottom: 2.5,
      }}
    >
      <Grid item xs={3}>
        <Box position="relative">
          {(newAvatar || avatar) && (
            <Box>
              <Avatar
                alt="Avatar of the user"
                src={newAvatar ? `${URL.createObjectURL(newAvatar)}` : avatar}
                sx={{
                  width: 110,
                  height: 110,
                  objectFit: 'cover',
                }}
              />
            </Box>
          )}
          {!newAvatar && !avatar && (
            <Box
              sx={{
                backgroundColor: '#D9D9D9',
                paddingY: 4,
                borderRadius: 100,
                width: 107,
              }}
            >
              <Typography
                color="rgba(0, 0, 0, 0.4)"
                fontWeight={700}
                textTransform="uppercase"
                fontSize={25}
                letterSpacing={0.5}
                textAlign="center"
              >
                {noAvatarName}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: 'white',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0.5,
              borderRadius: 10,
              bottom: -5,
              left: 70,
            }}
          >
            <IconButton disabled={disabled} onClick={() => {}} size="small">
              <label htmlFor="avatar" className="label-avatar">
                <CameraAltOutlinedIcon fontSize="small" color="action" />
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  className="avatar-upload"
                  accept="image/png, image/jpeg"
                  onChange={e =>
                    changePhoto(e.target.files ? e.target.files[0] : null)
                  }
                />
              </label>
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default GridFormPhoto;
