import { GenericAbortSignal } from 'axios';
import {
  AddAudienceObj,
  AudiencePostResponse,
  AudienceResponse,
} from '../models/Audience';
import { BasicProps } from '../models/Sites';
import APIClient from '../services/APIClient';

export const fetchAudiences = async (
  alphabet?: string,
  order?: string,
  status?: string,
  sites_ids?: number,
) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.get(
    `/audiences/?ordering=${order ?? 'name'}${
      alphabet ? `&alphabet_page=${alphabet}` : ''
    }${status ? `&status=${status}` : ''}${
      sites_ids ? `&sites_ids=${sites_ids}` : ''
    }`,
  );
  return audienceResponse.data;
};

export const fetchAudiencesSearch = async (query: string, letter: string) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.get(
    `/audiences/alphabet-search/?name=${query}${
      letter ? `&alphabet_page=${letter}` : ''
    }`,
  );
  return audienceResponse.data;
};

export const fetchAudiencesCounter = async () => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.get(
    '/audiences/alphabet-counter/',
  );
  return audienceResponse.data;
};

export const fetchAudiencesForForm = async (
  site_id: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.get<BasicProps[]>(
    `/audiences/?template_for_site=${site_id}&ordering=name`,
    { signal },
  );
  return audienceResponse.data;
};

export const fetchAudienceCount = async (id: number) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.get<{
    linksCount: number;
    templatesCount: number;
    sitesCount: number;
  }>(`/audiences/${id}/count-associated/`);
  return audienceResponse.data;
};

export const addAudience = async (obj: AddAudienceObj) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.post<AudiencePostResponse>(
    `/audiences/`,
    obj,
  );
  return audienceResponse.data;
};

export const updateAudience = async (
  obj: { status: 'active' | 'inactive' },
  id: number,
) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.put<AudienceResponse[]>(
    `/audiences/${id}/`,
    obj,
  );
  return audienceResponse.data;
};

export const updateAudienceStatus = async (obj: { ids: number[] }) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.post<string>(
    `/audiences/change-status/`,
    obj,
  );
  return audienceResponse.data;
};

export const deleteAudience = async (id: number, newId: number) => {
  const clientAPI = new APIClient();
  const audienceResponse = await clientAPI.client.delete(
    `/audiences/${id}/${newId !== 0 ? `?audience_id=${newId}` : ''}`,
  );
  return audienceResponse.data;
};
