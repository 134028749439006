import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import { TextInputField } from '../../inputs';
import { FlexBox } from '../../tableItems';
import { MainButton } from '../../buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  addNewFavFilter: (name: string) => Promise<void>;
  loading: boolean;
}

const AddNewFilter = ({
  open,
  closeModal,
  addNewFavFilter,
  loading,
}: Props) => {
  const [name, setName] = useState('');

  useEffect(() => {
    return () => {
      setName('');
    };
  }, []);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box justifyContent="end" display="flex">
          <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Typography
          fontWeight={700}
          fontSize={17}
          textAlign="center"
          textTransform="capitalize"
        >
          Add Favorite Filter
        </Typography>
        <Box marginTop={1}>
          <Typography fontSize={13} marginBottom={1.3} textAlign="center">
            In order to add a new favorite filter, please add a name:
          </Typography>
          <TextInputField
            placeholder="Name"
            size="small"
            fullWidth
            inputProps={{ maxLength: 30 }}
            onChange={e => setName(e.target.value)}
          />
        </Box>
        <Box marginTop={3.5}>
          <FlexBox marginTop={3} justifyContent="center">
            <MainButton
              onClick={() => closeModal(false)}
              variant="contained"
              sx={{
                backgroundColor: '#F0F0F0',
                color: 'black',
                marginRight: 1,
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
            >
              Cancel
            </MainButton>
            <MainButton
              variant="contained"
              onClick={() => addNewFavFilter(name)}
              loading={loading}
              disabled={name === ''}
              sx={{ marginLeft: 1 }}
            >
              Save
            </MainButton>
          </FlexBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewFilter;
