import { TableCell, Typography } from '@mui/material';
import React from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { FlexBox } from '../../tableItems';

interface Props {
  notes: number;
}

const TableCellNotes = ({ notes }: Props) => {
  return (
    <TableCell>
      <FlexBox>
        <ChatOutlinedIcon color="disabled" />
        <Typography fontSize={14} marginLeft={0.8}>
          {notes}
        </Typography>
      </FlexBox>
    </TableCell>
  );
};

export default TableCellNotes;
