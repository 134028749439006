import { Drawer } from '@mui/material';
import React from 'react';

interface Props {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const MainDrawer = ({ openDrawer, setOpenDrawer, children }: Props) => {
  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      className="scroll-custom"
      disableEscapeKeyDown
      onClose={(e, r) => {
        if (r === 'backdropClick') return;
        setOpenDrawer(false);
      }}
      PaperProps={{ className: 'drawer-width' }}
    >
      {children}
    </Drawer>
  );
};

export default MainDrawer;
