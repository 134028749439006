import { Grid, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

const GridForms = ({ children, title }: Props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: 1,
        borderColor: '#D9D9D9',
        paddingTop: 2.3,
        paddingBottom: 2.5,
      }}
    >
      <Grid item xs={3}>
        <Typography color="rgba(0, 0, 0, 0.8)" fontSize={14} fontWeight={600}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default GridForms;
