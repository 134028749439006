import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Spinner = () => {
  return (
    <Box display="flex" justifyContent="center" marginTop={4.4}>
      <CircularProgress sx={{ color: '#ABABAB' }} />
    </Box>
  );
};

export default Spinner;
