import React from 'react';
import { InputInterface } from '../../models/Forms';

interface Props {
  inputs: InputInterface[];
}

const BoxInput = ({ inputs }: Props) => {
  return (
    <div className="form-grids mb-2">
      {inputs.map(input => (
        <div
          key={input.id}
          className={`mb-2 ${input.fullSpace ? 'whole-grid' : ''}`}
        >
          {input.body}
        </div>
      ))}
    </div>
  );
};

export default BoxInput;
