import { useState, useEffect, useContext } from 'react';
import Context from '../context/UserContext';
import { UserContextType } from '../models/User';
import { fetchUnsubscribeCount } from '../helpers/unsubscribed';
import { IS_USER_ADMIN_MANAGER_STRAT } from '../constants/user';

const useUnsubscribeCount = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [loading, setLoading] = useState(false);
  const [countResult, setCountResult] = useState(0);
  const [previousMonthResult, setPreviousMonthResult] = useState(0);

  const getCounter = () => {
    setLoading(true);
    fetchUnsubscribeCount(
      !IS_USER_ADMIN_MANAGER_STRAT.includes(UserContext.profile.role)
        ? UserContext.id
        : undefined,
    )
      .then(res => {
        setCountResult(res.total);
        setPreviousMonthResult(res.previous_month);
      })
      .catch(err => {
        setCountResult(0);
        setPreviousMonthResult(0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(getCounter, []);

  return { countResult, loading, previousMonthResult };
};

export default useUnsubscribeCount;
