export interface TemplateAffinity {
  id: number;
  name: string;
  status: string;
}

export interface TemplateAudience {
  id: number;
  name: string;
  status: string;
}

interface TemplateOwner {
  id: number;
  username: string;
  email: string;
}

export interface TemplatesRequest {
  id: number;
  title: string;
  subject: string;
  body: string;
  status: string;
  created: string;
  greetings: string;
  automatic_greeting: boolean;
  signoff: string;
  modified?: string;
  affinity: TemplateAffinity;
  audience: TemplateAudience;
  site: TemplateAffinity;
  owner: TemplateOwner;
  status_notes: number[];
  general_notes: number[];
}

export interface TemplatesRequestV1 {
  id: number;
  title: string;
  body: string;
  created: string;
  modified: string;
  subject: string;
  original_template_id: number;
  site: number;
  affinity: TemplateAffinity;
  audience: TemplateAudience;
  owner: TemplateOwner;
}

export const TemplateObjRequest = {
  id: 0,
  title: '',
  subject: '',
  body: '',
  greetings: '',
  signoff: '',
  automatic_greeting: true,
  status: '',
  created: '',
  affinity: { id: 0, name: '', status: '' },
  audience: { id: 0, name: '', status: '' },
  site: { id: 0, name: '', status: '' },
  owner: { id: 0, username: '', email: '' },
  status_notes: [],
  general_notes: [],
};

export const TemplateObjCreate = {
  title: '',
  site: 0,
  owner: 0,
  status: 'active',
  affinity: 0,
  audience: 0,
  subject: '',
  body: '',
  greetings: '',
  automatic_greeting: true,
  signoff: '',
};

export interface RequestOfTemplates {
  count: number;
  next: string;
  previous: string;
  results: TemplatesRequest[];
}

export interface TemplateVisibilityColumns {
  site__name: boolean;
  owner__username: boolean;
  status: boolean;
  created: boolean;
  modified: boolean;
  affinity: boolean;
  audience: boolean;
  notes_count: boolean;
}

export interface CreateTemplateInt {
  id?: number;
  title: string;
  subject: string;
  body: string;
  status: string;
  automatic_greeting: boolean;
  affinity: number;
  audience: number;
  greetings: string;
  signoff: string;
  site: number;
  owner: number;
}
