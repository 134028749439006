import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserResponse } from '../models/User';
import routesNames from './customRoutes';

const PublicRoute = () => {
  const { pathname } = useLocation();
  const isLogged: UserResponse | false = JSON.parse(
    localStorage.getItem('backlink.user') ?? 'false',
  );
  const value: UserResponse | false =
    isLogged || JSON.parse(sessionStorage.getItem('backlink.user') ?? 'false');

  return (
    <>
      {pathname === '/unsubscribe/' && (
        <div>
          <Outlet />
        </div>
      )}
      {value && pathname !== '/unsubscribe/' && (
        <Navigate to={routesNames.home} />
      )}
      {!value && pathname === '/login' && (
        <div>
          <Outlet />
        </div>
      )}
    </>
  );
};

export default PublicRoute;
