import React, { useState, useEffect, useContext } from 'react';
import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import dayjs from 'dayjs';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import { BasicSelect, SelectFormInput, TextFormField } from '../FormInputs';
import GridForms from '../../Tab/GridForms';
import { MainButton } from '../../buttons';
import GridFormPhoto from '../../Tab/GridFormPhoto';
import BoxInput from '../BoxInput';
import {
  ACCOUNTS_USER,
  ROLES_USER,
  ROLES_USER_ACCOUNT_MANAGER,
  ROLES_USER_MANAGERS,
} from '../../../constants/user';
import { DefaultCheckbox } from '../../inputs';
import DateInput from '../DateInput';
import { onlyNumbers } from '../../../helpers/validateInputs';
import {
  EditUserInt,
  UserContextType,
  UserEditObj,
} from '../../../models/User';
import useProjects from '../../../customHooks/useProjects';
import {
  ProjectSitesFinalSave,
  UserProjectSites,
} from '../../../models/Projects';
import { FlexBoxBetween } from '../../tableItems';
import Spinner from '../../Loading/Spinner';
import TabParent from '../../Tab/TabParent';
import TabPanel from '../../Tab/TabPanel';
import { updateProfilePicture, updateUserInfo } from '../../../helpers/user';
import { BasicArray, SeverityType } from '../../../models/General';
import useOwners from '../../../customHooks/useOwners';
import TableSiteAccess from './ProjectsSites/TableSiteAccess';
import RowProjectSitesShow from './RowProjectSitesShow';
import ProjectsSitesModal from './ProjectsSites/ProjectsSitesModal';
import StatusModal from '../StatusModal';
import ChangeUserRoleModal from '../../Modals/ChangeUserRole';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import BoxAddSettings from '../BoxAddSettings';
import FormStatus from '../FormStatus';
import Context from '../../../context/UserContext';

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  userEdit: UserEditObj;
  id: number;
  projectsUser: UserProjectSites[];
  sitesUser: UserProjectSites[];
  showMessage: (message: string, severity: SeverityType) => void;
  reloadData: () => void;
  isActive: boolean;
}

const AVOID_ROLES = [
  'manager',
  'account_manager',
  'trainer',
  'strategist',
  'admin',
];
const justAdmin = ['administrator', 'dev admin'];

const EditUser = ({
  setOpenDrawer,
  userEdit,
  id,
  projectsUser,
  sitesUser,
  showMessage,
  reloadData,
  isActive,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [openModal, setOpenModal] = useState(false);
  const { projects } = useProjects({});
  const [errorPassword, setErrorPassword] = useState(false);
  const [userForm, setUserForm] = useState(userEdit);
  const [tabDrawer, setTabDrawer] = useState(0);
  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const { users: usersManagers } = useOwners({
    withFields: false,
    fullName: true,
    role: 'manager,administrator',
  });
  const { users: usersAccountManagers } = useOwners({
    withFields: false,
    role: 'account manager',
    filter: true,
  });
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [disabledSave, setDisabledSave] = useState(true);
  const [newPassword, setNewPassword] = useState({
    password: '',
    password_confirmation: '',
  });
  const [projectsSites, setProjectsSites] = useState<ProjectSitesFinalSave[]>(
    [],
  );

  const { history } = useHistoryTab({ id, type: 'user' });
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [modalStatus, setModalStatus] = useState(false);
  const [modalChangeUser, setModalChangeUser] = useState(false);
  const [withNote, setWithNote] = useState(false);
  const [roleNew, setRoleNew] = useState('');

  const [updateUserObj, setUpdateUserObj] = useState<EditUserInt>({});
  const [projectsAndSitesUser, setProjectsAndSitesUser] = useState<{
    projects: { id: number; date_effective: Date }[];
    sites: { id: number; date_effective: Date }[];
  }>({ projects: [], sites: [] });

  const deleteFromUpdateObj = (key: keyof typeof updateUserObj) => {
    const copyObj = { ...updateUserObj };
    delete copyObj[key];
    setUpdateUserObj(copyObj);
  };

  const deleteFromUpdateObjProfile = (key: string) => {
    const copyObj = { ...updateUserObj };
    if (!copyObj.profile) return;
    const keyNew = key as keyof typeof copyObj.profile;
    delete copyObj.profile[keyNew];
    setUpdateUserObj(copyObj);
  };

  const checkEditUser = () => {
    if (UserContext.is_superuser) return true;
    if (
      UserContext.profile.role === 'administrator' ||
      UserContext.profile.role === 'dev admin'
    )
      return true;
    if (UserContext.profile.role === 'strategist')
      return userEdit.role === 'linkbuilder' || userEdit.role === 'contractor';
    if (UserContext.profile.role === 'manager')
      return userEdit.manager === UserContext.id;
    if (userEdit.account_manager === null) return false;
    return UserContext.profile.role === 'account manager'
      ? userEdit.account_manager === UserContext.id
      : true;
  };

  const canEditUser = checkEditUser();

  const getRolesOptions = () => {
    if (
      UserContext.profile.role === 'manager' ||
      UserContext.profile.role === 'account manager'
    ) {
      const copyArr =
        UserContext.profile.role === 'account manager'
          ? [...ROLES_USER_ACCOUNT_MANAGER]
          : [...ROLES_USER_MANAGERS];
      if (userEdit.role === '') return copyArr;
      const index = copyArr.findIndex(item => item.value === userEdit.role);
      if (index === -1)
        copyArr.push({
          value: userEdit.role ?? '',
          label:
            ROLES_USER.find(item => item.value === userEdit.role)?.label ?? '',
        });
      return copyArr;
    }
    return ROLES_USER;
  };

  const inputsArr = [
    {
      id: 0,
      value: userForm.first_name,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="first-name"
          label="First Name"
          value={userForm.first_name}
          error={false}
          errorText=""
          placeholder="First Name"
          onChange={(value: string) => {
            if (value === userEdit.first_name || value === '')
              deleteFromUpdateObj('first_name');
            else setUpdateUserObj({ ...updateUserObj, first_name: value });
            setUserForm({ ...userForm, first_name: value });
          }}
        />
      ),
    },
    {
      id: 1,
      value: userForm.last_name,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="last-name"
          label="Last Name"
          value={userForm.last_name}
          error={false}
          errorText=""
          placeholder="Last Name"
          onChange={(value: string) => {
            if (value === userEdit.last_name || value === '')
              deleteFromUpdateObj('last_name');
            else setUpdateUserObj({ ...updateUserObj, last_name: value });
            setUserForm({ ...userForm, last_name: value });
          }}
        />
      ),
    },
    {
      id: 2,
      value: userForm.email,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="email"
          label="Email"
          value={userForm.email}
          error={false}
          errorText=""
          placeholder="Email"
          onChange={(value: string) => {
            if (value === userEdit.email || value === '')
              deleteFromUpdateObj('email');
            else setUpdateUserObj({ ...updateUserObj, email: value });
            setUserForm({ ...userForm, email: value });
          }}
        />
      ),
    },
    {
      id: 16,
      value: '',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled
          nameInput="user_id"
          label="User ID"
          value={id.toString()}
          error={false}
          errorText=""
          placeholder="User ID"
          onChange={() => {}}
        />
      ),
    },
    {
      id: 50,
      value: userForm.employee_title,
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={!canEditUser}
          nameInput="employee_title"
          label="Employee Title"
          value={userForm.employee_title}
          error={false}
          errorText=""
          placeholder="Employee Title"
          onChange={(value: string) => {
            if (value === userEdit.employee_title || value === '')
              deleteFromUpdateObjProfile('employee_title');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: { ...updateUserObj.profile, employee_title: value },
              });
            setUserForm({ ...userForm, employee_title: value });
          }}
        />
      ),
    },
    {
      id: 3,
      value: userForm.username,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="username"
          label="Username"
          value={userForm.username}
          error={false}
          errorText=""
          placeholder="Username"
          onChange={(value: string) => {
            if (value === userEdit.username || value === '')
              deleteFromUpdateObj('username');
            else setUpdateUserObj({ ...updateUserObj, username: value });
            setUserForm({ ...userForm, username: value });
          }}
        />
      ),
    },
    {
      id: 15,
      value: userForm.preferred_name,
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={!canEditUser}
          nameInput="preferred_name"
          label="Preferred Name"
          value={userForm.preferred_name}
          error={false}
          errorText=""
          placeholder="Preferred Name"
          onChange={(value: string) => {
            if (value === userEdit.preferred_name || value === '')
              deleteFromUpdateObjProfile('preferred_name');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: { ...updateUserObj.profile, preferred_name: value },
              });
            setUserForm({ ...userForm, preferred_name: value });
          }}
        />
      ),
    },
    {
      id: 4,
      required: true,
      value: newPassword.password,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required={false}
          disabled={!canEditUser}
          nameInput="password"
          label="Password"
          error={errorPassword}
          type="password"
          autocomplete="new-password"
          value={newPassword.password}
          errorText={errorPassword ? 'Passwords do not match' : ''}
          placeholder="New Password"
          onChange={(value: string) =>
            setNewPassword({ ...newPassword, password: value })
          }
        />
      ),
    },
    {
      id: 5,
      required: true,
      value: newPassword.password_confirmation,
      validate: false,
      validation: [],
      fullSpace: false,
      body: (
        <TextFormField
          required={false}
          disabled={!canEditUser}
          nameInput="confirmPassword"
          label="Confirm Password"
          type="password"
          autocomplete="new-password"
          error={errorPassword}
          value={newPassword.password_confirmation}
          errorText={errorPassword ? 'Passwords do not match' : ''}
          placeholder="Confirm Password"
          onChange={(value: string) =>
            setNewPassword({ ...newPassword, password_confirmation: value })
          }
        />
      ),
    },
    {
      id: 6,
      value: '',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <BasicSelect
          label="Account"
          required={false}
          options={ACCOUNTS_USER}
          nameInput="account"
          disabled={!canEditUser}
          value={userForm.account}
          onChange={(value: string) => {
            if (value === userEdit.account || value === '')
              deleteFromUpdateObjProfile('account');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: { ...updateUserObj.profile, account: value },
              });
            setUserForm({ ...userForm, account: value });
          }}
        />
      ),
    },
    {
      id: 7,
      value: '',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={usersManagers}
          disabled={
            usersManagers.length === 0 ||
            !justAdmin.includes(UserContext.profile.role)
          }
          placeholder={
            usersManagers.length === 0 ? 'Loading Managers...' : 'Manager'
          }
          value={
            usersManagers.find(item => item.id === userForm.manager) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            if (value === null || (value && value.id === userEdit.manager))
              deleteFromUpdateObjProfile('manager');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: {
                  ...updateUserObj.profile,
                  manager: value ? (value.id as number) : 0,
                },
              });
            setUserForm({
              ...userForm,
              manager: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 8,
      value: '',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={usersAccountManagers}
          disabled={
            usersAccountManagers.length === 0 ||
            !justAdmin.includes(UserContext.profile.role)
          }
          placeholder="Account Manager"
          value={
            usersAccountManagers.find(
              item => item.id === userForm.account_manager,
            ) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            if (
              value === null ||
              (value && value.id === userEdit.account_manager)
            )
              deleteFromUpdateObjProfile('account_manager');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: {
                  ...updateUserObj.profile,
                  account_manager: value ? (value.id as number) : 0,
                },
              });
            setUserForm({
              ...userForm,
              account_manager: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 9,
      value: userForm.role,
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <BasicSelect
          label="Role"
          required
          options={getRolesOptions()}
          nameInput="role"
          disabled={!canEditUser}
          value={userForm.role}
          onChange={(value: string) => {
            if (value === userEdit.role || value === '') {
              const obj = { ...updateUserObj };
              delete obj.role_name;
              if (!obj.text_note) delete obj.reassign_user_id;
              if (value === 'trainer' && obj.profile) {
                obj.profile.training_env_allowed = true;
              }
              setUpdateUserObj(obj);
              if (value === 'trainer') {
                setUserForm({
                  ...userForm,
                  role: value,
                  training_env_allowed: true,
                });
              } else {
                setUserForm({ ...userForm, role: value });
              }
            } else if (
              userEdit.role === 'manager' &&
              !updateUserObj.reassign_user_id
            ) {
              setRoleNew(value);
              setWithNote(false);
              setModalChangeUser(true);
            } else if (value === 'trainer') {
              setUpdateUserObj({
                ...updateUserObj,
                role_name: value,
                profile: {
                  ...updateUserObj.profile,
                  training_env_allowed: true,
                },
              });
              setUserForm({
                ...userForm,
                role: value,
                training_env_allowed: true,
              });
            } else {
              setUpdateUserObj({
                ...updateUserObj,
                role_name: value,
              });
              setUserForm({ ...userForm, role: value });
            }
          }}
        />
      ),
    },
    {
      id: 10,
      value: userForm.status,
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <BasicSelect
          label="Status"
          required={false}
          options={[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
          ]}
          nameInput="status"
          disabled={!canEditUser}
          value={userForm.status}
          onChange={(value: string) => {
            if (value === userEdit.status || value === '') {
              const obj = { ...updateUserObj };
              delete obj.is_active;
              delete obj.text_note;
              if (!obj.role_name) delete obj.reassign_user_id;
              setUpdateUserObj(obj);
              setUserForm({ ...userForm, status: value });
            } else if (
              userEdit.role === 'manager' &&
              userEdit.status === 'active' &&
              value === 'inactive'
            ) {
              setRoleNew('');
              setWithNote(true);
              setModalChangeUser(true);
            } else setModalStatus(true);
          }}
        />
      ),
    },
    {
      id: 11,
      value: userForm.can_override_links ? 'true' : 'false',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Override Links"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-20px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={!canEditUser}
              sx={{ mt: '-20px', ml: 1 }}
              size="medium"
              checked={userForm.can_override_links}
              onClick={() =>
                setUserForm({
                  ...userForm,
                  can_override_links: !userForm.can_override_links,
                })
              }
            />
          }
        />
      ),
    },
    {
      id: 12,
      value: userForm.status_effective_date,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <Box>
          <InputLabel
            htmlFor="status_effective_date"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
              marginTop: '-10px',
            }}
          >
            Status Effective Date
          </InputLabel>
          <DateInput
            disabled={!canEditUser}
            value={userForm.status_effective_date}
            handleChangeDate={(date: string) => {
              if (date === userEdit.status_effective_date || date === '')
                deleteFromUpdateObjProfile('status_effective');
              else
                setUpdateUserObj({
                  ...updateUserObj,
                  profile: { ...updateUserObj.profile, status_effective: date },
                });
              setUserForm({ ...userForm, status_effective_date: date });
            }}
          />
        </Box>
      ),
    },
    {
      id: 25,
      value: userForm.training_env_allowed ? 'true' : 'false',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Training Env Allowed"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-20px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={!canEditUser}
              sx={{ mt: '-20px', ml: 1 }}
              size="medium"
              checked={userForm.training_env_allowed}
              onClick={() => {
                if (
                  !userForm.training_env_allowed ===
                  userEdit.training_env_allowed
                )
                  deleteFromUpdateObjProfile('training_env_allowed');
                else
                  setUpdateUserObj({
                    ...updateUserObj,
                    profile: {
                      ...updateUserObj.profile,
                      training_env_allowed: !userForm.training_env_allowed,
                    },
                  });
                setUserForm({
                  ...userForm,
                  training_env_allowed: !userForm.training_env_allowed,
                });
              }}
            />
          }
        />
      ),
    },
    // {
    //   id: 13,
    //   value: userForm.assign_links ? 'true' : 'false',
    //   fullSpace: false,
    //   required: false,
    //   validate: false,
    //   validation: [],
    //   body: (
    //     <FormControlLabel
    //       label="Assign Links"
    //       sx={{
    //         '& .MuiFormControlLabel-label': {
    //           fontSize: 14,
    //           color: '#000000',
    //         },
    //       }}
    //       control={
    //         <DefaultCheckbox
    //           disabled={false}
    //           sx={{ ml: 1 }}
    //           size="medium"
    //           checked={userForm.assign_links}
    //           onClick={() =>
    //             setUserForm({
    //               ...userForm,
    //               assign_links: !userForm.assign_links,
    //             })
    //           }
    //         />
    //       }
    //     />
    //   ),
    // },
    {
      id: 30,
      value: userForm.unlimited_exceptions ? 'true' : 'false',
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Unlimited Domain Exception Outreach"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={!canEditUser}
              sx={{ ml: 1 }}
              size="medium"
              checked={userForm.unlimited_exceptions}
              onClick={() => {
                if (
                  !userForm.unlimited_exceptions ===
                  userEdit.unlimited_exceptions
                )
                  deleteFromUpdateObjProfile('unlimited_exceptions');
                else
                  setUpdateUserObj({
                    ...updateUserObj,
                    profile: {
                      ...updateUserObj.profile,
                      unlimited_exceptions: !userForm.unlimited_exceptions,
                    },
                  });
                setUserForm({
                  ...userForm,
                  unlimited_exceptions: !userForm.unlimited_exceptions,
                });
              }}
            />
          }
        />
      ),
    },
    {
      id: 31,
      value: userForm.monthly_exceptions,
      fullSpace: false,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={!canEditUser}
          nameInput="monthly-except"
          label="Monthly Exceptions"
          value={userForm.monthly_exceptions.toString()}
          error={false}
          errorText=""
          placeholder="Monthly Exceptions"
          onChange={(value: string) => {
            if (
              onlyNumbers(value) === userEdit.monthly_exceptions ||
              onlyNumbers(value) === ''
            )
              deleteFromUpdateObjProfile('monthly_exceptions');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: {
                  ...updateUserObj.profile,
                  monthly_exceptions: parseInt(onlyNumbers(value), 10),
                },
              });
            setUserForm({
              ...userForm,
              monthly_exceptions: onlyNumbers(value),
            });
          }}
        />
      ),
    },
    {
      id: 14,
      value: userForm.diff_min,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="difficult-min"
          label="Difficulty Minimum"
          value={userForm.diff_min.toString()}
          error={false}
          errorText=""
          placeholder="Difficulty Minimum"
          onChange={(value: string) => {
            if (
              onlyNumbers(value) === userEdit.diff_min ||
              onlyNumbers(value) === ''
            )
              deleteFromUpdateObjProfile('difficulty_minimum');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: {
                  ...updateUserObj.profile,
                  difficulty_minimum: parseInt(onlyNumbers(value), 10),
                },
              });
            setUserForm({
              ...userForm,
              diff_min: onlyNumbers(value),
            });
          }}
        />
      ),
    },
    {
      id: 15,
      value: userForm.diff_goal,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={!canEditUser}
          nameInput="difficult-goal"
          label="Difficulty Goal"
          value={userForm.diff_goal.toString()}
          error={false}
          errorText=""
          placeholder="Difficulty Goal"
          onChange={(value: string) => {
            if (
              onlyNumbers(value) === userEdit.diff_goal ||
              onlyNumbers(value) === ''
            )
              deleteFromUpdateObjProfile('difficulty_goal');
            else
              setUpdateUserObj({
                ...updateUserObj,
                profile: {
                  ...updateUserObj.profile,
                  difficulty_goal: parseInt(onlyNumbers(value), 10),
                },
              });
            setUserForm({
              ...userForm,
              diff_goal: onlyNumbers(value),
            });
          }}
        />
      ),
    },
  ];

  const updateUser = async (obj: EditUserInt) => {
    if (Object.keys(obj).length === 0) return;
    try {
      await updateUserInfo(id, obj);
    } catch (err) {
      console.log(err);
    }
  };

  const changeAvatar = async () => {
    if (!newAvatar) return;
    try {
      await updateProfilePicture(newAvatar, id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmits = () => {
    const copyObj = { ...updateUserObj };
    setIsLoadingSubmit(true);
    setErrorPassword(false);
    if (
      newPassword.password !== '' &&
      newPassword.password_confirmation !== '' &&
      newPassword.password !== newPassword.password_confirmation
    ) {
      setErrorPassword(true);
      setIsLoadingSubmit(false);
      return;
    }
    if (
      newPassword.password !== '' &&
      newPassword.password === newPassword.password_confirmation
    ) {
      copyObj.password = newPassword.password;
    }
    Promise.all([updateUser(copyObj), changeAvatar()])
      .then(() => {
        showMessage('User updated successfully', 'success');
        reloadData();
        setOpenDrawer(false);
      })
      .catch(err => {
        showMessage('Error while updating user', 'error');
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  };

  useEffect(() => {
    const obj = { ...updateUserObj };
    if (obj.profile && Object.keys(obj.profile).length === 0) {
      delete obj.profile;
      setUpdateUserObj(obj);
    }

    if (
      newPassword.password === '' &&
      newPassword.password_confirmation === '' &&
      (Object.keys(obj).length > 0 || newAvatar)
    ) {
      setDisabledSave(false);
      return;
    }

    if (
      newPassword.password !== '' ||
      newPassword.password_confirmation !== ''
    ) {
      setDisabledSave(
        !(
          newPassword.password !== '' &&
          newPassword.password_confirmation !== ''
        ),
      );
      return;
    }
    setDisabledSave(true);
  }, [updateUserObj, newPassword, newAvatar]);

  const changePhoto = (file: File | null) => setNewAvatar(file);

  useEffect(() => {
    if (projects.length > 0) {
      const sitesIds = sitesUser.map(site => site.id);
      const projectsSitesArr = projects.map(project => {
        const index = projectsUser.findIndex(item => item.id === project.id);
        if (index !== -1) {
          return {
            id: project.id,
            name: project.name,
            projectDate:
              projectsUser[index].date_effective ??
              dayjs().format('MM/DD/YYYY'),
            sites: project.sites.map(site => {
              return {
                id: site.id,
                name: site.name,
                date: projectsUser[index].date_effective
                  ? dayjs(projectsUser[index].date_effective).format(
                      'MM/DD/YYYY',
                    )
                  : dayjs().format('MM/DD/YYYY'),
                projectDate: projectsUser[index].date_effective
                  ? dayjs(projectsUser[index].date_effective).format(
                      'MM/DD/YYYY',
                    )
                  : dayjs().format('MM/DD/YYYY'),
              };
            }),
            isProject: true,
          };
        }
        return {
          id: project.id,
          name: project.name,
          projectDate: dayjs().format('MM/DD/YYYY'),
          sites: project.sites
            .filter(site => sitesIds.includes(site.id))
            .map(site => {
              const findIndex = sitesUser.findIndex(
                item => item.id === site.id,
              );
              return {
                id: site.id,
                name: site.name,
                date: sitesUser[findIndex].date_effective
                  ? dayjs(sitesUser[findIndex].date_effective).format(
                      'MM/DD/YYYY',
                    )
                  : dayjs().format('MM/DD/YYYY'),
                projectDate: dayjs().format('MM/DD/YYYY'),
              };
            }),
          isProject: false,
        };
      });
      setProjectsSites(projectsSitesArr.filter(item => item.sites.length > 0));
      setIsLoadingProjects(false);
    }
  }, [projects]);

  const convertToDateAndSort = (arr: UserProjectSites[]) =>
    arr
      .map(item => {
        return { id: item.id, date_effective: new Date(item.date_effective) };
      })
      .sort((a, b) => a.id - b.id);

  useEffect(() => {
    if (projectsUser.length > 0 || sitesUser.length > 0) {
      const convertToDate = convertToDateAndSort(projectsUser);

      const convertToDateSites = convertToDateAndSort(sitesUser);

      setProjectsAndSitesUser({
        projects: convertToDate,
        sites: convertToDateSites,
      });
    }
  }, []);

  const areTheSame = (
    array1: { id: number; date_effective: Date }[],
    array2: { id: number; date_effective: Date }[],
  ) => {
    if (array1.length !== array2.length) return false;
    if (array1.length === 0 && array2.length === 0) return true;
    for (let i = 0; i < array1.length; i += 1) {
      if (
        array1[i].id !== array2[i].id ||
        array1[i].date_effective.getTime() !==
          array2[i].date_effective.getTime()
      )
        return false;
    }
    return true;
  };

  const handleSaveProjects = (
    arr: ProjectSitesFinalSave[],
    site_ids: UserProjectSites[],
    project_ids: UserProjectSites[],
  ) => {
    const compareProjects = areTheSame(
      convertToDateAndSort(project_ids),
      projectsAndSitesUser.projects,
    );
    const compareSites = areTheSame(
      convertToDateAndSort(site_ids),
      projectsAndSitesUser.sites,
    );
    if (compareProjects && compareSites) {
      const obj = { ...updateUserObj };
      delete obj.profile?.site_ids;
      delete obj.profile?.project_ids;
      setUpdateUserObj(obj);
    } else if (!compareProjects || !compareSites) {
      setUpdateUserObj({
        ...updateUserObj,
        profile: { ...updateUserObj.profile, site_ids, project_ids },
      });
    }
    setProjectsSites(arr);
    setOpenModal(false);
  };

  const updateStatus = (note: string) => {
    const newStatus = userEdit.status === 'active' ? 'inactive' : 'active';
    setUpdateUserObj({
      ...updateUserObj,
      text_note: note,
      is_active: !isActive,
    });
    setUserForm({ ...userForm, status: newStatus });
    setModalStatus(false);
  };

  const updateReassignUser = (
    reassign_id: number,
    role?: string,
    note?: string,
  ) => {
    if (note) {
      const newStatus = userEdit.status === 'active' ? 'inactive' : 'active';
      setUpdateUserObj({
        ...updateUserObj,
        text_note: note,
        is_active: !isActive,
        reassign_user_id: reassign_id,
      });
      setUserForm({ ...userForm, status: newStatus });
    }
    if (role) {
      setUpdateUserObj({
        ...updateUserObj,
        role_name: role,
        reassign_user_id: reassign_id,
        profile: { manager: reassign_id },
      });
      setUserForm({ ...userForm, role, manager: reassign_id });
    }
    setModalChangeUser(false);
  };

  return (
    <>
      <HeaderDrawer
        title="Edit User"
        threeButtons={false}
        loading={isLoadingSubmit}
        disabled={disabledSave}
        closeDrawer={setOpenDrawer}
        saveFunction={handleSubmits}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: 1.3,
          marginLeft: 3,
          marginRight: 4,
        }}
      >
        <TabParent
          valueTab={tabDrawer}
          setTab={setTabDrawer}
          tabs={[
            { id: 0, label: 'Details' },
            { id: 1, label: 'History' },
          ]}
          center
        />
      </Box>
      <TabPanel value={tabDrawer} index={0}>
        <Box paddingLeft={4} paddingRight={5}>
          <GridFormPhoto
            avatar={userEdit.avatar}
            newAvatar={newAvatar}
            disabled={!canEditUser}
            changePhoto={changePhoto}
            noAvatarName={
              userEdit.first_name !== ''
                ? `${userEdit.first_name[0]}${userEdit.last_name[0]}`
                : 'US'
            }
          >
            <BoxInput inputs={inputsArr.slice(0, 4)} />
            <div className="whole-grid">{inputsArr[4].body}</div>
          </GridFormPhoto>
          <GridForms title="Login">
            <BoxInput inputs={inputsArr.slice(5, 9)} />
            <div className="form-grids">
              <MainButton
                variant="contained"
                sx={{
                  width: '170px',
                  fontSize: '13px',
                  background: 'rgba(0, 0, 0, 0.1)',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
                onClick={() => {}}
              >
                Resend Setup Link
              </MainButton>
            </div>
          </GridForms>
          <GridForms title="Account Details">
            <div className="form-grids mb-2">
              <div className="mb-2">{inputsArr[9].body}</div>
            </div>
            <div className="form-grids mb-2">
              <div className="mb-2">{inputsArr[10].body}</div>
              <div className="mb-2">{inputsArr[11].body}</div>
            </div>
          </GridForms>
          <GridForms title="Permissions">
            <BoxInput inputs={inputsArr.slice(11, 13)} />
            <div className="form-grids mb-2">
              <div>{inputsArr[14].body}</div>
              <div>{inputsArr[15].body}</div>
              <div>{inputsArr[16].body}</div>
            </div>
          </GridForms>
          <GridForms title="Domain Exception Outreach Restriction">
            <div className="form-grids mb-2">
              <div>{inputsArr[17].body}</div>
              {(updateUserObj.profile?.unlimited_exceptions === false ||
                (userEdit.unlimited_exceptions === false &&
                  updateUserObj.profile?.unlimited_exceptions !== true)) && (
                <div>{inputsArr[18].body}</div>
              )}
            </div>
          </GridForms>
          <GridForms title="Goal Settings">
            <BoxInput inputs={inputsArr.slice(19)} />
          </GridForms>
          <Box paddingTop={2.3} paddingBottom={4}>
            <FlexBoxBetween>
              <Typography
                color="rgba(0, 0, 0, 0.8)"
                fontSize={14}
                fontWeight={600}
              >
                Projects & Sites Access
              </Typography>
              {canEditUser && !isLoadingProjects && (
                <Typography
                  marginBottom={2}
                  color="rgba(0, 0, 0, 0.8)"
                  fontWeight={600}
                  fontSize={15}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  textTransform="capitalize"
                  onClick={() => setOpenModal(true)}
                >
                  Manage
                </Typography>
              )}
            </FlexBoxBetween>
            <Box paddingX={1.5} paddingTop={2}>
              {isLoadingProjects && <Spinner />}
              {!isLoadingProjects && projectsSites.length > 0 && (
                <TableSiteAccess>
                  {projectsSites.map(projectSite => (
                    <RowProjectSitesShow
                      key={projectSite.id}
                      obj={projectSite}
                    />
                  ))}
                </TableSiteAccess>
              )}
              {!isLoadingProjects && projectsSites.length === 0 && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height={250}
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                  borderRadius="4px"
                >
                  <Typography
                    color="rgba(0, 0, 0, 0.7)"
                    fontSize={14}
                    marginBottom={1}
                  >
                    No Projects/sites have been added yet
                  </Typography>
                  {canEditUser && (
                    <MainButton
                      variant="contained"
                      sx={{
                        background: 'rgba(0, 0, 0, 0.1)',
                        color: 'black',
                        '&:hover': {
                          backgroundColor: '#F2F2F2',
                        },
                      }}
                      onClick={() => setOpenModal(true)}
                    >
                      Add New
                    </MainButton>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tabDrawer} index={1}>
        <BoxAddSettings>
          <FormStatus history={history} />
        </BoxAddSettings>
      </TabPanel>
      <ProjectsSitesModal
        sitesSelected={projectsSites}
        open={openModal}
        projects={projects}
        closeModal={setOpenModal}
        handleSaveProjects={handleSaveProjects}
      />
      <StatusModal
        open={modalStatus}
        closeModal={setModalStatus}
        updateStatus={updateStatus}
      />
      <ChangeUserRoleModal
        open={modalChangeUser}
        closeModal={setModalChangeUser}
        managers={usersManagers}
        note={withNote}
        updateReassignUser={updateReassignUser}
        roleNew={roleNew}
      />
    </>
  );
};

export default EditUser;
