import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BasicArray } from '../../models/General';
import { SelectFormInput, TextFormField } from '../Forms/FormInputs';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface ChangeUserRoleModalProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  managers: BasicArray[];
  note: boolean;
  roleNew: string;
  updateReassignUser: (
    reassign_id: number,
    role?: string,
    note?: string,
  ) => void;
}

const ChangeUserRoleModal = ({
  open,
  closeModal,
  managers,
  note,
  roleNew,
  updateReassignUser,
}: ChangeUserRoleModalProps) => {
  const [reasignUser, setReasignUser] = useState(0);
  const [newNote, setNewNote] = useState('');

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Manager Update
          </Typography>
          <Typography
            color="#373737"
            fontSize={14}
            textAlign="center"
            marginTop={1}
          >
            In order to update this user, you need to select a new manager to
            reassign all of their users to the new one
            {note ? 'and also leave a note' : ''}.
          </Typography>
          <Box marginTop={2}>
            <SelectFormInput
              required
              clearIcon
              options={managers}
              disabled={managers.length === 0}
              placeholder="Manager"
              value={
                managers.find(item => item.id === reasignUser) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setReasignUser(value ? (value.id as number) : 0)
              }
            />
          </Box>
          {note && (
            <Box marginTop={1}>
              <TextFormField
                disabled={false}
                nameInput="text_note"
                label="Note"
                error={false}
                multiline
                required
                value={newNote}
                errorText=""
                placeholder="Note"
                onChange={(value: string) => setNewNote(value)}
              />
            </Box>
          )}
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() =>
                  updateReassignUser(
                    reasignUser,
                    roleNew === '' ? undefined : roleNew,
                    note ? newNote : undefined,
                  )
                }
                disabled={
                  note ? newNote === '' || reasignUser === 0 : reasignUser === 0
                }
                sx={{ marginLeft: 1 }}
              >
                Reassign
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default ChangeUserRoleModal;
