import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButtonCustom } from '../buttons';
import { MenuOptionsDropdown } from '../../models/General';

interface Props {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  open: number;
  id: number;
  anchorEl: HTMLElement | null;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
  hideEffect?: boolean;
  name?: string;
  menuOptions: MenuOptionsDropdown[];
  colorButtonIcon?: string;
}

const LittleMenu = ({
  setAnchorEl,
  setOpen,
  id,
  name,
  open,
  anchorEl,
  menuOptions,
  hideEffect,
  colorButtonIcon,
}: Props) => {
  return (
    <>
      <IconButtonCustom
        aria-label="more"
        id="long-button"
        className={hideEffect ? 'list_item-button' : ''}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(e.currentTarget);
          setOpen(id);
          e.stopPropagation();
        }}
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButtonCustom>
      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        PaperProps={{
          style: { width: 130 },
        }}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        open={open === id}
        onClose={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          setOpen(0);
        }}
      >
        {menuOptions.map(item => (
          <MenuItem
            key={item.id}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              setOpen(0);
              item.click(id, name);
            }}
            sx={{ color: item.delete ? 'red' : '#373737', fontSize: 13 }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LittleMenu;
