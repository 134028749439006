/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Accordion as MuiAccordion,
  styled,
  AccordionProps,
  AccordionSummaryProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const AccordionCustom = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 0,
  },
}));

export const AccordionDetailsCustom = styled(MuiAccordionDetails)(() => ({
  border: 0,
  boxShadow: 'none',
  '.thisSite .Mui-expanded': {
    border: 'none !important',
  },
}));

export const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.thisSite .Mui-expanded & > .collapsIconWrapper': {
          display: 'none !important',
        },
        '.expandIconWrapper': {
          display: 'none !important',
        },
        '.thisSite .Mui-expanded & > .expandIconWrapper': {
          display: 'block !important',
        },
      }}
    >
      <AddIcon
        className="collapsIconWrapper"
        sx={{ fontSize: 17, color: '#000000' }}
      />
      <RemoveIcon
        className="expandIconWrapper"
        sx={{ fontSize: 17, color: '#000000' }}
      />
    </Box>
  );
};

export const AccordionSummaryCustom = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
))(() => ({
  padding: 0,
  boxShadow: 'none',
}));
