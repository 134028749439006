import { Typography } from '@mui/material';
import React from 'react';
import SettingsStructure from './SettingsStructure';

const Settings = () => {
  return (
    <SettingsStructure option={0} title="" button={false}>
      <Typography variant="h5">Choose one section of the settings</Typography>
    </SettingsStructure>
  );
};

export default Settings;
