/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { ContactsFormLink } from '../../../models/Forms';
import {
  ContactsValuesType,
  contactValueDefaultError,
  validateResponseEmailContact,
} from '../../../helpers/contacts';
import { TextFormField } from '../FormInputs';
import { TextInputField } from '../../inputs';
import { FlexBox } from '../../tableItems';
import { MainButton } from '../../buttons';
import { validateEmail } from '../../../helpers/user';
import { IS_USER_ADMIN_MANAGER } from '../../../constants/user';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface AddNewContactModalProps {
  contacts: ContactsFormLink[];
  open: boolean;
  closeModal: () => void;
  site: number;
  updateNewContactAndAllowEdit: (newContact: ContactsFormLink) => void;
}

const AddNewContactModal: React.FC<AddNewContactModalProps> = ({
  contacts,
  open,
  closeModal,
  site,
  updateNewContactAndAllowEdit,
}) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [newContact, setNewContact] = useState<ContactsFormLink>({
    name: '',
    email: '',
    is_form: false,
    status: 'open',
    is_default: true,
    validation_status: 'active',
  });
  const [validatingContact, setValidatingContact] = useState(false);
  const [contactValidation, setContactValidation] =
    useState<ContactsValuesType>(contactValueDefaultError);

  const handleEmailValidation = async (
    value: string,
    contactVal: ContactsValuesType,
  ) => {
    try {
      const resp = await validateEmail(value, site);
      const contactCopy = validateResponseEmailContact(resp);
      return contactCopy;
    } catch (err) {
      return contactVal;
    }
  };

  const requestEmailValidation = async (
    value: string,
    contactVal: ContactsValuesType,
  ) => {
    const contactNewValue = await handleEmailValidation(value, contactVal);

    if (
      contactNewValue.contact === null &&
      contactVal.previousChanged &&
      !contactNewValue.previousChanged
    ) {
      const contactCopy = {
        ...newContact,
        email: value,
        form_text: '',
        name: '',
        status: 'open',
      };

      contactNewValue.contact = contactCopy;
    }

    return {
      changeContact: contactNewValue.contact,
      contactNewValue,
    };
  };

  const handleEmailChange = async (value: string) => {
    setValidatingContact(true);
    if (value === '') {
      setContactValidation(contactValueDefaultError);
      setValidatingContact(false);
      return;
    }
    const repeatEmails = contacts.filter(item => item.email === value);

    if (repeatEmails.length > 0) {
      const contactCopy = { ...contactValueDefaultError };
      contactCopy.message = 'Email already exist in the contacts';
      setContactValidation(contactCopy);
      setValidatingContact(false);
      return;
    }

    setContactValidation({ ...contactValidation, message: '' });

    const statusDefault = 'active';
    const response = await requestEmailValidation(value, contactValidation);
    const { contactNewValue } = response;
    if (contactNewValue.contact !== null) {
      const copyContact = contactNewValue.contact;
      copyContact.is_default = IS_USER_ADMIN_MANAGER.includes(
        UserContext.profile.role,
      )
        ? true
        : contactNewValue.validEmail
        ? true
        : false;
      copyContact.validation_status =
        contactNewValue.contact.validation_status ?? statusDefault;
      if (!contactNewValue.contact.id) delete copyContact.id;
      setNewContact(copyContact);
    }

    setContactValidation({
      ...contactNewValue,
      validEmail: IS_USER_ADMIN_MANAGER.includes(UserContext.profile.role)
        ? true
        : contactNewValue.validEmail,
    });

    setValidatingContact(false);
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box justifyContent="end" display="flex">
          <IconButton onClick={closeModal} sx={{ fontSize: 17 }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Typography
          fontWeight={700}
          fontSize={17}
          textAlign="center"
          textTransform="capitalize"
        >
          Add Contact
        </Typography>

        <Box>
          <Typography marginTop={2} fontSize={15} textAlign="center">
            New Contact
          </Typography>

          <Box px={5}>
            <TextFormField
              nameInput="name"
              disabled={false}
              label="Name"
              error={false}
              value={newContact.name}
              onChange={(value: string) =>
                setNewContact({ ...newContact, name: value })
              }
              errorText=""
              placeholder="Name"
            />

            <Box mt={2}>
              <InputLabel
                htmlFor="email"
                sx={{
                  fontSize: 12,
                  fontWeight: '700',
                  color: 'rgba(0, 0, 0, 0.8)',
                  marginBottom: 1,
                }}
              >
                Email <span className="required-fields_dot">*</span>
              </InputLabel>
              <TextInputField
                value={newContact.email}
                name="email"
                disabled={validatingContact}
                helperText={contactValidation.message}
                error={contactValidation.validEmail === false}
                size="small"
                onBlur={e => handleEmailChange(e.target.value.trim())}
                onChange={e =>
                  setNewContact({
                    ...newContact,
                    email: e.target.value.trim(),
                  })
                }
                fullWidth
                placeholder="Email"
                id="email"
                InputProps={
                  validatingContact
                    ? {
                        endAdornment: (
                          <InputAdornment position="start">
                            <CircularProgress
                              sx={{ color: '#ABABAB' }}
                              size={16}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <>
                              {contactValidation.validEmail === true && (
                                <CheckCircleIcon fontSize="inherit" />
                              )}
                              {contactValidation.validEmail === 'unknown' && (
                                <HelpIcon fontSize="inherit" />
                              )}
                              {contactValidation.validEmail === false && (
                                <CancelIcon fontSize="inherit" />
                              )}
                            </>
                          </InputAdornment>
                        ),
                      }
                }
              />
            </Box>
          </Box>

          <FlexBox justifyContent="center" marginTop={4}>
            <MainButton
              onClick={() => closeModal()}
              disabled={validatingContact}
              variant="contained"
              sx={{
                backgroundColor: '#F0F0F0',
                color: 'black',
                mx: 2,
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
            >
              Close
            </MainButton>

            <MainButton
              variant="contained"
              disabled={!contactValidation.validEmail || validatingContact}
              onClick={() => updateNewContactAndAllowEdit(newContact)}
            >
              Save
            </MainButton>
          </FlexBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewContactModal;
