import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface UsersSiteTableProps {
  children: React.ReactNode;
  columnsNames: { first: string; second: string; third?: string };
}

const UsersSiteTable = ({ children, columnsNames }: UsersSiteTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columnsNames.third && (
              <>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {columnsNames.first}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {columnsNames.second}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {columnsNames.third}
                </TableCell>
              </>
            )}
            {!columnsNames.third && (
              <>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {columnsNames.first}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {columnsNames.second}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersSiteTable;
