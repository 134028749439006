import { useState, useEffect } from 'react';
import { ListFavoriteFilter } from '../models/User';
import { getListFavFilter } from '../helpers/user';

const useFavFilters = () => {
  const [filters, setFilters] = useState<ListFavoriteFilter[]>([]);
  const [loading, setLoading] = useState(false);

  const getFiltersList = () => {
    setLoading(true);
    getListFavFilter()
      .then(res => setFilters(res))
      .catch(err => setFilters([]))
      .finally(() => setLoading(false));
  };

  useEffect(getFiltersList, []);

  return { filters, getFiltersList, loading };
};

export default useFavFilters;
