/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import LeftColumns from '../../../components/Columns/LeftColumns';
import { FlexBoxBetween } from '../../../components/tableItems';
import {
  GlobalContextType,
  LoadingContextType,
  RequestContextType,
} from '../../../models/Context';
import LoadingContext from '../../../context/LoadingContext';
import {
  filtersObjTemplates,
  FiltersTemplateInterface,
} from '../../../models/Filters';
import { TemplateVisibilityColumns } from '../../../models/Templates';
import SitesAccordion from '../../../components/Filters/SitesAccordion';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import AutoCompleteSelect from '../../../components/Filters/AutoCompleteSelect';
import DateFilters from '../../../components/Filters/DateFilters';
import RequestContext from '../../../context/RequestContext';
import GeneralContext from '../../../context/GeneralActions';
import useOwners from '../../../customHooks/useOwners';
import useProjects from '../../../customHooks/useProjects';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import useAffinity from '../../../customHooks/useAffinity';
import useAudience from '../../../customHooks/useAudience';

interface Props {
  columnsVisibility: TemplateVisibilityColumns;
  setColumnsVisible: React.Dispatch<
    React.SetStateAction<TemplateVisibilityColumns>
  >;
  clean: boolean;
}

const status = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
  { id: 'in review', name: 'In Review' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'admin_removed', name: 'Admin Removed' },
];

const FiltersTemplates = ({
  columnsVisibility,
  setColumnsVisible,
  clean,
}: Props) => {
  const { affinities } = useAffinity();
  const { audiences } = useAudience();
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const { RequestsContext, setRequestsContext } = useContext(
    RequestContext,
  ) as RequestContextType;

  const [clearAll, setClearAll] = useState(false);

  const [filtersValues, setFiltersValues] =
    useState<FiltersTemplateInterface>(filtersObjTemplates);

  const { UserContext } = useContext(Context) as UserContextType;
  const { owners } = useOwners({ withFields: true });
  const { projects } = useProjects({ id: UserContext.id });

  const [render, setRender] = useState(0);

  const updateFiltersValue = (id: string, value: string) =>
    setFiltersValues({ ...filtersValues, [id]: { id, value } });

  const updateDates = (id: string, value: string | null) =>
    setFiltersValues({ ...filtersValues, [id]: { id, value } });

  const filtersArray = [
    {
      id: 1,
      visibilityId: 'site__name',
      title: 'Site',
      countUse: filtersValues.sites_ids.value
        ? filtersValues.sites_ids.value.split(',').length
        : 0,
      visible: columnsVisibility.site__name,
      body: (
        <SitesAccordion
          favFilters={0}
          projectsArr={projects}
          value={filtersValues.sites_ids.value}
          updateFiltersValue={updateFiltersValue}
          clearAll={clearAll}
        />
      ),
      disabled: !(projects.length > 0),
    },
    {
      id: 6,
      visibilityId: 'affinity',
      title: 'Affinity',
      countUse:
        filtersValues.affinity.value !== ''
          ? filtersValues.affinity.value.split(',').length
          : 0,
      visible: columnsVisibility.affinity,
      body: (
        <AutoCompleteSelect
          placeholder="Affinity"
          disabledInput={isLoadingContext.loading}
          options={affinities}
          clearAll={clearAll}
          updateFiltersValue={updateFiltersValue}
          value={affinities.filter(
            item =>
              filtersValues.affinity.value
                .split(',')
                .indexOf(item.id.toString()) !== -1,
          )}
          id={filtersValues.affinity.id}
        />
      ),
      disabled: !(affinities.length > 0),
    },
    {
      id: 7,
      visibilityId: 'audience',
      title: 'Audience',
      countUse:
        filtersValues.audience.value !== ''
          ? filtersValues.audience.value.split(',').length
          : 0,
      visible: columnsVisibility.audience,
      body: (
        <AutoCompleteSelect
          placeholder="Audience"
          disabledInput={isLoadingContext.loading}
          options={audiences}
          clearAll={clearAll}
          updateFiltersValue={updateFiltersValue}
          value={audiences.filter(
            item =>
              filtersValues.audience.value
                .split(',')
                .indexOf(item.id.toString()) !== -1,
          )}
          id={filtersValues.audience.id}
        />
      ),
      disabled: !(audiences.length > 0),
    },
    {
      id: 2,
      visibilityId: 'status',
      title: 'Status',
      visible: columnsVisibility.status,
      countUse:
        filtersValues.status.value !== ''
          ? filtersValues.status.value.split(',').length
          : 0,
      body: (
        <AutoCompleteSelect
          disabledInput={isLoadingContext.loading || clean}
          options={status}
          placeholder="Status"
          updateFiltersValue={updateFiltersValue}
          clearAll={clearAll}
          value={status.filter(item => {
            const id = item.id as string;
            return filtersValues.status.value.split(',').indexOf(id) !== -1;
          })}
          id={filtersValues.status.id}
        />
      ),
      disabled: clean,
    },
    {
      id: 3,
      visibilityId: 'owner__username',
      title: 'Author',
      countUse:
        filtersValues.owner.value !== ''
          ? filtersValues.owner.value.split(',').length
          : 0,
      visible: columnsVisibility.owner__username,
      body: (
        <AutoCompleteSelect
          placeholder="Author"
          disabledInput={isLoadingContext.loading}
          options={owners.map(item => {
            return { id: item.id, name: item.username };
          })}
          clearAll={clearAll}
          updateFiltersValue={updateFiltersValue}
          value={owners
            .map(item => {
              return { id: item.id, name: item.username };
            })
            .filter(item => {
              const id = item.id as number;
              return (
                filtersValues.owner.value.split(',').indexOf(id.toString()) !==
                -1
              );
            })}
          id={filtersValues.owner.id}
        />
      ),
      disabled: !(owners.length > 0),
    },
    {
      id: 4,
      visibilityId: 'created',
      title: 'Date Created',
      visible: columnsVisibility.created,
      countUse:
        filtersValues.min_added_date.value && filtersValues.max_added_date.value
          ? 2
          : filtersValues.max_added_date.value ||
            filtersValues.min_added_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          disableInput={isLoadingContext.loading}
          min_id={filtersValues.min_added_date.id}
          min_value={filtersValues.min_added_date.value}
          max_id={filtersValues.max_added_date.id}
          max_value={filtersValues.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 5,
      visibilityId: 'modified',
      title: 'Date Modified',
      visible: columnsVisibility.modified,
      countUse:
        filtersValues.min_mod_date.value && filtersValues.max_mod_date.value
          ? 2
          : filtersValues.max_mod_date.value || filtersValues.min_mod_date.value
          ? 1
          : 0,
      body: (
        <DateFilters
          disableInput={isLoadingContext.loading}
          min_id={filtersValues.min_mod_date.id}
          min_value={filtersValues.min_mod_date.value}
          max_id={filtersValues.max_mod_date.id}
          max_value={filtersValues.max_mod_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
  ];

  useEffect(() => {
    if (owners.length > 0 && RequestsContext.sites.length > 0)
      setRequestsContext({ ...RequestsContext, owners });
  }, [owners, RequestsContext.sites]);

  const createQuery = () => {
    const copyFilters = JSON.parse(JSON.stringify(filtersValues));
    copyFilters.sites_ids.value =
      copyFilters.sites_ids.value !== ''
        ? copyFilters.sites_ids.value.replaceAll(':', ',')
        : '';
    const ArrFilters: { id: string; value: string; disabled?: boolean }[] =
      Object.assign([], Object.values(copyFilters));
    const arrayElements = ArrFilters.filter(
      item => item.value && !item.disabled,
    );
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    setGlobalContext({
      ...GlobalContext,
      templates: {
        ...GlobalContext.templates,
        page: 1,
        query_filters: queryString.join(''),
      },
    });
  };

  useEffect(() => {
    if (render !== 0) createQuery();
  }, [filtersValues]);

  useEffect(() => {
    setFiltersValues({
      ...filtersValues,
      status: { ...filtersValues.status, disabled: clean },
    });
  }, [clean]);

  useEffect(() => {
    setRender(1);
  }, []);

  return (
    <LeftColumns>
      <FlexBoxBetween px={3}>
        <Typography fontWeight={900} fontSize={16}>
          Filters
        </Typography>
        <Typography
          onClick={() => {
            setFiltersValues(filtersObjTemplates);
            setClearAll(!clearAll);
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </FlexBoxBetween>
      <Box marginTop={2.4}>
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            visible={filter.visible}
            countUse={filter.countUse}
            body={filter.body}
            disabled={filter.disabled}
            visibilityId={filter.visibilityId}
            columnsVisibility={columnsVisibility}
            setColumnsVisible={setColumnsVisible}
          />
        ))}
      </Box>
    </LeftColumns>
  );
};

export default FiltersTemplates;
