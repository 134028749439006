import React from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Box, Typography, IconButton } from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../Accordion/Accordion';
import { FlexBox } from '../tableItems';

interface Props {
  title: string;
  visible?: boolean;
  body: React.ReactNode;
  disabled: boolean;
  visibilityId: string;
  columnsVisibility: any;
  setColumnsVisible?: React.Dispatch<React.SetStateAction<any>>;
  countUse?: number;
}

const FiltersAccordion = ({
  title,
  visible,
  body,
  disabled,
  visibilityId,
  columnsVisibility,
  setColumnsVisible,
  countUse,
}: Props) => {
  return (
    <Box position="relative">
      {visible && (
        <IconButton
          sx={{
            padding: 0.3,
            backgroundColor:
              countUse !== undefined && countUse > 0 ? '#CEFFBA' : '',
            position: 'absolute',
            zIndex: 100,
            top: 20,
            right: 20,
          }}
          onClick={e => {
            e.stopPropagation();
            if (setColumnsVisible)
              setColumnsVisible({
                ...columnsVisibility,
                [visibilityId]: false,
              });
          }}
        >
          <VisibilityOutlinedIcon
            sx={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.4)',
              '&:hover': { color: 'rgba(0, 0, 0, 0.7)' },
            }}
          />
        </IconButton>
      )}
      {visible !== undefined && !visible && (
        <IconButton
          sx={{
            padding: 0.3,
            position: 'absolute',
            backgroundColor: '#FDA7A7',
            zIndex: 100,
            top: 20,
            right: 20,
          }}
          onClick={e => {
            e.stopPropagation();
            if (setColumnsVisible)
              setColumnsVisible({
                ...columnsVisibility,
                [visibilityId]: true,
              });
          }}
        >
          <VisibilityOffOutlinedIcon
            sx={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
              '&:hover': { color: 'rgba(0, 0, 0, 0.4)' },
            }}
          />
        </IconButton>
      )}
      <Accordion>
        <AccordionSummary
          disabled={disabled}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <FlexBox>
              <Box
                sx={{
                  backgroundColor:
                    countUse !== undefined && countUse > 0 ? '#CEFFBA' : '',
                }}
              >
                <Typography
                  textTransform="capitalize"
                  fontSize={12}
                  color="#000000"
                  fontWeight={700}
                >
                  {title}
                </Typography>
              </Box>
              {countUse !== undefined && countUse > 0 && (
                <Typography
                  textTransform="capitalize"
                  fontSize={12}
                  color="#000000"
                  marginLeft={1.5}
                  fontWeight={400}
                  sx={{
                    backgroundColor: '#E2E2E2',
                    padding: '2px 4px',
                    borderRadius: '2px',
                  }}
                >
                  {countUse}
                </Typography>
              )}
            </FlexBox>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FiltersAccordion;
