import { Modal, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useState } from 'react';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';
import { SelectFormInput } from '../Forms/FormInputs';
import { fetchSiteCount } from '../../helpers/sites';
import Spinner from '../Loading/Spinner';
import { fetchAffinities, fetchAffinityCount } from '../../helpers/affinities';
import { fetchAudienceCount, fetchAudiences } from '../../helpers/audience';
import useSites from '../../customHooks/useSites';
import { BasicArray } from '../../models/General';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  deleteItem: (id: number, option: string, newId?: number) => Promise<void>;
  dataType: string;
  loadingDelete: boolean;
  impact?: string;
  id: number;
  option?: string;
  selectOptions?: { id: number; name: string }[];
}

const DeleteModal = ({
  open,
  closeModal,
  title,
  dataType,
  deleteItem,
  id,
  impact,
  loadingDelete,
  option,
  selectOptions,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [loading, setLoading] = useState(false);
  const { sites: sitesRequest } = useSites({
    minimal: true,
    userId: UserContext.id,
  });
  const [options, setOptions] = useState<{ id: number; name: string }[]>([]);
  const [sites, setSites] = useState<{ id: number; name: string }[]>([]);
  const [optionSelected, setOptionSelected] = useState(0);
  const [optionSelectedWhole, setOptionSelectedWhole] = useState<BasicArray>({
    name: '',
    id: 0,
  });
  const [count, setCount] = useState({
    linksCount: 0,
    templatesCount: 0,
    sitesCount: 0,
  });

  useEffect(() => {
    setSites(
      sitesRequest
        .filter(item => item.id !== id && item.status === 'active')
        .map(item => {
          return { id: item.id, name: item.name };
        }),
    );
  }, [sitesRequest]);

  const fetchCount = async () => {
    try {
      let resp;
      if (dataType.toLowerCase() === 'site') resp = await fetchSiteCount(id);
      if (dataType.toLowerCase() === 'affinity')
        resp = await fetchAffinityCount(id);
      if (dataType.toLowerCase() === 'audience')
        resp = await fetchAudienceCount(id);
      setCount({
        linksCount: resp ? resp.linksCount : 0,
        templatesCount: resp ? resp.templatesCount : 0,
        sitesCount: resp ? resp.sitesCount ?? 0 : 0,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getOptionsArray = async () => {
    try {
      if (dataType.toLowerCase() === 'audience') {
        const resp = await fetchAudiences(undefined, 'name');
        setOptions(resp);
        return;
      }
      const resp = await fetchAffinities(undefined, 'name');
      setOptions(resp);
    } catch (err) {
      setOptions([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    setOptionSelected(0);
    if (option === 'Delete') {
      if (
        dataType.toLowerCase() === 'audience' ||
        dataType.toLowerCase() === 'affinity'
      ) {
        Promise.all([fetchCount(), getOptionsArray()]);
      }
      if (dataType.toLowerCase() !== 'site' && !selectOptions)
        setLoading(false);
      if (dataType.toLowerCase() === 'site') fetchCount();
      if (selectOptions) fetchCount();
    } else setLoading(false);

    return () => {
      setOptionSelected(0);
      setOptionSelectedWhole({ id: 0, name: '' });
    };
  }, [title]);

  const disabledButton = () => {
    if (
      count.linksCount === 0 &&
      count.templatesCount === 0 &&
      count.sitesCount === 0
    )
      return false;
    if (
      (dataType.toLowerCase() === 'site' || selectOptions) &&
      optionSelected === 0 &&
      option === 'Delete'
    )
      return true;

    return false;
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Box justifyContent="end" display="flex">
                <IconButton
                  onClick={() => closeModal(false)}
                  sx={{ fontSize: 17 }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Typography
                fontWeight={700}
                fontSize={17}
                textAlign="center"
                textTransform="capitalize"
              >
                {option} {dataType}?{' '}
              </Typography>
              <Typography
                color="#373737"
                fontSize={14}
                textAlign="center"
                marginTop={1}
              >
                {option === 'Deactivate' &&
                  `${
                    impact
                      ? `This change will impact ${impact}`
                      : `Are you sure you want to deactivate ${title}?`
                  }`}
                {option === 'Activate' &&
                  `${
                    impact
                      ? `This change will impact ${impact}`
                      : `Are you sure you want to activate ${title}?`
                  }`}
                {option === '' ||
                  (option === 'Delete' && (
                    <>
                      {selectOptions &&
                        (count.sitesCount > 0 ||
                          count.templatesCount > 0 ||
                          count.linksCount > 0) && (
                          <>
                            <Typography fontSize={14}>
                              <Typography
                                fontWeight={600}
                                display="inline"
                                fontSize={14}
                              >
                                {title}
                              </Typography>{' '}
                              is currently assigned to{' '}
                              {count.templatesCount === 0
                                ? ''
                                : `${count.templatesCount} templates, `}{' '}
                              {count.linksCount === 0
                                ? ''
                                : `${count.linksCount} links`}{' '}
                              {count.sitesCount === 0
                                ? ''
                                : `${
                                    count.linksCount > 0 ||
                                    count.templatesCount > 0
                                      ? 'and'
                                      : ''
                                  } ${count.sitesCount} sites.`}{' '}
                              Before you can delete this{' '}
                              {dataType.toLowerCase()}, all templates, links and
                              sites must be reassigned.
                            </Typography>
                            <Box textAlign="left" marginTop={1}>
                              <Typography fontSize={14} textAlign="center">
                                Please select an {dataType} from the list to
                                re-assign all current templates and sites to.
                                This cannot be undone.
                              </Typography>
                              <SelectFormInput
                                required
                                clearIcon
                                options={
                                  dataType.toLowerCase() === 'audience' ||
                                  dataType.toLowerCase() === 'affinity'
                                    ? options
                                    : selectOptions
                                }
                                disabled={false}
                                placeholder={dataType}
                                value={optionSelectedWhole}
                                onChange={(value: BasicArray | null) => {
                                  setOptionSelectedWhole(
                                    value ?? { id: 0, name: '' },
                                  );
                                  setOptionSelected(
                                    value ? (value.id as number) : 0,
                                  );
                                }}
                              />
                            </Box>
                          </>
                        )}
                      {dataType.toLowerCase() !== 'site' && !selectOptions && (
                        <>
                          Are you sure you want to delete{' '}
                          <Typography
                            fontWeight={600}
                            display="inline"
                            fontSize={14}
                          >
                            {title}
                          </Typography>
                          ? This change{' '}
                          {impact ? `will impact ${impact} and` : ''} cannot be
                          undone.
                        </>
                      )}

                      {dataType.toLowerCase() === 'site' &&
                        (count.linksCount > 0 || count.templatesCount > 0) && (
                          <>
                            <Typography fontSize={14}>
                              <Typography
                                fontWeight={600}
                                display="inline"
                                fontSize={14}
                              >
                                {title}
                              </Typography>{' '}
                              has{' '}
                              {count.linksCount > 0
                                ? `${count.linksCount} links`
                                : ''}{' '}
                              {count.linksCount > 0 && count.templatesCount > 0
                                ? 'and'
                                : ''}{' '}
                              {count.templatesCount > 0
                                ? `${count.templatesCount} templates`
                                : ''}{' '}
                              currently assigned to it. Before you can delete
                              this site, all links and templates must be
                              reassigned.
                            </Typography>
                            <Box textAlign="left" marginTop={1}>
                              <Typography textAlign="center" fontSize={14}>
                                Please select a Site from the list so all the
                                current links and templates can be reassigned.
                              </Typography>
                              <SelectFormInput
                                clearIcon
                                required
                                options={sites}
                                loading={loading}
                                disabled={loading}
                                placeholder="Site"
                                value={
                                  sites.find(
                                    item => item.id === optionSelected,
                                  ) ?? {
                                    id: 0,
                                    name: '',
                                  }
                                }
                                onChange={(value: BasicArray | null) => {
                                  setOptionSelected(
                                    value ? (value.id as number) : 0,
                                  );
                                }}
                              />
                            </Box>
                          </>
                        )}
                      {(dataType.toLowerCase() === 'site' || selectOptions) &&
                        count.linksCount === 0 &&
                        count.sitesCount === 0 &&
                        count.templatesCount === 0 && (
                          <>
                            Are you sure you want to delete{' '}
                            <Typography
                              fontWeight={600}
                              display="inline"
                              fontSize={14}
                            >
                              {title}
                            </Typography>
                            ? This change cannot be undone.
                          </>
                        )}
                    </>
                  ))}
              </Typography>
              <Box marginTop={3.5}>
                <FlexBox marginTop={3} justifyContent="center">
                  <MainButton
                    onClick={() => closeModal(false)}
                    variant="contained"
                    sx={{
                      backgroundColor: '#F0F0F0',
                      color: 'black',
                      marginRight: 1,
                      '&:hover': {
                        backgroundColor: '#F2F2F2',
                      },
                    }}
                  >
                    Cancel
                  </MainButton>
                  <MainButton
                    variant="contained"
                    onClick={() => {
                      if (optionSelected !== 0)
                        deleteItem(id, dataType, optionSelected);
                      else deleteItem(id, dataType);
                    }}
                    loading={loadingDelete}
                    disabled={disabledButton()}
                    sx={{ marginLeft: 1 }}
                  >
                    {option === 'Delete' &&
                    (count.linksCount > 0 ||
                      count.sitesCount > 0 ||
                      count.templatesCount > 0)
                      ? 'Reassign and Delete'
                      : `${option}`}
                  </MainButton>
                </FlexBox>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
