import React from 'react';
import { InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SearchIcon from '@mui/icons-material/Search';
import { SearchTextField } from '../inputs';

interface Props {
  placeholder: string;
  filter: boolean;
  onChange?: (value: string) => void;
  onChangeNormal?: (query: string) => void;
  id: string;
  disabled: boolean;
  loadingInfo?: boolean;
  onKeyDown?: () => void;
  value?: string;
  onBlur?: (query: string) => void;
}

const SearchInput = ({
  placeholder,
  value,
  filter,
  onChange,
  id,
  disabled,
  onChangeNormal,
  loadingInfo,
  onKeyDown,
  onBlur,
}: Props) => {
  const searchFunction = onChange ? debounce(onChange, 900) : () => {};

  return (
    <SearchTextField
      disabled={disabled}
      value={value}
      onKeyDown={e => {
        if (e.key === 'Enter' && onKeyDown) {
          onKeyDown();
        }
      }}
      onBlur={e => {
        if (onBlur) onBlur(e.target.value);
      }}
      onChange={e =>
        onChangeNormal
          ? onChangeNormal(e.target.value)
          : searchFunction(e.target.value)
      }
      InputProps={
        filter
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <TuneRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loadingInfo && (
                    <CircularProgress sx={{ color: '#ABABAB' }} size={16} />
                  )}
                </InputAdornment>
              ),
            }
      }
      size="small"
      fullWidth
      type="text"
      placeholder={placeholder}
      id={id}
    />
  );
};

export default SearchInput;
