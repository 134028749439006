import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value }: Props) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
