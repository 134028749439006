import { useContext, useState, useEffect } from 'react';
import { ProfileUser, UserResponse } from '../models/User';
import { getAllUsers, getUsersProfiles } from '../helpers/user';
import GeneralContext from '../context/GeneralActions';
import { GlobalContextType } from '../models/Context';

const useUsers = () => {
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const [usersArray, setUsersArray] = useState<UserResponse[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [usersProfiles, setUsersProfiles] = useState<ProfileUser[]>([]);

  const refreshUsers = () => {
    setLoading(true);
    getAllUsers(
      GlobalContext.users.page,
      GlobalContext.users.page_size,
      GlobalContext.users.search,
      GlobalContext.users.order,
    )
      .then(res => {
        setUsersArray(res.results);
        setCount(res.count);
      })
      .catch(err => {
        setError(true);
        setLoading(false);
      });
  };

  const refreshProfiles = (ids: number[]) => {
    setLoadingProfile(true);
    getUsersProfiles(ids)
      .then(resp => setUsersProfiles(resp))
      .catch(err => setUsersProfiles([]))
      .finally(() => {
        setLoadingProfile(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (usersArray.length === 0) {
      setUsersProfiles([]);
      return;
    }
    const ids = usersArray.map(user => user.id);
    refreshProfiles(ids);
  }, [usersArray]);

  return {
    usersArray,
    count,
    loading,
    error,
    refreshUsers,
    loadingProfile,
    usersProfiles,
  };
};

export default useUsers;
