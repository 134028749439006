export const approveReasons = [
  { label: 'Domain Quality', value: 'Domain Quality' },
  { label: 'Domain Relevance', value: 'Domain Relevance' },
  {
    label: 'Page Quality',
    value: 'Page Quality',
  },
  {
    label: 'Page Relevance',
    value: 'Page Relevance',
  },
  {
    label: 'Anchor Text Relevance',
    value: 'Anchor Text Relevance',
  },
  {
    label: 'Domain Authority',
    value: 'Domain Authority',
  },
  {
    label: 'Gov (Domain Authority)',
    value: 'Gov (Domain Authority)',
  },
  {
    label: 'Edu (Domain Authority)',
    value: 'Edu (Domain Authority)',
  },
  {
    label: 'In-content Link',
    value: 'In-content Link',
  },
  {
    label: 'Custom Content Link',
    value: 'Custom Content Link',
  },
];

export const rejectedReasons = [
  { label: 'Low Quality', value: 'Low Quality' },
  { label: 'Low Relevance', value: 'Low Relevance' },
  {
    label: 'DA below Project Restriction',
    value: 'DA below Project Restriction',
  },
  {
    label: 'Website Scraped our Content',
    value: 'Website Scraped our Content',
  },
  {
    label: 'Active Link on Page prior to Outreach',
    value: 'Active Link on Page prior to Outreach',
  },
  {
    label: 'Link doesn’t appear on page',
    value: 'Link doesn’t appear on page',
  },
  {
    label: 'Duplicate Link Claim',
    value: 'Duplicate Link Claim',
  },
  {
    label: 'Misalignment between page and current strategy',
    value: 'Misalignment between page and current strategy',
  },
];

export const LOW_QUALITY_DETAIL = [
  {
    label: 'Religious/political conspiracies',
    value: 'Religious/political conspiracies',
  },
  {
    label: 'Excessive ads/disruptive pop-ups',
    value: 'Excessive ads/disruptive pop-ups',
  },
  {
    label: 'Thin content',
    value: 'Thin content',
  },
  {
    label: 'Inappropriate content',
    value: 'Inappropriate content',
  },
  {
    label: 'Grammar/spelling errors',
    value: 'Grammar/spelling errors',
  },
  {
    label: 'Inaccurate content',
    value: 'Inaccurate content',
  },
  {
    label: 'Link Farm',
    value: 'Link Farm',
  },
];

export const LOW_REVELANCE_DETAIL = [
  {
    label: 'Irrelevant link “neighborhood”',
    value: 'Irrelevant link “neighborhood”',
  },
  {
    label: 'Built on irrelevant page',
    value: 'Built on irrelevant page',
  },
  {
    label: 'Built on irrelevant domain',
    value: 'Built on irrelevant domain',
  },
];

export const DA_BELOW_DETAIL = [
  {
    label: 'Outside of Grace Period',
    value: 'Outside of Grace Period',
  },
];

const optionsReasons = {
  'Low Quality': LOW_QUALITY_DETAIL,
  'Low Relevance': LOW_REVELANCE_DETAIL,
  'DA below Project Restriction': DA_BELOW_DETAIL,
  'Website Scraped our Content': [],
  'Active Link on Page prior to Outreach': [],
  'Link doesn’t appear on page': [],
  'Duplicate Link Claim': [],
  'Misalignment between page and current strategy': [],
};

export const OPTIONS_SELECT_REJECTED_CLAIM = (option: string) => {
  if (option === '') return [];
  const key = option as keyof typeof optionsReasons;
  return optionsReasons[key];
};
