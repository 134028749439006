/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  FormControlLabel,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MainButton } from '../../../buttons';
import { FlexBox } from '../../../tableItems';
import { DefaultCheckbox, TextInputField } from '../../../inputs';
import { OPTIONS_SELECT, SPECIFIC_USER } from './ConstantsClosedStatus';
import ButtonGroupSelect from './ButtonGroupSelect';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 4,
};

interface ContactChangesProps {
  contactIds: number[];
  contactNamesEmails: string[];
  closeType: string;
  closeReason: string;
}

interface LinkChangesProps {
  closeType: string;
  closeReason: string;
  relevance: boolean;
}

interface CloseLinkModalProps {
  open: boolean;
  closeModal: () => void;
  userRole: string;
  disableContactsModal: boolean;
  closeConfirmLink: (linkChanges: LinkChangesProps) => void;
  closeLinkOpenContact: (linkChanges: LinkChangesProps) => void;
  goBackToOptions: () => void;
  updateLinkClosed: (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => void;
  updateLinkClosedAndSave: (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => void;
  contactChanges?: ContactChangesProps;
  note: string;
  updateNote: (newNote: string) => void;
  disabledSave: boolean;
}

const CloseLinkModal = ({
  open,
  closeModal,
  userRole,
  disableContactsModal,
  closeConfirmLink,
  closeLinkOpenContact,
  goBackToOptions,
  updateLinkClosed,
  contactChanges,
  note,
  updateNote,
  updateLinkClosedAndSave,
  disabledSave,
}: CloseLinkModalProps) => {
  const [step, setStep] = useState(0);
  const [closeTypeSelected, setCloseTypeSelected] = useState('');
  const [closeReasonSelected, setCloseReasonSelected] = useState('');
  const [relevance, setRelevance] = useState(false);

  useEffect(() => {
    return () => {
      setCloseTypeSelected('');
      setStep(0);
    };
  }, []);

  useEffect(() => {
    setCloseReasonSelected('');
  }, [closeTypeSelected]);

  const changeValueTypeSelected = (value: string) =>
    setCloseTypeSelected(value);

  const changeValueReasonSelected = (value: string) =>
    setCloseReasonSelected(value);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box
            justifyContent={step > 0 ? 'space-between' : 'end'}
            display="flex"
          >
            {step > 0 && (
              <IconButton onClick={() => setStep(0)} sx={{ fontSize: 17 }}>
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            )}
            <IconButton onClick={closeModal} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Close Link
          </Typography>

          {step === 0 && (
            <>
              <ButtonGroupSelect
                value={closeTypeSelected}
                type="link"
                changeValue={changeValueTypeSelected}
                reasonValue={closeReasonSelected}
                changeReasonValue={changeValueReasonSelected}
              />

              {SPECIFIC_USER.includes(userRole) && (
                <Box marginTop={2} px={4}>
                  <FormControlLabel
                    label="Quality/Relevance Close"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 14,
                        color: '#000000',
                      },
                    }}
                    control={
                      <DefaultCheckbox
                        disabled={false}
                        sx={{ ml: 1 }}
                        size="medium"
                        checked={relevance}
                        onClick={() => setRelevance(!relevance)}
                      />
                    }
                  />
                </Box>
              )}
            </>
          )}

          {step === 2 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                Confirm all changes or go back and change them
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#F7F7F7',
                  borderRadius: 2,
                  padding: 0.8,
                }}
              >
                {contactChanges && (
                  <Box marginTop={2} px={3}>
                    <Typography fontWeight={500} fontSize={13} textAlign="left">
                      Contact Close
                    </Typography>
                    <Typography
                      marginTop={1}
                      fontSize={13}
                      sx={{ wordBreak: 'break-word' }}
                    >
                      Contacts: {contactChanges.contactNamesEmails.join(',')}
                    </Typography>
                    <Typography
                      marginTop={1}
                      fontSize={13}
                      textTransform="capitalize"
                    >
                      Option: {contactChanges.closeType}
                    </Typography>
                    <Typography marginTop={1} fontSize={13}>
                      Reason:{' '}
                      {OPTIONS_SELECT(contactChanges.closeType).find(
                        item => item.value === contactChanges.closeReason,
                      )?.label ?? ''}
                    </Typography>
                  </Box>
                )}
                <Box marginTop={2} px={3}>
                  <Typography fontWeight={500} fontSize={13} textAlign="left">
                    Link Close
                  </Typography>
                  <Typography
                    marginTop={1}
                    fontSize={13}
                    textTransform="capitalize"
                  >
                    Option: {closeTypeSelected}
                  </Typography>
                  <Typography marginTop={1} fontSize={13}>
                    Reason:{' '}
                    {OPTIONS_SELECT(closeTypeSelected).find(
                      item => item.value === closeReasonSelected,
                    )?.label ?? ''}
                  </Typography>
                  {relevance && (
                    <Typography marginTop={1} fontSize={13}>
                      Quality/Relevance Close: True
                    </Typography>
                  )}
                </Box>
              </Box>
              <Typography fontSize={15} textAlign="center" mt={2} mb={1}>
                Insert or change current note
              </Typography>
              <TextInputField
                value={note}
                name="status_note"
                size="small"
                rows={6}
                multiline
                onChange={e => updateNote(e.target.value)}
                fullWidth
                placeholder="Note"
                id="status_note"
              />
              <FlexBox justifyContent="center" marginTop={3}>
                <MainButton
                  onClick={() => setStep(0)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
                <MainButton
                  variant="contained"
                  sx={{
                    marginX: 1.5,
                    background: '#BAF372',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#BAF372',
                    },
                  }}
                  disabled={note === ''}
                  onClick={() => {
                    closeConfirmLink({
                      relevance,
                      closeType: closeTypeSelected,
                      closeReason: closeReasonSelected,
                    });
                    updateLinkClosed(
                      closeReasonSelected,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                      `${
                        contactChanges
                          ? `Contact Close\nContacts: ${contactChanges.contactNamesEmails.join(
                              ',',
                            )}\nOption: ${contactChanges.closeType}\nReason: ${
                              contactChanges.closeReason
                            }\n\n`
                          : ''
                      }Link Close\nOption: ${closeTypeSelected}\nReason: ${closeReasonSelected}\n${
                        relevance ? 'Quality/Relevance Close: True\n\n' : '\n'
                      }Note: ${note}
                      `,
                      relevance,
                    );
                  }}
                >
                  Confirm
                </MainButton>

                <MainButton
                  variant="contained"
                  disabled={note === '' || disabledSave}
                  sx={{
                    backgroundColor: '#84D0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#84D0F0',
                    },
                  }}
                  onClick={() => {
                    closeModal();
                    updateLinkClosedAndSave(
                      closeReasonSelected,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                      `${
                        contactChanges
                          ? `Contact Close\nContacts: ${contactChanges.contactNamesEmails.join(
                              ',',
                            )}\nOption: ${contactChanges.closeType}\nReason: ${
                              contactChanges.closeReason
                            }\n\n`
                          : ''
                      }Link Close\nOption: ${closeTypeSelected}\nReason: ${closeReasonSelected}\n${
                        relevance ? 'Quality/Relevance Close: True\n\n' : '\n'
                      }Note: ${note}
                      `,
                      relevance,
                    );
                  }}
                >
                  Confirm & Save
                </MainButton>
              </FlexBox>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                Would you like to close Contacts attached to this Link?
              </Typography>

              <FlexBox justifyContent="center" marginTop={3}>
                <MainButton
                  onClick={() => setStep(0)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
                <MainButton
                  onClick={() => setStep(2)}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    marginX: 1.5,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  No
                </MainButton>
                <MainButton
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    closeLinkOpenContact({
                      relevance,
                      closeType: closeTypeSelected,
                      closeReason: closeReasonSelected,
                    });
                    updateLinkClosed(
                      closeReasonSelected,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                      `${
                        contactChanges
                          ? `Contact Close\nContacts: ${contactChanges.contactNamesEmails.join(
                              ',',
                            )}\nOption: ${contactChanges.closeType}\nReason: ${
                              contactChanges.closeReason
                            }\n\n`
                          : ''
                      }Link Close\nOption: ${closeTypeSelected}\nReason: ${closeReasonSelected}\n${
                        relevance ? 'Quality/Relevance Close: True\n\n' : '\n'
                      }Note: ${note}
                      `,
                      relevance,
                    );
                  }}
                >
                  Yes
                </MainButton>
              </FlexBox>
            </Box>
          )}
          <Box marginTop={3.5}>
            <FlexBox justifyContent="end">
              {/* {step === 0 && !disableContactsModal && (
                <MainButton
                  onClick={goBackToOptions}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
              )} */}
              {step === 0 && (
                <MainButton
                  variant="contained"
                  onClick={() => {
                    if (!disableContactsModal) setStep(1);
                    if (disableContactsModal) setStep(2);
                  }}
                  disabled={
                    closeTypeSelected === '' || closeReasonSelected === ''
                  }
                  sx={{ marginLeft: 1 }}
                >
                  Next
                </MainButton>
              )}
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default CloseLinkModal;
