export type SeverityType = 'error' | 'success';

export const tabs = [
  { id: 0, label: 'Details' },
  { id: 1, label: 'Notes' },
  { id: 2, label: 'History' },
];

export const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Admin Removed', value: 'admin_removed' },
];

export interface MenuOptionsDropdown {
  id: number;
  title: string;
  click: (id: number, name?: string) => void;
  delete?: boolean;
}

export type Order = 'asc' | 'desc';

export interface BasicArray {
  id: number | string;
  name: string;
}

export interface MessagesInt {
  message: string;
  severity: SeverityType;
}

export const LinkStatusColors = {
  active: 'green',
  approved: 'orange',
  closed: 'red',
  pending: 'blue',
  open: 'orange',
  emailed_bounced: 'orange',
  contact_not_found: 'orange',
  to_do: 'orange',
  deleted: 'gray',
  not_found: 'gray',
  link_removed: 'gray',
  curl_error: 'gray',
};

export const getStatusColor = (id: string) => {
  const key = id as keyof typeof LinkStatusColors;
  if (!LinkStatusColors[key]) return 'gray';
  return LinkStatusColors[key];
};
