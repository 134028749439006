import { Tabs, Tab } from '@mui/material';
import React from 'react';

interface Props {
  valueTab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  tabs: { label: string; id: number; disabled?: boolean }[];
  center: boolean;
}

const TabParent = ({ valueTab, setTab, tabs, center }: Props) => {
  return (
    <Tabs
      value={valueTab}
      textColor="primary"
      onChange={(e, value) => setTab(value)}
      TabIndicatorProps={{
        style: { backgroundColor: '#000000' },
      }}
      centered={center}
    >
      {tabs.map(item => (
        <Tab
          key={item.id}
          label={item.label}
          disabled={item.disabled}
          className={
            valueTab === item.id ? 'tabs-tab_selected ' : 'tabs-tab_notselected'
          }
        />
      ))}
    </Tabs>
  );
};

export default TabParent;
