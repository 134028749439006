import { useState, useEffect } from 'react';
import { BasicArray } from '../models/General';
import { fetchAffinities } from '../helpers/affinities';

const useAffinity = () => {
  const [affinities, setAffinities] = useState<BasicArray[]>([]);
  const [loading, setLoading] = useState(false);

  const getAffinitiesList = () => {
    setLoading(true);
    fetchAffinities()
      .then(res =>
        setAffinities(
          res.map((item: { id: number; name: string }) => {
            return { id: item.id, name: item.name };
          }),
        ),
      )
      .catch(err => setAffinities([]))
      .finally(() => setLoading(false));
  };

  useEffect(getAffinitiesList, []);

  return { affinities, getAffinitiesList, loading };
};

export default useAffinity;
