import { GenericAbortSignal } from 'axios';
import { SiteCreateInt } from '../models/Forms';
import { HistoryReq, NotesReq } from '../models/Notes';
import { MinimalAudAffSites, SitesRequestV2 } from '../models/Sites';
import APIClient from '../services/APIClient';

export const fetchSites = async (idOwner?: number, minimal?: boolean) => {
  const clientAPI = new APIClient();
  const sitesResponse = await clientAPI.client.get<SitesRequestV2[]>(
    `/v2/sites/${
      idOwner
        ? `?owner=${idOwner}${minimal ? '&minimal=true' : ''}`
        : `${minimal ? '?minimal=true' : ''}`
    }`,
  );
  return sitesResponse.data;
};

export const fetchSitesAudiencesAffinities = async (
  id: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const sitesResponse = await clientAPI.client.get<SitesRequestV2>(
    `/v2/sites/${id}/?minimal=true&aa=true`,
    { signal },
  );
  return sitesResponse.data;
};

export const fetchSitesAudiencesAffinitiesMinimal = async (
  sites_ids: string,
) => {
  const clientAPI = new APIClient();
  const sitesResponse = await clientAPI.client.get<MinimalAudAffSites[]>(
    `/v2/sites/bulk-with-aa/?sites=${sites_ids}`,
  );
  return sitesResponse.data;
};

export const fetchSiteCount = async (id: number) => {
  const clientAPI = new APIClient();
  const sitesResponse = await clientAPI.client.get<{
    linksCount: number;
    templatesCount: number;
    sitesCount?: number;
  }>(`/v2/sites/${id}/count-associated/`);
  return sitesResponse.data;
};

export const fetchSingleSite = async (id: number) => {
  const clientAPI = new APIClient();
  const sitesResponse = await clientAPI.client.get<SitesRequestV2>(
    `/v2/sites/${id}/`,
  );
  return sitesResponse.data;
};

export const addSite = async (obj: SiteCreateInt) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.post<SitesRequestV2>(
    `/v2/sites/`,
    obj,
  );
  return siteResponse.data;
};

export const updateSite = async (obj: SiteCreateInt, id: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.put<SitesRequestV2>(
    `/v2/sites/${id}/`,
    obj,
  );
  return siteResponse.data;
};

export const updateSiteStatus = async (
  obj: { status: string; text_note: string },
  id: number,
) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.put<SitesRequestV2>(
    `/v2/sites/${id}/`,
    obj,
  );
  return siteResponse.data;
};

export const deleteSite = async (id: number, newId: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.delete(
    `/v2/sites/${id}/${newId === 0 ? '' : `?site_id=${newId}`}`,
  );
  return siteResponse.data;
};

export const deactivateSite = async (id: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.post(
    `/v2/sites/${id}/change-status/`,
  );
  return siteResponse.data;
};

export const fetchSiteNotes = async (id: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.get<NotesReq[]>(
    `/v2/sites/${id}/list-general-note/?ordering=-created`,
  );
  return siteResponse.data;
};

export const addNoteSite = async (obj: { text_note: string }, id: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.post(
    `/v2/sites/${id}/add-note/`,
    obj,
  );
  return siteResponse.data;
};

export const fetchSiteHistory = async (id: number) => {
  const clientAPI = new APIClient();
  const siteResponse = await clientAPI.client.get<HistoryReq[]>(
    `/v2/sites/${id}/list-status-note/?ordering=-created`,
  );
  return siteResponse.data;
};
