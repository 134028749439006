import { useState, useEffect, useContext } from 'react';
import { countBuildOutreach } from '../helpers/links';
import Context from '../context/UserContext';
import { UserContextType } from '../models/User';

const baseResult = {
  send_events: 0,
  open_events: 0,
  open_rate: 0,
};

const useBuildOutreach = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [loading, setLoading] = useState(false);
  const [resultsBuild, setResultsBuild] = useState(baseResult);

  const getCounter = () => {
    setLoading(true);
    countBuildOutreach(
      UserContext.profile.role === 'linkbuilder' ? UserContext.id : undefined,
    )
      .then(res => setResultsBuild(res))
      .catch(err => setResultsBuild(baseResult))
      .finally(() => setLoading(false));
  };

  useEffect(getCounter, []);

  return { resultsBuild, loading };
};

export default useBuildOutreach;
