export const onlyNumbers = (value: string) => value.replace(/[a-zA-z]+/g, '');

export const onlyLetters = (value: string) =>
  value.replace(/[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, '');

export const isURL = (value: string) => {
  const urlRegex =
    '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const url = new RegExp(urlRegex, 'i');
  return url.test(value);
};

export const getLengthNumbers = (value: string) =>
  value.replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, '').length;

export const validateLengthInputs = (
  min: number,
  id: number,
  value: string,
  arrErrors: number[],
  max: number = 100,
) => {
  if (value !== '') {
    const isThere = arrErrors.indexOf(id);
    const copyArr = [...arrErrors];
    if (getLengthNumbers(value) >= min && getLengthNumbers(value) <= max) {
      if (isThere !== -1) {
        copyArr.splice(isThere, 1);
      }
      return copyArr;
    }
    if (isThere === -1) {
      copyArr.push(id);
    }
    return copyArr;
  }
  return arrErrors;
};

export const validateUrlInputs = (
  value: string,
  id: number,
  arrErros: number[],
) => {
  if (value !== '') {
    const isThere = arrErros.indexOf(id);
    const copyArr = [...arrErros];
    if (isURL(value)) {
      if (isThere !== -1) {
        copyArr.splice(isThere, 1);
        return copyArr;
      }
    } else if (isThere === -1) copyArr.push(id);
    return copyArr;
  }
  return arrErros;
};
