/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Box,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../../../../components/tableItems';
import { approveReasons } from './ClaimConstants';
import { DefaultCheckbox } from '../../../../components/inputs';
import { MainButton } from '../../../../components/buttons';
import { ClaimPostPutInt } from '../../../../models/Links';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface ClaimApproveModalProps {
  openModal: boolean;
  closeModal: () => void;
  linkOwner: string;
  linkURL: string;
  claimObject: ClaimPostPutInt;
  handleSubmit: (editObject?: ClaimPostPutInt, id?: number) => Promise<void>;
  loading: boolean;
  claimId?: number;
}

const ClaimApproveModal: React.FC<ClaimApproveModalProps> = ({
  openModal,
  closeModal,
  linkOwner,
  linkURL,
  claimObject,
  handleSubmit,
  loading,
  claimId,
}) => {
  const [selectedDetail, setSelectedDetail] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    return () => {
      setSelectedDetail([]);
    };
  }, [openModal]);

  return (
    <Modal open={openModal}>
      <Box sx={style}>
        <Box justifyContent="end" display="flex">
          <IconButton
            onClick={closeModal}
            sx={{ fontSize: 17 }}
            disabled={loading}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Typography
          fontWeight={700}
          fontSize={17}
          textAlign="center"
          textTransform="capitalize"
        >
          Approved Claim
        </Typography>
        <Box px={3} mt={2}>
          <FlexBox>
            <Typography fontSize={14} fontWeight={500}>
              Link Owner:
            </Typography>
            <Typography fontSize={14} ml={0.5} textTransform="capitalize">
              {linkOwner}
            </Typography>
          </FlexBox>
          <Typography fontSize={14} fontWeight={500} mt={2}>
            Link URL:
          </Typography>
          <Typography fontSize={14} mt={0.5}>
            {linkURL}
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography
            fontWeight={700}
            fontSize={16}
            marginBottom={1}
            textAlign="center"
            textTransform="capitalize"
          >
            Approved Reason
          </Typography>

          <Box marginTop={2} px={4}>
            <Autocomplete
              multiple
              value={selectedDetail}
              disableCloseOnSelect
              disabled={false}
              clearIcon={false}
              size="small"
              limitTags={3}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '8px 12px',
                  '&:focus fieldset': {
                    borderColor: '#D7D7D7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ABABAB',
                  },
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: '2px solid #D7D7D7',
                },
                '& input': {
                  padding: 0,
                  fontSize: 13,
                },
                '& .MuiAutocomplete-tag': {
                  fontSize: 13,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: 120,
                  borderRadius: '3px',
                },
              }}
              options={approveReasons}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <DefaultCheckbox
                    size="small"
                    checked={selected}
                    sx={{ padding: 0, marginRight: 1.3 }}
                  />
                  {option.label}
                </li>
              )}
              onChange={(e, val) => {
                setSelectedDetail(val);
              }}
              popupIcon={<KeyboardArrowDownIcon />}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField {...params} placeholder="Reason Details" />
              )}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={2.3} mb={2}>
          <MainButton
            variant="contained"
            disabled={selectedDetail.length === 0 || loading}
            loading={loading}
            sx={{
              backgroundColor: '#84D0F0',
              color: 'black',
              '&:hover': {
                backgroundColor: '#84D0F0',
              },
            }}
            onClick={() => {
              const newObj = { ...claimObject };
              newObj.status = 'approved';
              newObj.approve_reason =
                selectedDetail.length > 0
                  ? selectedDetail.map(item => item.label).join(',')
                  : '';
              handleSubmit(newObj, claimId);
            }}
          >
            Confirm & Save
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ClaimApproveModal;
