import { Box } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import {
  createCategory,
  getSingleCategory,
  updateCategory,
} from '../../../helpers/category';
import { CategoryCreateInt } from '../../../models/Category';
import { SeverityType, statusOptions, tabs } from '../../../models/General';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import FeedbackMessage from '../../FeedbackMessage';
import Spinner from '../../Loading/Spinner';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import FormNotes from '../FormNotes';
import BoxAddSettings from '../BoxAddSettings';
import { AutoCompleteInput, BasicSelect, TextFormField } from '../FormInputs';
import StatusModal from '../StatusModal';
import FormStatus from '../FormStatus';
import { PropsCategoryForm } from '../../../models/Forms';
import useNotes from '../../../customHooks/useNotes';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { ROLES_CATEGORIES } from '../../../constants/user';

const AddCategory = ({
  setOpenDrawer,
  clientsOptions,
  clientValue,
  type,
  createSuccess,
  categoryEdit,
}: PropsCategoryForm) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [categoryForm, setCategoryForm] = useState({
    name: '',
    client: clientValue || 0,
    status: '',
    text_note: '',
    general_category_note: '',
  });
  const [tabDrawer, setTabDrawer] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: categoryEdit ?? 0,
    type: 'category',
  });
  const { history } = useHistoryTab({
    id: categoryEdit ?? 0,
    type: 'category',
  });

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [inputsErrors, setInputsErrors] = useState<number[]>([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [originalState, setOriginalState] = useState('');
  const [messagesProps, setMessagesProps] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'error',
  });
  const [disableButton, setDisableButton] = useState(true);

  const canEdit =
    type === 'edit'
      ? !sendBackObject(UserContext.profile.role, ROLES_CATEGORIES).edit
      : !sendBackObject(UserContext.profile.role, ROLES_CATEGORIES).add;

  const inputsArr = [
    {
      id: 0,
      value: categoryForm.name,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="name"
          label="Name"
          error={false}
          value={categoryForm.name}
          errorText=""
          placeholder="Name"
          onChange={(value: string) =>
            setCategoryForm({ ...categoryForm, name: value })
          }
        />
      ),
    },
    {
      id: 1,
      value: categoryForm.client === 0 ? '' : 'valid',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <AutoCompleteInput
          required
          options={clientsOptions}
          disabled={canEdit}
          placeholder="Client"
          value={
            clientsOptions.find(
              item => parseInt(item.value, 10) === categoryForm.client,
            ) ?? { label: '', value: '' }
          }
          onChange={(value: { label: string; value: string } | null) => {
            setCategoryForm({
              ...categoryForm,
              client: value ? parseInt(value.value, 10) : 0,
            });
          }}
        />
      ),
    },
  ];

  const updateStatus = (note: string) => {
    const newStatus = categoryForm.status === 'active' ? 'inactive' : 'active';
    setCategoryForm({ ...categoryForm, text_note: note, status: newStatus });
    setOpenModal(false);
  };

  if (type === 'edit') {
    inputsArr.splice(1, 0, {
      id: 3,
      value: categoryForm.status,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <BasicSelect
          label="Status"
          required
          nameInput="status"
          disabled={canEdit}
          value={categoryForm.status}
          options={statusOptions}
          onChange={(value: string) => {
            if (value !== originalState) setOpenModal(true);
            else {
              setCategoryForm({
                ...categoryForm,
                status: value,
                text_note: '',
              });
            }
          }}
        />
      ),
    });
  }

  const saveCategory = async (obj: CategoryCreateInt) => {
    setLoadingCreate(true);
    try {
      if (type === 'add') await createCategory(obj);
      if (type === 'edit') await updateCategory(obj, categoryEdit ?? 0);
      setOpenDrawer(false);
      createSuccess(
        type === 'add'
          ? 'Category created succesfully'
          : 'Category updated succesfully',
        'category',
      );
    } catch (err) {
      const error = err as any;
      if (error.data.name) {
        setMessagesProps({
          severity: 'error',
          message: error.data.name[0],
        });
      } else {
        setMessagesProps({
          severity: 'error',
          message: 'There was an error, please try again',
        });
      }
      setOpenMessage(true);
    } finally {
      setLoadingCreate(false);
    }
  };

  const validationAll = () => {
    const purge = inputsArr
      .filter(item => item.value !== '' && item.validate)
      .map(item => item.validation)
      .flat();

    if (purge.length > 0) {
      setInputsErrors(purge);
      return;
    }

    const obj = { ...categoryForm };
    if (type === 'add') {
      obj.status = 'active';
      obj.text_note = 'New Category';
    }
    saveCategory(obj);
  };

  useEffect(() => {
    const errors = inputsArr.filter(
      item => item.value.toString().trim() === '' && item.required,
    );
    if (errors.length > 0) setDisableButton(true);
    else setDisableButton(false);
  }, [categoryForm]);

  const getInfoCategory = async () => {
    try {
      const resp = await getSingleCategory(categoryEdit ?? 0);
      setCategoryForm({
        ...categoryForm,
        name: resp.name,
        status: resp.status,
        general_category_note: resp.general_category_note ?? '',
        client: resp.clients[0],
      });
      setOriginalState(resp.status);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      setLoading(true);
      getInfoCategory();
    }
  }, []);

  return (
    <>
      <HeaderDrawer
        title={type === 'add' ? 'Add Category' : 'Edit Category'}
        threeButtons={false}
        closeDrawer={setOpenDrawer}
        disabled={
          !(disableButton === false && inputsErrors.length === 0) || canEdit
        }
        loading={loadingCreate}
        saveFunction={validationAll}
      />
      {type === 'edit' && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginTop: 1.3,
            marginLeft: 3,
            marginRight: 4,
          }}
        >
          <TabParent
            valueTab={tabDrawer}
            setTab={setTabDrawer}
            tabs={tabs}
            center
          />
        </Box>
      )}
      {type === 'add' && (
        <BoxAddSettings title="Category Info">
          <div className="form-grids">
            {inputsArr.map(input => (
              <div
                key={input.id}
                className={`mb-2 ${input.fullSpace ? 'whole-grid_three' : ''}`}
              >
                {input.body}
              </div>
            ))}
          </div>
        </BoxAddSettings>
      )}
      {type === 'edit' && (
        <>
          <TabPanel value={tabDrawer} index={0}>
            {loading && <Spinner />}
            {!loading && (
              <BoxAddSettings title="Category Info">
                <div className="form-grids">
                  {inputsArr.map(input => (
                    <div
                      key={input.id}
                      className={`mb-2 ${
                        input.fullSpace ? 'whole-grid_three' : ''
                      }`}
                    >
                      {input.body}
                    </div>
                  ))}
                </div>
              </BoxAddSettings>
            )}
          </TabPanel>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="category"
                notes={notes}
                id={categoryEdit ?? 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={2}>
            <BoxAddSettings>
              <FormStatus history={history} />
            </BoxAddSettings>
          </TabPanel>
        </>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        updateStatus={updateStatus}
      />
    </>
  );
};

export default AddCategory;
