import React, { useContext, useState } from 'react';
import { VisibleColumnsInterface } from '../../models/Filters';
import Filters from './Filters/Filters';
import Links from './Links/Links';
import FiltersClaims from './Filters/FiltersClaims';
import useOwners from '../../customHooks/useOwners';
import useProjects from '../../customHooks/useProjects';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import useLinkStatus from '../../customHooks/useLinkStatus';
import useSites from '../../customHooks/useSites';
import FiltersMaster from './Filters/FiltersMaster';
import FiltersAssigned from './Filters/FiltersAssigned';
import FiltersUnclaimed from './Filters/FiltersUnclaimed';
import useFavFilters from '../../customHooks/useFavFilters';

const DefaultView = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { owners } = useOwners({ withFields: true });
  const { projects } = useProjects({ id: UserContext.id });
  const { sites } = useSites({ userId: UserContext.id, minimal: true });
  const { statusOptions } = useLinkStatus();
  const { users: usersManagers } = useOwners({
    withFields: false,
    fullName: true,
    role: 'manager,administrator',
  });

  const [tab, setTab] = useState({ numberTab: 0, loadingTab: false });
  const { filters: favFiltersList } = useFavFilters();
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsInterface>(
    {
      site__name: true,
      owner__username: true,
      affinity__name: true,
      template__title: true,
      audience__name: true,
      found_not_null: true,
      created: true,
      modified: true,
      status: true,
      notes_count: true,
      is_unsubscribed: true,
    },
  );

  const updateTab = (value: number, prop: boolean) =>
    setTab({ numberTab: value, loadingTab: prop });

  return (
    <div className="grid_container">
      <div className="left_columns">
        {tab.numberTab === 0 && (
          <Filters
            columnsVisibility={visibleColumns}
            favFiltersList={favFiltersList}
            setColumnsVisible={setVisibleColumns}
            tab={tab}
            owners={owners}
            projects={projects}
            status={statusOptions.map(item => {
              return {
                id: item.value,
                name: item.name,
              };
            })}
          />
        )}
        {tab.numberTab === 1 && (
          <FiltersAssigned owners={owners} sites={sites} />
        )}
        {tab.numberTab === 2 && (
          <FiltersUnclaimed
            columnsVisibility={visibleColumns}
            setColumnsVisible={setVisibleColumns}
            owners={owners}
            projects={projects}
          />
        )}
        {tab.numberTab === 3 && (
          <FiltersClaims
            sites={sites}
            owners={owners}
            managers={usersManagers}
          />
        )}
        {tab.numberTab === 4 && <FiltersMaster sites={sites} owners={owners} />}
      </div>
      <div className="right_columns">
        <Links
          columnsVisibility={visibleColumns}
          updateTab={updateTab}
          statusOfLinks={statusOptions}
        />
      </div>
    </div>
  );
};

export default DefaultView;
