import { ContactsFormLink } from '../models/Forms';
import { ValidateEmailInt } from '../models/Links';

export type ContactsValuesType = {
  loading: boolean;
  validEmail: boolean | string;
  message: string;
  previousChanged: boolean;
  contact: ContactsFormLink | null;
};

export const contactValueDefault: ContactsValuesType = {
  loading: false,
  validEmail: true,
  message: '',
  previousChanged: false,
  contact: null,
};

export const contactValueDefaultError: ContactsValuesType = {
  loading: false,
  validEmail: false,
  message: '',
  previousChanged: false,
  contact: null,
};

export const contactValueDefaultLoading: ContactsValuesType = {
  loading: true,
  validEmail: '',
  message: '',
  previousChanged: false,
  contact: null,
};

export const VALID_MESSAGES = {
  unknown: 'The quality of this email is too low for outreach',
  false: 'This email cannot be validated',
  duplicated: 'This email contact already exists in the database',
  true: '',
};

export const validateResponseEmailContact = (resp: ValidateEmailInt) => {
  const contactValue = { ...contactValueDefault };
  contactValue.message =
    resp.message !== ''
      ? resp.message
      : VALID_MESSAGES[
          resp.valid_email.toString() as keyof typeof VALID_MESSAGES
        ];
  if (resp.contact !== null && resp.duplicate_contact) {
    contactValue.contact = resp.contact;
    if (contactValue.contact.last_outreach === '')
      delete contactValue.contact.last_outreach;
    contactValue.previousChanged = true;
  } else contactValue.previousChanged = false;
  if (resp.valid_email === 'unknown') contactValue.validEmail = 'unknown';
  else if (resp.valid_email === false || resp.valid_email === 'duplicated')
    contactValue.validEmail = false;
  else contactValue.validEmail = true;
  return contactValue;
};

export const contactObjectDefault = {
  name: '',
  email: '',
  is_form: false,
  status: 'open',
  is_default: false,
  validation_status: 'active',
};
