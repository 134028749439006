import { CategoryCreateInt, CategoryReq } from '../models/Category';
import { HistoryReq, NotesReq } from '../models/Notes';
import APIClient from '../services/APIClient';

export const getSingleCategory = async (id: number) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.get<CategoryReq>(
    `/categories/${id}`,
  );
  return categoryResponse.data;
};

export const createCategory = async (obj: CategoryCreateInt) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.post<CategoryReq>(
    '/categories/',
    obj,
  );
  return categoryResponse.data;
};

export const updateCategoryStatus = async (
  obj: { status: string; text_note: string },
  id: number,
) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.put<CategoryReq>(
    `/categories/${id}/`,
    obj,
  );
  return categoryResponse.data;
};

export const updateCategory = async (obj: CategoryCreateInt, id: number) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.put<CategoryReq>(
    `/categories/${id}/`,
    obj,
  );
  return categoryResponse.data;
};

export const deleteCategory = async (id: number) => {
  const clientAPI = new APIClient();
  const categoryResponse = await clientAPI.client.delete(`/categories/${id}/`);
  return categoryResponse.data;
};

export const deactivateCategory = async (id: number) => {
  const clientAPI = new APIClient();
  const categoryResponse = await clientAPI.client.post(
    `/categories/${id}/change-status/`,
  );
  return categoryResponse.data;
};

export const addNoteCategory = async (
  obj: { text_note: string },
  id: number,
) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.post(
    `/categories/${id}/add-note/`,
    obj,
  );
  return categoryResponse.data;
};

export const fetchCategoryNotes = async (id: number) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.get<NotesReq[]>(
    `/categories/${id}/list-general-note/?ordering=-created`,
  );
  return categoryResponse.data;
};

export const fetchCategoryHistory = async (id: number) => {
  const categoryAPI = new APIClient();
  const categoryResponse = await categoryAPI.client.get<HistoryReq[]>(
    `/categories/${id}/list-status-note/?ordering=-created`,
  );
  return categoryResponse.data;
};
