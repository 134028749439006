import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LeftColumns from '../../../components/Columns/LeftColumns';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import { BasicArray } from '../../../models/General';
import {
  SelectFormBulk,
  SelectFormProspecting,
} from '../../../components/Forms/FormInputs';
import { filtersObjMaster } from '../../../models/Filters';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import { Owners } from '../../../models/User';
import { TextInputField } from '../../../components/inputs';
import { onlyNumbers } from '../../../helpers/validateInputs';
import LoadingContext from '../../../context/LoadingContext';
import DateFilters from '../../../components/Filters/DateFilters';
import useFiltersMaster from '../../../stores/filters/useFiltersMaster';

interface FiltersClaimsProps {
  sites: BasicArray[];
  owners: Owners[];
}

const FiltersMaster = ({ sites, owners }: FiltersClaimsProps) => {
  const filterMaster = useFiltersMaster();
  const [render, setRender] = useState(0);
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;

  const createQuery = () => {
    const copyFilters = JSON.parse(
      JSON.stringify(filterMaster.filterValuesDefault),
    );

    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    setGlobalContext({
      ...GlobalContext,
      links: { ...GlobalContext.links, order: '-created' },
      unclaimed: { ...GlobalContext.unclaimed, order: '-created' },
      claims: { ...GlobalContext.claims, order: 'modified' },
      master: {
        ...GlobalContext.master,
        query_filters: queryString.join(''),
        page: 1,
        page_size: 10,
      },
    });
    if (render === 0) setRender(1);
  };

  useEffect(() => {
    createQuery();
  }, [filterMaster.filterValuesDefault]);

  useEffect(() => {
    filterMaster.updateFiltersChange(filtersObjMaster);
    filterMaster.updateFiltersDefault(filtersObjMaster);
  }, []);

  const updateDates = (id: string, value: string | null) => {
    let obj = { ...filterMaster.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterMaster.updateFiltersChange(obj);
  };

  const updateOptions = (id: string, value: string) => {
    let obj = { ...filterMaster.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterMaster.updateFiltersChange(obj);
  };

  const applyFilters = () =>
    filterMaster.updateFiltersDefault(filterMaster.filterValuesChange);

  const filtersArray = [
    {
      id: 2,
      title: 'Total Points',
      body: (
        <TextInputField
          value={filterMaster.filterValuesChange.total_points.value}
          name="total_points"
          disabled={false}
          size="small"
          onKeyDown={e => {
            if (e.key === 'Enter') applyFilters();
          }}
          onChange={e =>
            updateOptions('total_points', onlyNumbers(e.target.value))
          }
          fullWidth
          placeholder="Enter total points"
          id="total_points"
        />
      ),
      disabled: false,
    },
    {
      id: 3,
      title: 'Site',
      body: (
        <SelectFormProspecting
          required={false}
          options={sites}
          width
          disabled={false}
          clear
          onEnter={applyFilters}
          placeholder="Select Site"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('site', value ? value.id.toString() : '')
          }
          value={
            sites.find(
              item =>
                item.id ===
                parseInt(filterMaster.filterValuesChange.site.value ?? '0', 10),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: sites.length === 0,
    },
    {
      id: 4,
      title: 'Owner',
      body: (
        <SelectFormBulk
          required={false}
          options={owners}
          width
          disabled={false}
          placeholder="Select Owner"
          clear
          onEnter={applyFilters}
          bigger
          onChange={(value: Owners | null) =>
            updateOptions('owner', value ? value.id.toString() : '')
          }
          value={
            owners.find(
              item =>
                item.id ===
                parseInt(
                  filterMaster.filterValuesChange.owner.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, username: '' }
          }
        />
      ),
      disabled: owners.length === 0,
    },
    {
      id: 10,
      title: 'Approved By',
      body: (
        <SelectFormBulk
          required={false}
          options={owners}
          width
          onEnter={applyFilters}
          disabled={false}
          placeholder="Select Owner"
          clear
          bigger
          onChange={(value: Owners | null) =>
            updateOptions('approved_by', value ? value.id.toString() : '')
          }
          value={
            owners.find(
              item =>
                item.id ===
                parseInt(
                  filterMaster.filterValuesChange.approved_by?.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, username: '' }
          }
        />
      ),
      disabled: sites.length === 0,
    },

    {
      id: 7,
      title: 'Created',
      body: (
        <DateFilters
          onEnter={applyFilters}
          disableInput={isLoadingContext.loading}
          min_id={filterMaster.filterValuesChange.min_added_date.id}
          min_value={filterMaster.filterValuesChange.min_added_date.value}
          max_id={filterMaster.filterValuesChange.max_added_date.id}
          max_value={filterMaster.filterValuesChange.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 9,
      title: 'Date Approved',
      body: (
        <DateFilters
          disableInput={isLoadingContext.loading}
          min_id={filterMaster.filterValuesChange.min_approved_date.id}
          min_value={filterMaster.filterValuesChange.min_approved_date.value}
          max_id={filterMaster.filterValuesChange.max_approved_date.id}
          max_value={filterMaster.filterValuesChange.max_approved_date.value}
          onEnter={applyFilters}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
  ];

  return (
    <LeftColumns>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
        }}
      >
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <Typography
          onClick={() => {
            if (isLoadingContext.loading) return;

            filterMaster.updateFiltersChange(filtersObjMaster);
            filterMaster.updateFiltersDefault(filtersObjMaster);
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </Box>
      <Box marginTop={2.4}>
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            body={filter.body}
            disabled={filter.disabled}
            columnsVisibility={undefined}
            visibilityId="site"
          />
        ))}
      </Box>
    </LeftColumns>
  );
};

export default FiltersMaster;
