import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LeftColumns from '../../../components/Columns/LeftColumns';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import {
  BasicSelect,
  SelectFormBulk,
  SelectFormProspecting,
} from '../../../components/Forms/FormInputs';
import { filtersAssigned } from '../../../models/Filters';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import LoadingContext from '../../../context/LoadingContext';
import { Owners, UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import useFiltersAssigned from '../../../stores/filters/useFiltersAssigned';
import { BasicArray } from '../../../models/General';
import DateFilters from '../../../components/Filters/DateFilters';
import { BasicProps } from '../../../models/Sites';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import { fetchTemplatesFilter } from '../../../helpers/templates';
import { SearchTextField } from '../../../components/inputs';

interface FiltersClaimsProps {
  owners: Owners[];
  sites: BasicArray[];
}

const statusOptions = [
  { value: 'assigned', label: 'Assigned' },
  { value: 'owned', label: 'Owned' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'imported', label: 'Imported' },
];

const rolesFilterOwner = ['administrator', 'dev admin', 'strategist'];

const FiltersAssigned = ({ owners, sites }: FiltersClaimsProps) => {
  const filterAssigned = useFiltersAssigned();

  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<BasicProps[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<BasicProps[]>([]);
  const [templateOptions, setTemplateOptions] = useState<BasicProps[]>([]);

  const { UserContext } = useContext(Context) as UserContextType;
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  // const [filtersValues, setFiltersValues] = useState<FiltersAssignedInterface>(
  //   filtersAssigned(UserContext.id),
  // );

  const createQuery = () => {
    const copyFilters = JSON.parse(
      JSON.stringify(filterAssigned.filterValuesDefault),
    );

    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    setGlobalContext({
      ...GlobalContext,
      unclaimed: { ...GlobalContext.unclaimed, order: '-created' },
      claims: { ...GlobalContext.claims, order: 'modified' },
      master: { ...GlobalContext.master, order: 'link' },
      links: { ...GlobalContext.links, order: '-created' },
      assigned_me: {
        ...GlobalContext.assigned_me,
        query_filters: queryString.join(''),
        page: 1,
        page_size: 10,
      },
    });
  };

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const fetchingTemplates = async (
    site_id: number,
    aud_id: number,
    aff_id: number,
  ) => {
    abortControllerTemplate.current = new AbortController();
    try {
      const resp = await fetchTemplatesFilter(
        aud_id,
        aff_id,
        site_id,
        abortControllerTemplate.current.signal,
      );

      const tempsFilter = resp
        .filter(template => template.status === 'active')
        .map(item => {
          return { id: item.id, name: item.title, status: item.status };
        });

      return tempsFilter;
    } catch (err) {
      return [];
    }
  };

  const fetchingAudiences = async (site_id: number) => {
    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site_id,
        abortControllerAudience.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const fetchingAffinities = async (site_id: number, audience_id: number) => {
    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audience_id,
        site_id,
        abortControllerAffinity.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const getAudiences = async () => {
    setLoadingAud(true);

    cancelRequestAud();
    cancelRequestAff();
    cancelRequestTemplate();

    const siteId = parseInt(filterAssigned.filterValuesChange.site.value, 10);
    const resp = await fetchingAudiences(siteId);

    setAudienceOptions(resp);

    setLoadingAud(false);
  };

  const getAffinities = async () => {
    setLoadingAff(true);

    cancelRequestAff();
    cancelRequestTemplate();

    const siteId = parseInt(filterAssigned.filterValuesChange.site.value, 10);
    const audienceId = parseInt(
      filterAssigned.filterValuesChange.audience.value,
      10,
    );

    const resp = await fetchingAffinities(siteId, audienceId);

    setAffinityOptions(resp);

    setLoadingAff(false);
  };

  const getTemplates = async () => {
    setLoadingTemplates(true);

    cancelRequestTemplate();

    const siteId = parseInt(filterAssigned.filterValuesChange.site.value, 10);
    const audienceId = parseInt(
      filterAssigned.filterValuesChange.audience.value,
      10,
    );
    const affinityId = parseInt(
      filterAssigned.filterValuesChange.affinity.value,
      10,
    );

    const resp = await fetchingTemplates(siteId, audienceId, affinityId);

    setTemplateOptions(resp);

    setLoadingTemplates(false);
  };

  useEffect(() => {
    if (filterAssigned.filterValuesChange.affinity.value !== '') getTemplates();
  }, [filterAssigned.filterValuesChange.affinity.value]);

  useEffect(() => {
    if (filterAssigned.filterValuesChange.audience.value !== '')
      getAffinities();
  }, [filterAssigned.filterValuesChange.audience.value]);

  useEffect(() => {
    if (filterAssigned.filterValuesChange.site.value !== '') getAudiences();
  }, [filterAssigned.filterValuesChange.site.value]);

  useEffect(() => {
    createQuery();
  }, [filterAssigned.filterValuesDefault]);

  useEffect(() => {
    filterAssigned.updateFiltersChange(filtersAssigned(UserContext.id));
    filterAssigned.updateFiltersDefault(filtersAssigned(UserContext.id));
  }, []);

  const updateDates = (id: string, value: string | null) => {
    let obj = { ...filterAssigned.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterAssigned.updateFiltersChange(obj);
  };

  const updateOptions = (id: string, value: string) => {
    let obj = { ...filterAssigned.filterValuesChange };
    if (id === 'site') {
      obj = {
        ...obj,
        [id]: { id, value },
        audience: { ...obj.audience, value: '' },
        affinity: { ...obj.affinity, value: '' },
        template: { ...obj.template, value: '' },
      };
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    } else if (id === 'audience') {
      obj = {
        ...obj,
        [id]: { id, value },
        affinity: { ...obj.affinity, value: '' },
        template: { ...obj.template, value: '' },
      };
      setAffinityOptions([]);
      setTemplateOptions([]);
    } else if (id === 'affinity') {
      obj = {
        ...obj,
        [id]: { id, value },
        template: { ...obj.template, value: '' },
      };
      setTemplateOptions([]);
    } else {
      obj = { ...obj, [id]: { id, value } };
    }
    filterAssigned.updateFiltersChange(obj);
  };

  const applyFilters = () =>
    filterAssigned.updateFiltersDefault(filterAssigned.filterValuesChange);

  const filtersArray = [
    {
      id: 0,
      title: 'Status',
      body: (
        <BasicSelect
          required
          options={statusOptions}
          nameInput="status"
          filter
          disabled={isLoadingContext.loading}
          value={filterAssigned.filterValuesChange.status.value}
          onChange={(value: string) => updateOptions('status', value)}
        />
      ),
      disabled: false,
    },
    {
      id: 1,
      title: 'Site',
      body: (
        <SelectFormProspecting
          required={false}
          options={sites}
          width
          disabled={sites.length === 0 || isLoadingContext.loading}
          clear
          onEnter={applyFilters}
          placeholder="Select Site"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('site', value ? value.id.toString() : '')
          }
          value={
            sites.find(
              item =>
                item.id ===
                parseInt(
                  filterAssigned.filterValuesChange.site.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: sites.length === 0,
    },
    {
      id: 5,
      title: 'Audience',
      body: (
        <SelectFormProspecting
          required={false}
          options={audienceOptions}
          width
          disabled={audienceOptions.length === 0 || isLoadingContext.loading}
          loading={loadingAud}
          clear
          onEnter={applyFilters}
          placeholder="Select Audience"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('audience', value ? value.id.toString() : '')
          }
          value={
            audienceOptions.find(
              item =>
                item.id ===
                parseInt(
                  filterAssigned.filterValuesChange.audience.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: audienceOptions.length === 0,
    },
    {
      id: 6,
      title: 'Affinity',
      body: (
        <SelectFormProspecting
          required={false}
          options={affinityOptions}
          width
          disabled={affinityOptions.length === 0 || isLoadingContext.loading}
          loading={loadingAff}
          clear
          onEnter={applyFilters}
          placeholder="Select Affinity"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('affinity', value ? value.id.toString() : '')
          }
          value={
            affinityOptions.find(
              item =>
                item.id ===
                parseInt(
                  filterAssigned.filterValuesChange.affinity.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: affinityOptions.length === 0,
    },
    {
      id: 7,
      title: 'Template',
      body: (
        <SelectFormProspecting
          required={false}
          options={templateOptions}
          width
          disabled={templateOptions.length === 0 || isLoadingContext.loading}
          loading={loadingTemplates}
          clear
          onEnter={applyFilters}
          placeholder="Select Template"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('template', value ? value.id.toString() : '')
          }
          value={
            templateOptions.find(
              item =>
                item.id ===
                parseInt(
                  filterAssigned.filterValuesChange.template.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: templateOptions.length === 0,
    },
    {
      id: 3,
      title: 'Created',
      body: (
        <DateFilters
          onEnter={applyFilters}
          disableInput={isLoadingContext.loading}
          min_id={filterAssigned.filterValuesChange.min_added_date.id}
          min_value={filterAssigned.filterValuesChange.min_added_date.value}
          max_id={filterAssigned.filterValuesChange.max_added_date.id}
          max_value={filterAssigned.filterValuesChange.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    // {
    //   id: 4,
    //   title: 'Modified',
    //   body: (
    //     <DateFilters
    //       onEnter={applyFilters}
    //       disableInput={isLoadingContext.loading}
    //       min_id={filterAssigned.filterValuesChange.min_mod_date.id}
    //       min_value={filterAssigned.filterValuesChange.min_mod_date.value}
    //       max_id={filterAssigned.filterValuesChange.max_mod_date.id}
    //       max_value={filterAssigned.filterValuesChange.max_mod_date.value}
    //       updateDates={updateDates}
    //     />
    //   ),
    //   disabled: false,
    // },
  ];

  if (rolesFilterOwner.includes(UserContext.profile.role)) {
    filtersArray.push({
      id: 2,
      title: 'Owner',
      body: (
        <SelectFormBulk
          required={false}
          options={owners}
          width
          disabled={false}
          placeholder="Select Owner"
          onEnter={applyFilters}
          bigger
          onChange={(value: Owners | null) =>
            updateOptions('user_id', value ? value.id.toString() : '')
          }
          value={
            owners.find(
              item =>
                item.id ===
                parseInt(
                  filterAssigned.filterValuesChange.user_id.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, username: '' }
          }
        />
      ),
      disabled: owners.length === 0,
    });
  }

  return (
    <LeftColumns>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
        }}
      >
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <Typography
          onClick={() => {
            if (isLoadingContext.loading) return;

            filterAssigned.updateFiltersChange(filtersAssigned(UserContext.id));
            filterAssigned.updateFiltersDefault(
              filtersAssigned(UserContext.id),
            );
            setAffinityOptions([]);
            setAudienceOptions([]);
            setTemplateOptions([]);
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </Box>
      <Box marginTop={2.4}>
        <Divider sx={{ border: '1px solid rgba(0, 0, 0, 0.06)' }} />
        <Box paddingTop={3} paddingBottom={1} paddingX={3}>
          <Typography
            fontSize={12}
            color="#000000"
            fontWeight={700}
            marginBottom={1.3}
          >
            Search by Query
          </Typography>

          <SearchTextField
            disabled={isLoadingContext.loading}
            value={filterAssigned.filterValuesChange.in_query.value}
            onKeyDown={e => {
              if (e.key === 'Enter') applyFilters();
            }}
            onChange={e => updateOptions('in_query', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            fullWidth
            type="text"
            placeholder="Query"
            id="in_query"
          />
        </Box>
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            body={filter.body}
            disabled={filter.disabled}
            columnsVisibility={undefined}
            visibilityId="site"
          />
        ))}
      </Box>
    </LeftColumns>
  );
};

export default FiltersAssigned;
