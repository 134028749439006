import { TableCell, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import TableCellMain from '../../../components/Table/Rows/TableCellMain';
import TableHeadComp from '../../../components/Table/TableHead.tsx/TableHeadComp';
import TableWrapper from '../../../components/Table/TableWrapper';
import { TableCellCustom } from '../../../components/tableItems';
import GeneralContext from '../../../context/GeneralActions';
import { getKeyValue, sendBackObject } from '../../../helpers/helpersfunctions';
import { GlobalContextType } from '../../../models/Context';
import {
  MenuOptionsDropdown,
  Order,
  SeverityType,
  getStatusColor,
} from '../../../models/General';
import { templatesHeadCells } from '../../../models/Table';
import {
  TemplateObjRequest,
  TemplatesRequest,
  TemplateVisibilityColumns,
} from '../../../models/Templates';
import TableCellNotes from '../../../components/Table/Rows/TableCellNotes';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { bulkTemplateStatus } from '../../../helpers/templates';
import { ROLES_TEMPLATES } from '../../../constants/user';
import { StyledTableRow } from '../../../components/LinksTable/LinksTable';

interface Props {
  templates: TemplatesRequest[];
  loading: boolean;
  countItems: number;
  error: boolean;
  fetchTemplates: () => void;
  review: boolean;
  tab: number;
  columnsVisibility: TemplateVisibilityColumns;
  showMessage: (message: string, severity: SeverityType, type: string) => void;
  handleTemplate: (
    obj: TemplatesRequest,
    option: 'view' | 'clone' | 'edit',
    tabNotes?: number,
  ) => void;
  handleStatusTemplate: (
    obj: TemplatesRequest,
    status: 'active' | 'rejected',
  ) => void;
}

const TableTemplates = ({
  templates,
  loading,
  countItems,
  error,
  fetchTemplates,
  review,
  tab,
  columnsVisibility,
  showMessage,
  handleTemplate,
  handleStatusTemplate,
}: Props) => {
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { UserContext } = useContext(Context) as UserContextType;

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('created');

  const [render, setRender] = useState(0);
  const [tableHead, setTableHead] = useState(templatesHeadCells);
  const [selected, setSelected] = useState<number[]>([]);

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setGlobalContext({
      ...GlobalContext,
      templates: {
        ...GlobalContext.templates,
        order: `${orderRequest === 'desc' ? '-' : ''}${property}`,
      },
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      templates: {
        ...GlobalContext.templates,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      templates: { ...GlobalContext.templates, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleClick = (id: number) => {
    const copyArr = [...selected];
    const selectedIndex = copyArr.indexOf(id);

    if (selectedIndex === -1) copyArr.push(id);
    else copyArr.splice(selectedIndex, 1);

    setSelected(copyArr);
  };

  const handleAllClick = () => {
    const ids = templates.map(item => item.id);
    if (selected.length < templates.length) {
      setSelected(ids);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const menuOptions: MenuOptionsDropdown[] = [
    {
      id: 1,
      title: 'Approve',
      click: (id: number) => {
        const obj = templates.find(item => item.id === id);
        if (obj) handleStatusTemplate(obj, 'active');
      },
    },
    {
      id: 2,
      title: 'Reject',
      click: (id: number) => {
        const obj = templates.find(item => item.id === id);
        if (obj) handleStatusTemplate(obj, 'rejected');
      },
    },
  ];

  const getClassName = (name: string): string =>
    getKeyValue(name)(columnsVisibility) ? 'present-column' : 'hide-column';

  useEffect(() => {
    if (render === 1) {
      const tableHeadTitle = {
        id: 'template',
        disablePadding: true,
        disableSort: false,
        main: true,
        label:
          selected.length === 0
            ? 'Template'
            : `(${selected.length}) Templates Selected`,
      };
      const copyHead = [...tableHead];
      copyHead.splice(0, 1, tableHeadTitle);
      setTableHead(copyHead);
    }
  }, [selected]);

  const handle = (
    id: number,
    option: 'view' | 'clone' | 'edit',
    tabNotes?: number,
  ) => {
    const obj = templates.find(item => item.id === id);
    handleTemplate(obj ?? TemplateObjRequest, option, tabNotes);
  };

  const handleBulk = async (option: 'active' | 'inactive') => {
    try {
      await bulkTemplateStatus(selected, option);
      setSelected([]);
      showMessage('Templates updated successfully', 'success', 'templates');
    } catch (e) {
      showMessage('Error updating templates', 'error', 'templates');
    }
  };

  return (
    <TableWrapper
      loading={loading}
      error={error}
      type="template"
      totalCells={tableHead.length - 1}
      fetchInfo={fetchTemplates}
      itemsLength={templates.length}
      countItems={countItems}
      pageSize={GlobalContext.templates.page_size}
      page={GlobalContext.templates.page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      HeadOfTable={
        <TableHeadComp
          order={order}
          handleBulk={handleBulk}
          checkbox={
            sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).approve
              ? {
                  checked: selected.length === templates.length,
                  indeterminate:
                    selected.length > 0 && selected.length < templates.length,
                  click: handleAllClick,
                }
              : undefined
          }
          bulk={
            sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).approve
              ? { disabled: selected.length === 0 }
              : undefined
          }
          headCells={tableHead}
          orderBy={orderBy}
          columnsVisibility={columnsVisibility}
          onRequestSort={handleRequestSort}
        />
      }
    >
      {!loading && templates.length > 0 && (
        <>
          {templates.map((row, index) => {
            const labelId = `template-table-checkbox-${index}`;
            return (
              <StyledTableRow
                hover
                selected={isSelected(row.id)}
                role="checkbox"
                key={row.id}
                className={`column-row ${
                  row.status === 'inactive' ? 'column-inactive' : ''
                }`}
              >
                {review && (
                  <TableCellMain
                    id={row.id}
                    title={row.title}
                    type="template"
                    handle={handle}
                    index={index === 0 ? 2 : index}
                    body={row.body}
                    menuOptions={menuOptions}
                    labelId={labelId}
                    review
                    buttons={
                      sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                        .approve
                        ? 'strategist'
                        : 'builder'
                    }
                    checkbox={
                      sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                        .approve
                        ? {
                            checked: isSelected(row.id),
                            onClick: handleClick,
                          }
                        : undefined
                    }
                    notes={row.general_notes.length}
                  />
                )}
                {!review && (
                  <TableCellMain
                    id={row.id}
                    title={row.title}
                    type="template"
                    disabledClone={row.status !== 'active'}
                    body={row.body}
                    index={index === 0 ? 2 : index}
                    handle={handle}
                    menuOptions={menuOptions}
                    labelId={labelId}
                    checkbox={
                      sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                        .approve
                        ? {
                            checked: isSelected(row.id),
                            onClick: handleClick,
                          }
                        : undefined
                    }
                    buttons={
                      sendBackObject(UserContext.profile.role, ROLES_TEMPLATES)
                        .approve
                        ? 'strategist'
                        : 'builder'
                    }
                  />
                )}
                <TableCellCustom
                  className={`table_overflow-text ${getClassName(
                    'site__name',
                  )}`}
                >
                  {row.site ? row.site.name : ''}
                </TableCellCustom>
                <TableCellCustom
                  className={`table_overflow-text ${getClassName(
                    'owner__username',
                  )}`}
                >
                  {row.owner ? row.owner.username : ''}
                </TableCellCustom>
                <TableCell className={getClassName('status')}>
                  <div className="status_row">
                    {' '}
                    <Typography
                      fontSize={14}
                      color={getStatusColor(row.status)}
                    >
                      {row.status === 'admin_removed'
                        ? 'Admin Removed'
                        : row.status}
                    </Typography>
                  </div>
                </TableCell>
                <TableCellCustom className={getClassName('created')}>
                  {dayjs(row.created).format('MM-DD-YYYY')}
                </TableCellCustom>
                <TableCellCustom className={getClassName('modified')}>
                  {dayjs(row.modified).format('MM-DD-YYYY')}
                </TableCellCustom>
                <TableCellNotes notes={row.general_notes.length} />
              </StyledTableRow>
            );
          })}
        </>
      )}
    </TableWrapper>
  );
};

export default TableTemplates;
