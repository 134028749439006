import { Box, Typography } from '@mui/material';
import React from 'react';

interface AlphProp {
  arr: [string, unknown][];
  changeQuery: (letter: string) => void;
  current: string;
}

const AlphScroll = ({ arr, current, changeQuery }: AlphProp) => {
  return (
    <Box className="alphscroll">
      {arr.map(item => (
        <Typography
          marginBottom={0.5}
          fontSize={item[0].toLowerCase() === current.toLowerCase() ? 14 : 12}
          key={item[0]}
          onClick={() => changeQuery(item[0])}
          fontWeight={
            item[0].toLowerCase() === current.toLowerCase() ? 'bold' : 'normal'
          }
          color={
            item[0].toLowerCase() === current.toLowerCase() ? 'white' : 'black'
          }
          sx={{
            cursor: 'pointer',
            background:
              item[0].toLowerCase() === current.toLowerCase() ? 'black' : '',
            borderRadius: 0.5,
            transform: 'translateZ(0)',
            transitionDuration: '0.2s',
            transitionProperty: 'transform',
            ':hover': { transform: 'scale(1.3)' },
          }}
        >
          {item[0]}
        </Typography>
      ))}
    </Box>
  );
};

export default AlphScroll;
