import { useState, useContext } from 'react';
import { getAssignedToMeCount } from '../helpers/prospecting';
import { UserContextType } from '../models/User';
import Context from '../context/UserContext';
import { GlobalContextType } from '../models/Context';
import GeneralContext from '../context/GeneralActions';

const useAssignedToMeCount = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const [countAssignedToMe, setCountAssignedToMe] = useState<number>(0);
  const [loadingCount, setLoadingCount] = useState(true);

  const getCount = () => {
    setLoadingCount(true);
    getAssignedToMeCount(UserContext.id, GlobalContext.site_selected)
      .then(res => {
        setCountAssignedToMe(res.assignments_count);
      })
      .catch(err => setCountAssignedToMe(0))
      .finally(() => setLoadingCount(false));
  };

  return { countAssignedToMe, loadingCount, getCount };
};

export default useAssignedToMeCount;
