import React, { useState } from 'react';
import {
  FormControlLabel,
  FormGroup,
  InputLabel,
  Checkbox,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../../assets/icons/google.png';
import { CssTextField } from '../../components/inputs';
import { ColorButton, GoogleButton } from '../../components/buttons';
import FeedbackMessage from '../../components/FeedbackMessage';
import { authenticate } from '../../helpers/user';
import { UserResponse, LogIn, LoginGoogle } from '../../models/User';
import routesNames from '../../routes/customRoutes';

const Login = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const userRemember = localStorage.getItem('userRemembered');
  const [user, setUser] = useState({
    username: userRemember ? JSON.parse(userRemember).username : '',
    password: userRemember ? JSON.parse(userRemember).password : '',
    remember: !!userRemember,
  });

  const authUser = async (obj: LogIn | LoginGoogle, messageError: string) => {
    setLoading(true);
    try {
      const response: UserResponse = await authenticate(obj);
      if (!response.profile.upgrade_allowed) {
        setMessage('This user is not allowed in V2');
        setOpen(true);
        setLoading(false);
        return;
      }

      if (!response.is_active) {
        setMessage('This user is not allowed to log in');
        setOpen(true);
        setLoading(false);
        return;
      }

      if (user.remember) {
        localStorage.setItem('backlink.user', JSON.stringify(response));
        localStorage.setItem('userRemembered', JSON.stringify(user));
      } else {
        sessionStorage.setItem('backlink.user', JSON.stringify(response));
        localStorage.removeItem('userRemembered');
      }
      setLoading(false);
      navigate(routesNames.home);
    } catch (err) {
      setMessage(messageError);
      setOpen(true);
      setLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // const userInfo = await axios
      //   .get('https://www.googleapis.com/oauth2/v3/userinfo', {
      //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      //   })
      //   .then(res => res.data);
      const obj: LoginGoogle = {
        googleUser: {
          email: '',
          token: tokenResponse.access_token,
        },
        user,
      };

      authUser(obj, 'Your google account is not authorized for Backlink');
    },
  });

  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authUser(user, 'Invalid Login Credentials');
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="header">
          <h2>Log In</h2>
        </div>
        <div className="body">
          <form className="form" onSubmit={e => handleForm(e)}>
            <div>
              <InputLabel htmlFor="username">Username</InputLabel>
              <CssTextField
                size="small"
                fullWidth
                value={user.username}
                onChange={e => setUser({ ...user, username: e.target.value })}
                required
                type="text"
                placeholder="Username"
                id="username"
              />
            </div>
            <div>
              <InputLabel htmlFor="password">Password</InputLabel>
              <CssTextField
                size="small"
                value={user.password}
                onChange={e => setUser({ ...user, password: e.target.value })}
                fullWidth
                required
                type="password"
                placeholder="Password"
                id="password"
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e =>
                      setUser({ ...user, remember: e.target.checked })
                    }
                    checked={user.remember}
                    sx={{
                      color: '#000000',
                      '&.Mui-checked': {
                        color: '#000000',
                      },
                    }}
                  />
                }
                label="Remember me"
              />
            </FormGroup>
            <div className="button">
              <ColorButton
                variant="contained"
                fullWidth
                type="submit"
                loading={loading}
              >
                Sign In
              </ColorButton>
            </div>
          </form>
          {/* <div className="google-sign">
            <div className="or_signin-with">
              <p>Or Sign In with</p>
            </div>
            <div>
              <GoogleButton onClick={() => googleLogin()}>
                <img src={googleIcon} alt="google" />
                <p>Google</p>
              </GoogleButton>
            </div>
          </div> */}
        </div>
      </div>
      <FeedbackMessage
        open={open}
        setOpen={setOpen}
        vertical="top"
        horizontal="center"
        severity="error"
        message={message}
      />
    </div>
  );
};

export default Login;
