import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
  TableSortLabel,
} from '@mui/material';
import { HeadCell } from '../../models/Table';
import { FlexBox, FlexBoxBetween } from '../../components/tableItems';
import { DefaultCheckbox } from '../../components/inputs';

interface ProspectingHeadEligibleProps {
  onRequestSort: (property: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  headCells: HeadCell[];
  checked: boolean;
  indeterminate: boolean;
  selected: number;
  openEditStatusBulk: () => void;
  handleClick: () => void;
}

const ProspectingHeadEligible = ({
  order,
  orderBy,
  headCells,
  checked,
  indeterminate,
  handleClick,
  selected,
  openEditStatusBulk,
  onRequestSort,
}: ProspectingHeadEligibleProps) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sx={{
              minWidth: 140,
              color: '#787878',
              fontSize: 13,
            }}
            align={headCell.main ? 'left' : 'center'}
            className={headCell.main ? 'first_column' : 'present-column'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.main ? (
              <FlexBoxBetween>
                <FlexBox>
                  <DefaultCheckbox
                    sx={{ marginLeft: 1 }}
                    size="small"
                    checked={checked}
                    indeterminate={indeterminate}
                    onClick={() => handleClick()}
                  />
                  <Typography
                    fontSize={13}
                    fontWeight={500}
                    sx={{ paddingLeft: 1 }}
                  >
                    {headCell.label}
                  </Typography>
                </FlexBox>
                <Box paddingRight={1}>
                  <button
                    type="button"
                    className="mail-merge"
                    onKeyDown={e => e.preventDefault()}
                    disabled={selected === 0}
                    onClick={openEditStatusBulk}
                  >
                    Bulk Update Status
                  </button>
                </Box>
              </FlexBoxBetween>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                disabled={headCell.disableSort}
                direction={order}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ProspectingHeadEligible;
