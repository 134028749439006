import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const googleKey: string = process.env.REACT_APP_GOOGLE_KEY || '';
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={googleKey}>
      <App />
    </GoogleOAuthProvider>
  </BrowserRouter>,
);
