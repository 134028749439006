/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MainButton } from '../../../buttons';
import { FlexBox } from '../../../tableItems';
import { DefaultCheckbox, TextInputField } from '../../../inputs';
import ButtonGroupSelect from './ButtonGroupSelect';
import { OPTIONS_SELECT } from './ConstantsClosedStatus';
import { TextFormField } from '../../FormInputs';
import {
  ContactsValuesType,
  contactValueDefaultError,
  validateResponseEmailContact,
} from '../../../../helpers/contacts';
import { validateEmail } from '../../../../helpers/user';
import { IS_USER_ADMIN_MANAGER } from '../../../../constants/user';
import Context from '../../../../context/UserContext';
import { UserContextType } from '../../../../models/User';
import { ContactsFormLink } from '../../../../models/Forms';
import { isURL } from '../../../../helpers/validateInputs';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface LinkChangesProps {
  closeType: string;
  closeReason: string;
  relevance: boolean;
}

interface ContactChangesProps {
  contactIds: number[];
  contactNamesEmails: string[];
  closeType: string;
  closeReason: string;
}

interface CloseContactModalProps {
  open: boolean;
  closeModal: () => void;
  disabledLinksModal: boolean;
  contacts: {
    id: number;
    name: string;
    contactId: number;
    status: string;
    is_form: boolean;
  }[];
  allContacts: ContactsFormLink[];
  contactsValidations: ContactsValuesType[];
  closeContactOpenLink: (contactChanges: ContactChangesProps) => void;
  closeConfirmContact: (contactChanges: ContactChangesProps) => void;
  goBackToOptions: () => void;
  updateContactsClosed: (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => void;
  updateContactsClosedAndSave: (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => void;
  linkChanges?: LinkChangesProps;
  note: string;
  updateNote: (newNote: string) => void;
  disabledSave: boolean;
  site: number;
  updateNewContact: (newContact: ContactsFormLink) => void;
}

const CloseContactModal = ({
  open,
  closeModal,
  disabledLinksModal,
  contacts,
  closeContactOpenLink,
  closeConfirmContact,
  goBackToOptions,
  updateContactsClosed,
  linkChanges,
  note,
  updateNote,
  updateContactsClosedAndSave,
  disabledSave,
  site,
  updateNewContact,
  allContacts,
  contactsValidations,
}: CloseContactModalProps) => {
  const { UserContext } = useContext(Context) as UserContextType;

  const [step, setStep] = useState(0);
  const [closeTypeSelected, setCloseTypeSelected] = useState('');
  const [closeReasonSelected, setCloseReasonSelected] = useState('');
  const [contactSelected, setContactSelected] = useState<number[]>([]);
  const [contactNamesEmails, setContactNamesEmails] = useState<string[]>([]);
  const [additionalContactAdded, setAdditionalContactAdded] = useState(false);
  const [contactValidation, setContactValidation] =
    useState<ContactsValuesType>(contactValueDefaultError);
  const [newContact, setNewContact] = useState<ContactsFormLink>({
    name: '',
    email: '',
    is_form: false,
    status: 'open',
    is_default: true,
    validation_status: 'active',
  });
  const [validatingContact, setValidatingContact] = useState(false);

  useEffect(() => {
    return () => {
      setCloseTypeSelected('');
      setStep(0);
    };
  }, []);

  useEffect(() => {
    setCloseReasonSelected('');
  }, [closeTypeSelected]);

  const changeValueTypeSelected = (value: string) =>
    setCloseTypeSelected(value);

  const changeValueReasonSelected = (value: string) =>
    setCloseReasonSelected(value);

  const handleEmailValidation = async (
    value: string,
    contactVal: ContactsValuesType,
  ) => {
    try {
      const resp = await validateEmail(value, site);
      const contactCopy = validateResponseEmailContact(resp);
      return contactCopy;
    } catch (err) {
      return contactVal;
    }
  };

  const requestEmailValidation = async (
    value: string,
    contactVal: ContactsValuesType,
  ) => {
    const contactNewValue = await handleEmailValidation(value, contactVal);

    if (
      contactNewValue.contact === null &&
      contactVal.previousChanged &&
      !contactNewValue.previousChanged
    ) {
      const contactCopy = {
        ...newContact,
        email: value,
        form_text: '',
        name: '',
        status: 'open',
      };

      contactNewValue.contact = contactCopy;
    }

    return {
      changeContact: contactNewValue.contact,
      contactNewValue,
    };
  };

  const handleEmailChange = async (value: string) => {
    setValidatingContact(true);
    if (value === '') {
      setContactValidation(contactValueDefaultError);
      setValidatingContact(false);
      return;
    }
    const repeatEmails = contacts.filter(item => item.name === value);

    if (repeatEmails.length > 0) {
      const contactCopy = { ...contactValueDefaultError };
      contactCopy.message = 'Email already exist in the contacts';
      setContactValidation(contactCopy);
      setValidatingContact(false);
      return;
    }

    setContactValidation({ ...contactValidation, message: '' });

    const statusDefault = 'active';
    const response = await requestEmailValidation(value, contactValidation);
    const { contactNewValue } = response;
    if (contactNewValue.contact !== null) {
      const copyContact = contactNewValue.contact;
      copyContact.is_default = IS_USER_ADMIN_MANAGER.includes(
        UserContext.profile.role,
      )
        ? true
        : contactNewValue.validEmail
        ? true
        : false;
      copyContact.validation_status =
        contactNewValue.contact.validation_status ?? statusDefault;
      if (!contactNewValue.contact.id) delete copyContact.id;
      setNewContact(copyContact);
    }

    setContactValidation({
      ...contactNewValue,
      validEmail: IS_USER_ADMIN_MANAGER.includes(UserContext.profile.role)
        ? true
        : contactNewValue.validEmail,
    });

    setValidatingContact(false);
  };

  const checkAllContacts = () => {
    const copyContacts = [...contacts].map(item => {
      if (contactSelected.includes(item.contactId)) {
        return {
          ...item,
          status: `${
            closeTypeSelected === 'invalid contact'
              ? 'invalid_contact'
              : `${closeTypeSelected}_closed`
          }`,
        };
      }
      return item;
    });

    const response = copyContacts.filter(
      item => item.status === 'open' && !item.is_form,
    );

    if (response.length > 0) return true;

    return closeTypeSelected !== 'invalid contact';
  };

  const checkDisabledSaveContacts = () => {
    const copyContacts = [...allContacts].map((item, index) => {
      if (contactSelected.includes(index)) {
        if (item.is_form && closeTypeSelected === 'invalid contact')
          return {
            ...item,
            name: item.name === '' ? 'Invalid Legacy From Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
            status: `${
              closeTypeSelected === 'invalid contact'
                ? 'invalid_contact'
                : `${closeTypeSelected}_closed`
            }`,
          };
        return {
          ...item,
          status: `${
            closeTypeSelected === 'invalid contact'
              ? 'invalid_contact'
              : `${closeTypeSelected}_closed`
          }`,
        };
      }
      return item;
    });

    const checkContactValidations = contactsValidations.map((item, index) => {
      if (copyContacts[index].is_form) {
        return {
          ...item,
          validEmail: isURL(copyContacts[index].form_url ?? ''),
        };
      }
      return item;
    });

    const newValidation = copyContacts.filter(
      item =>
        (!item.is_form && item.email === '') ||
        (item.is_form &&
          (item.name === '' || item.form_text === '' || item.form_url === '')),
    );

    if (
      newValidation.length > 0 ||
      checkContactValidations.filter(item => !item.validEmail).length > 0
    )
      return true;

    return false;
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box
            justifyContent={step === 1 ? 'space-between' : 'end'}
            display="flex"
          >
            {step === 1 && (
              <IconButton
                onClick={() => setStep(step - 1)}
                sx={{ fontSize: 17 }}
              >
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            )}
            <IconButton onClick={closeModal} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Close Contact
          </Typography>
          {step === 0 && (
            <>
              <Box px={4}>
                <Typography
                  marginTop={2}
                  fontSize={15}
                  marginBottom={2}
                  textAlign="center"
                >
                  Select the Contacts
                </Typography>
                <Autocomplete
                  multiple
                  value={contacts.filter(item =>
                    contactSelected.includes(item.contactId),
                  )}
                  disableCloseOnSelect
                  clearIcon={false}
                  size="small"
                  limitTags={6}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '8px 12px',
                      '&:focus fieldset': {
                        borderColor: '#D7D7D7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#ABABAB',
                      },
                    },
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                      {
                        border: '2px solid #D7D7D7',
                      },
                    '& input': {
                      padding: 0,
                      fontSize: 13,
                    },
                    '& .MuiAutocomplete-tag': {
                      fontSize: 13,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: 120,
                      borderRadius: '3px',
                    },
                  }}
                  options={contacts}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <DefaultCheckbox
                        size="small"
                        checked={selected}
                        sx={{ padding: 0, marginRight: 1.3 }}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={(e, val) => {
                    setContactSelected(
                      val.map(item => {
                        return item.contactId;
                      }),
                    );
                    setContactNamesEmails(
                      val.map(item => {
                        return item.name;
                      }),
                    );
                  }}
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField {...params} placeholder="Contacts" />
                  )}
                />
              </Box>
              <ButtonGroupSelect
                value={closeTypeSelected}
                changeValue={changeValueTypeSelected}
                type="contact"
                reasonValue={closeReasonSelected}
                changeReasonValue={changeValueReasonSelected}
              />
            </>
          )}
          {step === 2 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                Confirm all changes or go back and change them
              </Typography>
              <Box sx={{ backgroundColor: '#F7F7F7', borderRadius: 2 }}>
                <Box marginTop={2} px={3}>
                  <Typography fontWeight={500} fontSize={13} textAlign="left">
                    Contact Close
                  </Typography>
                  <Typography
                    marginTop={1}
                    fontSize={13}
                    sx={{ wordBreak: 'break-word' }}
                  >
                    Contacts: {contactNamesEmails.join(',')}
                  </Typography>
                  <Typography
                    marginTop={1}
                    fontSize={13}
                    textTransform="capitalize"
                  >
                    Option: {closeTypeSelected}
                  </Typography>
                  <Typography marginTop={1} fontSize={13}>
                    Reason:{' '}
                    {OPTIONS_SELECT(closeTypeSelected).find(
                      item => item.value === closeReasonSelected,
                    )?.label ?? ''}
                  </Typography>
                </Box>
                {!checkAllContacts() && (
                  <Box marginTop={2} px={3}>
                    <Typography fontWeight={500} fontSize={13} textAlign="left">
                      Link Close
                    </Typography>
                    <Typography
                      marginTop={1}
                      fontSize={13}
                      textTransform="capitalize"
                    >
                      Option: Invalid Contact
                    </Typography>
                  </Box>
                )}
              </Box>

              <Typography fontSize={15} textAlign="center" mt={2} mb={1}>
                Insert or change current note
              </Typography>
              <TextInputField
                value={note}
                name="status_note"
                size="small"
                rows={6}
                multiline
                onChange={e => updateNote(e.target.value)}
                fullWidth
                placeholder="Note"
                id="status_note"
              />

              <FlexBox justifyContent="center" marginTop={3}>
                <MainButton
                  onClick={() => setStep(0)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
                <MainButton
                  variant="contained"
                  sx={{
                    marginX: 1.5,
                    background: '#BAF372',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#BAF372',
                    },
                  }}
                  disabled={note === ''}
                  onClick={() => {
                    closeConfirmContact({
                      contactIds: contactSelected,
                      closeType: closeTypeSelected,
                      closeReason: closeReasonSelected,
                      contactNamesEmails,
                    });
                    updateContactsClosed(
                      closeReasonSelected,
                      contactSelected,
                      `Contact Close\nContacts: ${contactNamesEmails.join(
                        ',',
                      )}\nOption: ${closeTypeSelected}\nReason: ${
                        OPTIONS_SELECT(closeTypeSelected).find(
                          item => item.value === closeReasonSelected,
                        )?.label ?? closeReasonSelected
                      }\n\n${
                        !checkAllContacts()
                          ? `Link Close\nOption: Invalid Contact\n\n`
                          : ''
                      }Note: ${note}`,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                      true,
                    );
                  }}
                >
                  Confirm
                </MainButton>
                <MainButton
                  variant="contained"
                  sx={{
                    backgroundColor: '#84D0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#84D0F0',
                    },
                  }}
                  disabled={
                    note === '' || disabledSave || checkDisabledSaveContacts()
                  }
                  onClick={() => {
                    closeModal();
                    updateContactsClosedAndSave(
                      closeReasonSelected,
                      contactSelected,
                      `Contact Close\nContacts: ${contactNamesEmails.join(
                        ',',
                      )}\nOption: ${closeTypeSelected}\nReason: ${
                        OPTIONS_SELECT(closeTypeSelected).find(
                          item => item.value === closeReasonSelected,
                        )?.label ?? closeReasonSelected
                      }\n\n${
                        !checkAllContacts()
                          ? `Link Close\nOption: Invalid Contact\n\n`
                          : ''
                      }Note: ${note}`,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                      true,
                    );
                  }}
                >
                  Confirm & Save
                </MainButton>
              </FlexBox>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                Would you like to close the Link attached to this{' '}
                {contactSelected.length > 1 ? 'Contacts' : 'Contact'}?
              </Typography>

              <FlexBox justifyContent="center" marginTop={3}>
                <MainButton
                  onClick={() => setStep(0)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
                <MainButton
                  onClick={() => setStep(2)}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    marginX: 1.5,
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  No
                </MainButton>
                <MainButton
                  variant="contained"
                  disabled={false}
                  onClick={() => {
                    closeContactOpenLink({
                      contactIds: contactSelected,
                      closeType: closeTypeSelected,
                      closeReason: closeReasonSelected,
                      contactNamesEmails,
                    });
                    updateContactsClosed(
                      closeReasonSelected,
                      contactSelected,
                      `Contact Close\nContacts: ${contactNamesEmails.join(
                        ',',
                      )}\nOption: ${closeTypeSelected}\nReason: ${
                        OPTIONS_SELECT(closeTypeSelected).find(
                          item => item.value === closeReasonSelected,
                        )?.label ?? closeReasonSelected
                      }\n\n${
                        !checkAllContacts()
                          ? `Link Close\nOption: Invalid Contact\n\n`
                          : ''
                      }Note: ${note}`,
                      `${
                        closeTypeSelected === 'invalid contact'
                          ? 'invalid_contact'
                          : `${closeTypeSelected}_closed`
                      }`,
                    );
                  }}
                >
                  Yes
                </MainButton>
              </FlexBox>
            </Box>
          )}
          {step === 3 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                Would you like to add an additional contact?
              </Typography>

              <FlexBox justifyContent="center" marginTop={3}>
                <MainButton
                  onClick={() => setStep(0)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
                <MainButton
                  onClick={() => {
                    if (!checkAllContacts()) setStep(2);
                    else setStep(1);
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    marginX: 1.5,
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  No
                </MainButton>
                <MainButton
                  variant="contained"
                  disabled={false}
                  onClick={() => setStep(4)}
                >
                  Yes
                </MainButton>
              </FlexBox>
            </Box>
          )}{' '}
          {step === 4 && (
            <Box>
              <Typography marginTop={2} fontSize={15} textAlign="center">
                New Contact
              </Typography>

              <Box px={5}>
                <TextFormField
                  nameInput="name"
                  disabled={false}
                  label="Name"
                  error={false}
                  value={newContact.name}
                  onChange={(value: string) =>
                    setNewContact({ ...newContact, name: value })
                  }
                  errorText=""
                  placeholder="Name"
                />

                <Box mt={2}>
                  <InputLabel
                    htmlFor="email"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Email <span className="required-fields_dot">*</span>
                  </InputLabel>
                  <TextInputField
                    value={newContact.email}
                    name="email"
                    disabled={validatingContact}
                    helperText={contactValidation.message}
                    error={contactValidation.validEmail === false}
                    size="small"
                    onBlur={e => handleEmailChange(e.target.value.trim())}
                    onChange={e =>
                      setNewContact({
                        ...newContact,
                        email: e.target.value.trim(),
                      })
                    }
                    fullWidth
                    placeholder="Email"
                    id="email"
                    InputProps={
                      validatingContact
                        ? {
                            endAdornment: (
                              <InputAdornment position="start">
                                <CircularProgress
                                  sx={{ color: '#ABABAB' }}
                                  size={16}
                                />
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: (
                              <InputAdornment position="end">
                                <>
                                  {contactValidation.validEmail === true && (
                                    <CheckCircleIcon fontSize="inherit" />
                                  )}
                                  {contactValidation.validEmail ===
                                    'unknown' && (
                                    <HelpIcon fontSize="inherit" />
                                  )}
                                  {contactValidation.validEmail === false && (
                                    <CancelIcon fontSize="inherit" />
                                  )}
                                </>
                              </InputAdornment>
                            ),
                          }
                    }
                  />
                </Box>
              </Box>

              <FlexBox justifyContent="center" marginTop={4}>
                <MainButton
                  onClick={() => setStep(1)}
                  startIcon={<ArrowBackIcon />}
                  disabled={validatingContact}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    mx: 2,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>

                <MainButton
                  variant="contained"
                  disabled={!contactValidation.validEmail || validatingContact}
                  onClick={() => {
                    setAdditionalContactAdded(true);
                    updateNewContact(newContact);
                    setStep(1);
                  }}
                >
                  Save & Continue
                </MainButton>
              </FlexBox>
            </Box>
          )}
          <Box marginTop={3.5}>
            <FlexBox justifyContent="end">
              {/* {step === 0 && !disabledLinksModal && (
                <MainButton
                  onClick={goBackToOptions}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Go Back
                </MainButton>
              )} */}
              {step === 0 && (
                <MainButton
                  variant="contained"
                  onClick={() => {
                    if (!disabledLinksModal && !additionalContactAdded)
                      setStep(3);
                    if (disabledLinksModal) setStep(2);
                    if (!disabledLinksModal && additionalContactAdded)
                      setStep(1);
                  }}
                  disabled={
                    closeTypeSelected === '' ||
                    closeReasonSelected === '' ||
                    contactSelected.length === 0
                  }
                  sx={{ marginLeft: 1 }}
                >
                  Next
                </MainButton>
              )}
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default CloseContactModal;
