import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import { HistoryReq } from '../../models/Notes';

interface Props {
  history: HistoryReq[];
}

const FormStatus = ({ history }: Props) => {
  const [pagination, setPagination] = useState(5);
  const results = history.slice(0, pagination);
  return (
    <Box>
      {results.map(item => (
        <Grid container spacing={2} key={item.id} marginBottom={4}>
          <Grid item xs={2.5}>
            <Box width="60%">
              <div className="history_status">
                {item.status ?? item.new_status}
              </div>
            </Box>
          </Grid>
          <Grid item xs={9.5}>
            <Typography fontWeight={400} color="#787878" fontSize={15}>
              {dayjs(item.created).format('MM/DD/YYYY')} by{' '}
              <Typography
                display="inline"
                fontWeight={600}
                color="#787878"
                fontSize={15}
              >
                {item.user ? item.user.username : ''}
              </Typography>
            </Typography>
            <Typography marginTop={1} fontSize={15}>
              {item.body ?? ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {history.length > pagination && (
        <Box display="flex" justifyContent="center" marginTop={1}>
          <IconButton
            size="large"
            sx={{ borderRadius: 20 }}
            onClick={() => setPagination(pagination + 5)}
          >
            <Typography>Load more...</Typography>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FormStatus;
