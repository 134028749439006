import React, { useContext, useState } from 'react';
import { grey } from '@mui/material/colors';
import { TableRow, styled } from '@mui/material';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import { LinkObject, ScanStatusLink } from '../../../models/Links';
import { VisibleColumnsInterface } from '../../../models/Filters';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { MenuOptionsDropdown, Order } from '../../../models/General';
import GeneralContext from '../../../context/GeneralActions';
import { GlobalContextType } from '../../../models/Context';
import { ROLES_LINKS } from '../../../constants/user';
import { getKeyValue, sendBackObject } from '../../../helpers/helpersfunctions';
import TableWrapper from '../../../components/Table/TableWrapper';
import TableHeadComp from '../../../components/Table/TableHead.tsx/TableHeadComp';
import { linkUnclaimedHeadCells } from '../../../models/Table';
import TableCellMain from '../../../components/Table/Rows/TableCellMain';
import { TableCellCustom } from '../../../components/tableItems';
import MainDrawer from '../../../components/Drawers/MainDrawer';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: grey[200],
  },
}));

interface Props {
  links: LinkObject[];
  loading: boolean;
  columnsVisibility: VisibleColumnsInterface;
  countItems: number;
  error: boolean;
  fetchLinks: () => void;
  editLink: (
    link: LinkObject,
    statusLink: string,
    tabEdit?: number,
    unclaimed?: boolean,
  ) => void;
  deleteLinkAction: (id: number) => void;
  handleSendEmail: (
    link: LinkObject,
    statusLink: string,
    unclaimed?: boolean,
  ) => void;
}

const UnclaimedTab = ({
  links,
  loading,
  countItems,
  error,
  fetchLinks,
  columnsVisibility,
  editLink,
  handleSendEmail,
  deleteLinkAction,
}: Props) => {
  dayjs.extend(relativetime);
  const { UserContext } = useContext(Context) as UserContextType;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [order, setOrder] = React.useState<Order>('desc');
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [orderBy, setOrderBy] = React.useState<string>('created');

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    const propertyChanged =
      property === 'found_not_null'
        ? `${orderRequest === 'desc' ? property : '-found_not_null'},${
            orderRequest === 'desc' ? '-' : ''
          }found`
        : property;
    setGlobalContext({
      ...GlobalContext,
      unclaimed: {
        ...GlobalContext.unclaimed,
        order: `${orderRequest === 'desc' ? '-' : ''}${propertyChanged}`,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      unclaimed: { ...GlobalContext.unclaimed, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      unclaimed: {
        ...GlobalContext.unclaimed,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleEdit = (id: number, tab?: number) => {
    const link = links.find(item => item.id === id);
    if (link) {
      editLink(link, link.status, tab ?? 4, true);
    }
  };

  const handleEmail = (id: number) => {
    const link = links.find(item => item.id === id);
    if (link) {
      handleSendEmail(link, link.status, true);
    }
  };

  const menuOptions: MenuOptionsDropdown[] = [
    {
      id: 1,
      title: 'History',
      click: (id: number) => {
        handleEdit(id, 2);
      },
    },
    {
      id: 2,
      title: 'Send New Email',
      click: (id: number) => {
        handleEmail(id);
      },
    },
  ];

  if (sendBackObject(UserContext.profile.role, ROLES_LINKS).delete) {
    menuOptions.push({
      id: 3,
      title: 'Delete',
      click: (id: number) => deleteLinkAction(id),
      delete: true,
    });
  }

  const getClassName = (name: string): string =>
    getKeyValue(name)(columnsVisibility) ? 'present-column' : 'hide-column';

  return (
    <>
      <TableWrapper
        loading={loading}
        error={error}
        type="link"
        totalCells={8}
        fetchInfo={fetchLinks}
        itemsLength={links.length}
        countItems={countItems}
        pageSize={GlobalContext.unclaimed.page_size}
        page={GlobalContext.unclaimed.page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <TableHeadComp
            order={order}
            headCells={linkUnclaimedHeadCells}
            orderBy={orderBy}
            columnsVisibility={columnsVisibility}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {!loading && links.length > 0 && (
          <>
            {links.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={row.id}
                  className="column-row"
                >
                  <TableCellMain
                    id={row.id}
                    title={row.url}
                    type="link"
                    index={index === 0 ? 2 : index}
                    notes={row.notes_count}
                    menuOptions={menuOptions}
                    labelId={labelId}
                    handleEdit={handleEdit}
                  />
                  <TableCellCustom
                    className={`table_overflow-text ${getClassName(
                      'site__name',
                    )}`}
                  >
                    {row.site.name}
                  </TableCellCustom>
                  <TableCellCustom
                    className={`table_overflow-text ${getClassName(
                      'template__title',
                    )}`}
                  >
                    {row.template ? row.template.title : ''}
                  </TableCellCustom>
                  <TableCellCustom
                    className={`table_overflow-text ${getClassName(
                      'affinity__name',
                    )}`}
                  >
                    {row.affinity ? row.affinity.name : ''}
                  </TableCellCustom>

                  <TableCellCustom
                    className={`table_overflow-text ${getClassName(
                      'audience__name',
                    )}`}
                  >
                    {row.audience ? row.audience.name : ''}
                  </TableCellCustom>
                  <TableCellCustom className={getClassName('created')}>
                    {dayjs(row.created).format('MM-DD-YYYY')}
                  </TableCellCustom>
                  <TableCellCustom className={getClassName('modified')}>
                    {dayjs(row.modified).format('MM-DD-YYYY')}
                  </TableCellCustom>
                  <TableCellCustom className={getClassName('found_not_null')}>
                    {row.found ? dayjs(row.found).format('MM-DD-YYYY') : ''}
                  </TableCellCustom>
                  <TableCellCustom className={getClassName('owner__username')}>
                    {row.owner ? row.owner.username : ''}
                  </TableCellCustom>
                </StyledTableRow>
              );
            })}
          </>
        )}
      </TableWrapper>
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {}
      </MainDrawer>
    </>
  );
};
export default UnclaimedTab;
