import { useState, useEffect } from 'react';
import { LinkStatuses } from '../models/Status';
import getAllStatus from '../helpers/status';

const useLinkStatus = () => {
  const [statusOptions, setStatusOptions] = useState<LinkStatuses[]>([]);
  const [loadingStatusOptions, setLoadingStatusOptions] = useState(false);

  const getInfo = () => {
    setLoadingStatusOptions(true);
    getAllStatus()
      .then(res => setStatusOptions(res))
      .catch(err => setStatusOptions([]))
      .finally(() => setLoadingStatusOptions(false));
  };

  useEffect(getInfo, []);

  return { statusOptions, loadingStatusOptions };
};

export default useLinkStatus;
