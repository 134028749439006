import { ROLES_USER_PERMIT } from '../constants/user';
import { ProjectSitesInt } from '../models/Projects';

export const getKeyValue = (key: string) => (obj: Record<any, any>) => obj[key];

export const findWords = (query: string, array: ProjectSitesInt[]) => {
  const newArray = array
    .filter(
      project => project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    )
    .map(word => {
      const startPos = word.name.toLowerCase().indexOf(query.toLowerCase());
      const endPos = query.length + startPos;
      return {
        ...word,
        startPos,
        endPos,
      };
    });

  return newArray;
};

export const isUserRouteValid = (
  role: keyof typeof ROLES_USER_PERMIT,
  path: string,
) => ROLES_USER_PERMIT[role].includes(path);

export const sendRoute = (role: keyof typeof ROLES_USER_PERMIT) =>
  ROLES_USER_PERMIT[role][0];

export const sendBackObject = (
  role: keyof typeof ROLES_USER_PERMIT,
  object: any,
) =>
  object[role] ?? {
    add: true,
    edit: true,
    delete: true,
    assignee: true,
    approve: true,
  };
