import { Typography, Divider } from '@mui/material';
import React from 'react';
import { MainButton } from '../buttons';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import { ContactsFormLink } from '../../models/Forms';
import StatusClosed from '../Forms/Links/StatusClosed/StatusClosed';
import { ContactsValuesType } from '../../helpers/contacts';

interface Props {
  threeButtons: boolean;
  title: string;
  disabled: boolean;
  disabledEmailSave?: boolean;
  closeDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  saveFunction: () => void;
  saveFunctionEmail?: () => void;
  closeConfirmation?: () => void;
  closeButton?: {
    disabled: boolean;
    site: number;
    contacts: ContactsFormLink[];
    allContacts: ContactsFormLink[];
    contactsValidations: ContactsValuesType[];
    updateNewContact: (newContact: ContactsFormLink) => void;
    updateContactsClosed: (
      close_reason: string,
      contactIds: number[],
      newNote: string,
      status: string,
    ) => void;
    updateContactsClosedAndSave: (
      close_reason: string,
      contactIds: number[],
      newNote: string,
      status: string,
    ) => void;
    updateLinkClosed: (
      close_reason: string,
      status: string,
      newNote: string,
      relevance_close?: boolean | undefined,
    ) => void;
    updateLinkClosedAndSave: (
      close_reason: string,
      status: string,
      newNote: string,
      relevance_close?: boolean | undefined,
    ) => void;
    disabledSave: boolean;
  };
}

const HeaderDrawer = ({
  threeButtons,
  title,
  disabled,
  closeDrawer,
  loading,
  saveFunction,
  saveFunctionEmail,
  closeConfirmation,
  closeButton,
  disabledEmailSave,
}: Props) => {
  return (
    <>
      <FlexBoxBetween padding="15px 25px">
        <Typography fontWeight={700} fontSize={18} textTransform="capitalize">
          {title}
        </Typography>
        <FlexBox>
          <MainButton
            onClick={
              closeConfirmation
                ? () => closeConfirmation()
                : () => closeDrawer(false)
            }
            variant="contained"
            sx={{
              marginRight: closeButton ? 1.5 : '',
              backgroundColor: '#FFFFFF',
              color: 'black',
              borderColor: 'grey',
              border: 1,
              '&:hover': {
                backgroundColor: '#FFFFFF',
              },
            }}
          >
            Cancel
          </MainButton>
          {closeButton && (
            <StatusClosed
              updateNewContact={closeButton.updateNewContact}
              disabled={closeButton.disabled}
              allContacts={closeButton.allContacts}
              contactsValidations={closeButton.contactsValidations}
              site={closeButton.site}
              contacts={closeButton.contacts}
              updateContactsClosed={closeButton.updateContactsClosed}
              updateLinkClosed={closeButton.updateLinkClosed}
              updateLinkClosedAndSave={closeButton.updateLinkClosedAndSave}
              updateContactsClosedAndSave={
                closeButton.updateContactsClosedAndSave
              }
              disabledSave={closeButton.disabledSave}
            />
          )}
          <MainButton
            loading={loading || false}
            variant="contained"
            sx={{
              marginX: 1.5,
              background: '#BAF372',
              color: 'black',
              '&:hover': {
                backgroundColor: '#BAF372',
              },
            }}
            disabled={disabled}
            onClick={() => saveFunction()}
          >
            Save
          </MainButton>
          {threeButtons && (
            <MainButton
              variant="contained"
              disabled={disabledEmailSave}
              loading={loading}
              sx={{
                backgroundColor: '#84D0F0',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#84D0F0',
                },
              }}
              onClick={() => (saveFunctionEmail ? saveFunctionEmail() : {})}
            >
              Save + Email
            </MainButton>
          )}
        </FlexBox>
      </FlexBoxBetween>
      <Divider />
    </>
  );
};

export default HeaderDrawer;
