import { Box, TableRow, TableCell, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DefaultCheckbox } from '../inputs';
import { UserContextType, UserResponse } from '../../models/User';
import { GlobalContextType } from '../../models/Context';
import GeneralContext from '../../context/GeneralActions';
import TableWrapper from '../Table/TableWrapper';
import { userHeadCells } from '../../models/Table';
import TableHeadComp from '../Table/TableHead.tsx/TableHeadComp';
import { MenuOptionsDropdown, Order } from '../../models/General';
import { TableCellCustom } from '../tableItems';
import LittleMenu from '../Drawers/LittleMenu';
import Context from '../../context/UserContext';
import { sendBackObject } from '../../helpers/helpersfunctions';
import { ROLES_USER_PAGE } from '../../constants/user';
import { StyledTableRow } from '../LinksTable/LinksTable';

interface Props {
  loading: boolean;
  users: UserResponse[];
  countUsers: number;
  error: boolean;
  refreshUsers: () => void;
  openEdit: (userEdit: UserResponse) => void;
  changeStatus: (id: number, status: boolean) => void;
}

const orderOptions = {
  role: 'role_name',
  status: 'is_active',
  sites: 'total_allocated_sites',
  user: 'username',
};

const visibility = {
  user: true,
  role: true,
  status: true,
  sites: true,
  options: true,
};

const UserTable = ({
  loading,
  users,
  countUsers,
  error,
  refreshUsers,
  openEdit,
  changeStatus,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(0);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('user');
  const [selected, setSelected] = useState<number[]>([]);

  const menuOptions: MenuOptionsDropdown[] = [
    {
      id: 1,
      title:
        UserContext.profile.role === 'strategist' ? 'View User' : 'Edit User',
      click: (id: number) => {
        setOpen(0);
        const userEdit = users.find(item => item.id === id);
        if (userEdit) openEdit(userEdit);
      },
    },
    {
      id: 2,
      title: 'Deactivate',
      click: (id: number) => {
        changeStatus(id, false);
      },
    },
  ];

  const menuOptionsActivate: MenuOptionsDropdown[] = [
    {
      id: 1,
      title:
        UserContext.profile.role === 'strategist' ? 'View User' : 'Edit User',
      click: (id: number) => {
        setOpen(0);
        const userEdit = users.find(item => item.id === id);
        if (userEdit) openEdit(userEdit);
      },
    },
    {
      id: 2,
      title: 'Activate',
      click: (id: number) => {
        changeStatus(id, true);
      },
    },
  ];

  if (sendBackObject(UserContext.profile.role, ROLES_USER_PAGE).delete) {
    menuOptions.push({
      id: 3,
      title: 'Delete',
      delete: true,
      click: (id: number) => {},
    });
    menuOptionsActivate.push({
      id: 3,
      title: 'Delete',
      delete: true,
      click: (id: number) => {},
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      users: { ...GlobalContext.users, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      users: {
        ...GlobalContext.users,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleClick = (id: number) => {
    const copyArr = [...selected];
    const selectedIndex = copyArr.indexOf(id);

    if (selectedIndex === -1) copyArr.push(id);
    else copyArr.splice(selectedIndex, 1);

    setSelected(copyArr);
  };

  const handleAllClick = () => {
    const ids = users.map(item => item.id);
    if (selected.length < users.length) {
      setSelected(ids);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    const newOrder = `${orderRequest === 'desc' ? '-' : ''}${
      orderOptions[property as keyof typeof orderOptions]
    }`;
    setGlobalContext({
      ...GlobalContext,
      users: {
        ...GlobalContext.users,
        order: newOrder,
      },
    });
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <TableWrapper
      loading={loading}
      error={error}
      type="user"
      totalCells={userHeadCells.length - 1}
      itemsLength={users.length}
      fetchInfo={refreshUsers}
      countItems={countUsers}
      pageSize={GlobalContext.users.page_size}
      page={GlobalContext.users.page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      HeadOfTable={
        <TableHeadComp
          columnsVisibility={visibility}
          headCells={userHeadCells}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          checkbox={{
            checked: selected.length === users.length,
            indeterminate:
              selected.length > 0 && selected.length < users.length,
            click: handleAllClick,
          }}
        />
      }
    >
      {!loading && users.length > 0 && (
        <>
          {users.map((user, index) => {
            const labelId = `template-user-checkbox-${index}`;
            return (
              <StyledTableRow
                hover
                role="checkbox"
                key={user.id}
                selected={isSelected(user.id)}
                className="column-row"
              >
                <TableCell
                  component="th"
                  className="first_column"
                  scope="row"
                  sx={{ backgroundColor: index % 2 === 0 ? '#eeeeee' : '' }}
                  padding="none"
                  id={labelId}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingX: 1,
                      width: 400,
                      minWidth: 150,
                      maxWidth: 450,
                    }}
                  >
                    <DefaultCheckbox
                      checked={isSelected(user.id)}
                      onClick={() => handleClick(user.id)}
                    />
                    <Box
                      sx={{
                        backgroundColor: '#D9D9D9',
                        paddingY: 1.7,
                        borderRadius: 100,
                        width: 50,
                        marginLeft: 3,
                      }}
                    >
                      <Typography
                        color="rgba(0, 0, 0, 0.4)"
                        fontWeight={700}
                        textTransform="uppercase"
                        fontSize={16}
                        letterSpacing={0.5}
                        textAlign="center"
                      >
                        {user.first_name[0] ?? 'U'}
                        {user.last_name[0] ?? 'U'}
                      </Typography>
                    </Box>
                    <Box marginLeft={2}>
                      <Typography color="#373737" fontSize={14}>
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography color="#737373" fontSize={12} marginTop={0.4}>
                        {user.email}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCellCustom>{user.role_name}</TableCellCustom>
                <TableCellCustom>
                  {user.is_active ? 'Active' : 'Inactive'}
                </TableCellCustom>
                <TableCellCustom>
                  {user.total_allocated_sites ?? 0}
                </TableCellCustom>
                <TableCell>
                  <Box
                    sx={{
                      flexGrow: 1,
                      position: 'relative',
                    }}
                  >
                    <LittleMenu
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      open={open}
                      id={user.id}
                      setOpen={setOpen}
                      menuOptions={
                        user.is_active ? menuOptions : menuOptionsActivate
                      }
                    />
                  </Box>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </>
      )}
    </TableWrapper>
  );
};

export default UserTable;
