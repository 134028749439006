/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ProjectSitesSelectTable,
  ProjectSitesSelectedInt,
  ProjectsRequest,
} from '../../../../models/Projects';
import { DefaultCheckbox } from '../../../inputs';
import { FlexBox } from '../../../tableItems';
import DateInputPS from './DateInputPS';

interface Props {
  project: ProjectsRequest;
  arrId: number;
  handleChecks: (
    arrId: number,
    item: ProjectSitesSelectedInt[],
    projectIs: boolean,
  ) => void;
  arr: ProjectSitesSelectedInt[];
  isProject: boolean;
}

const RowProjectSites = ({
  project,
  handleChecks,
  arrId,
  arr,
  isProject,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState<ProjectSitesSelectTable[]>([]);
  const [selected, setSelected] = useState<ProjectSitesSelectedInt[]>([]);
  const [currentDate, setCurrentDate] = useState(
    arr.length > 0 ? arr[0].projectDate : dayjs().format('MM/DD/YYYY'),
  );

  const handleClick = (id: number, date: string, name: string) => {
    const copyArr = [...selected];
    const selectedIndex = copyArr.findIndex(item => item.id === id);
    if (selectedIndex === -1) {
      const arrCop = [...copyArr, { id, date, name, projectDate: currentDate }];
      setSelected(arrCop);
      handleChecks(arrId, arrCop, false);
      return;
    }
    copyArr.splice(selectedIndex, 1);
    setSelected(copyArr);
    handleChecks(arrId, copyArr, false);
  };

  const handleAllClick = () => {
    if (selected.length < sites.length) {
      const sitesTransform = sites.map(item => {
        return { ...item, projectDate: currentDate };
      });
      setSelected(sitesTransform);
      handleChecks(arrId, sitesTransform, true);
      return;
    }
    setSelected([]);
    handleChecks(arrId, [], false);
  };

  const handleChangeDate = (date: string, id?: number) => {
    const copyArr = [...sites];
    const selectedIndex = copyArr.findIndex(item => item.id === id);
    copyArr[selectedIndex].date = date;
    setSites(copyArr);
    const copySelected = [...selected];
    const selectedIndexDate = copySelected.findIndex(item => item.id === id);
    if (selectedIndexDate !== -1) {
      copySelected[selectedIndexDate].date = date;
      setSelected(copySelected);
      handleChecks(arrId, copySelected, false);
    }
  };

  const handleChangeAllDates = (date: string) => {
    setCurrentDate(date);
    const copyArr = [...sites];
    const transform = copyArr.map(item => {
      return { ...item, date };
    });
    setSites(transform);
    if (selected.length > 0) {
      const copySelected = [...selected];
      const transformSelected = copySelected.map(item => {
        return { ...item, date, projectDate: date };
      });
      setSelected(transformSelected);
      handleChecks(arrId, transformSelected, true);
    }
  };

  useEffect(() => {
    if (arr.length === 0) return;
    const transformArr1 = arr.map(item => item.id);
    const transformArr2 = selected.map(item => item.id);
    if (!isEqual(transformArr1, transformArr2)) {
      setSelected(arr);
    }
  }, [arr]);

  useEffect(() => {
    if (project.sites.length > 0) {
      const sitesTransform = project.sites.map(item => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
          date: dayjs().format('MM/DD/YYYY'),
        };
      });
      setSites(sitesTransform);
    }
  }, []);

  const findDate = (id: number) => {
    const findSiteDate = selected.find(item => item.id === id);
    if (findSiteDate) return findSiteDate.date;
    return undefined;
  };
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: 600, fontSize: 13, position: 'relative' }}
        >
          <DefaultCheckbox
            size="small"
            sx={{ p: 0 }}
            disabled={selected.length > 0 && !isProject}
            checked={selected.length === sites.length && isProject}
            onClick={handleAllClick}
          />
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {project.name}
          {selected.length > 0 && !isProject && (
            <Typography
              textTransform="capitalize"
              fontSize={11}
              color="#000000"
              marginLeft={1.5}
              fontWeight={400}
              display="inline"
              sx={{
                backgroundColor: '#E2E2E2',
                padding: '2px 4px',
                borderRadius: '2px',
              }}
            >
              {selected.length}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ '& > *': { borderBottom: 'unset' } }}>
          <DateInputPS
            site={{ id: 0, date: currentDate }}
            handleChangeDate={handleChangeAllDates}
            disabled={selected.length > 0 && !isProject}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: 1 }}>
              <Table>
                <TableBody>
                  {sites.map(site => (
                    <TableRow key={site.id}>
                      <TableCell component="th" scope="row">
                        <FlexBox>
                          <DefaultCheckbox
                            size="small"
                            sx={{ p: 0, marginRight: 0.7 }}
                            disabled={isProject}
                            onClick={() =>
                              handleClick(site.id, site.date, site.name)
                            }
                            checked={
                              !!selected.find(item => item.id === site.id)
                            }
                          />

                          <Typography sx={{ fontSize: 14 }}>
                            {site.name}
                          </Typography>
                        </FlexBox>
                      </TableCell>
                      <TableCell>
                        <DateInputPS
                          disabled={isProject}
                          site={{
                            id: site.id,
                            date: findDate(site.id) ?? site.date,
                          }}
                          handleChangeDate={handleChangeDate}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowProjectSites;
