/* eslint-disable no-param-reassign */
import { useContext, useState, useEffect } from 'react';
import { GlobalContextType, LoadingContextType } from '../models/Context';
import GeneralContext from '../context/GeneralActions';
import { fetchAllContacts, fetchAllLinks } from '../helpers/links';
import { LinkObject } from '../models/Links';
import LoadingContext from '../context/LoadingContext';
import { RequestContacts } from '../models/Forms';

interface UseLinksProps {
  abortLinks: any;
}

const useLinksUnclaimed = ({ abortLinks }: UseLinksProps) => {
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const { setIsLoading } = useContext(LoadingContext) as LoadingContextType;
  const [links, setLinks] = useState<LinkObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);

  const [contacts, setContacts] = useState<RequestContacts[]>([]);

  const refreshLinks = () => {
    abortLinks.current = new AbortController();
    setLoading(true);
    setIsLoading({ loading: true });
    fetchAllLinks(
      GlobalContext.unclaimed.order,
      GlobalContext.unclaimed.page,
      GlobalContext.unclaimed.page_size,
      `${GlobalContext.unclaimed.query_filters}${GlobalContext.unclaimed.search_filter}`,
      abortLinks.current.signal,
    )
      .then(resp => {
        setCount(resp.count);
        setLinks(resp.results);
        setLoading(false);
        setIsLoading({ loading: false });
        if (error) setError(false);
      })
      .catch(err => {
        if (err) {
          setError(true);
          setLoading(false);
          setIsLoading({ loading: false });
        }
        setLinks([]);
      });
  };

  const refreshContacts = (ids: number[]) => {
    fetchAllContacts(ids).then(resp => setContacts(resp));
  };

  useEffect(() => {
    if (links.length === 0) return;
    const ids = links.map(link => link.id);
    refreshContacts(ids);
  }, [links]);

  return {
    links,
    loading,
    error,
    refreshLinks,
    count,
    contacts,
  };
};

export default useLinksUnclaimed;
