import { LinkStatuses } from '../models/Status';
import APIClient from '../services/APIClient';

const getAllStatus = async () => {
  const clientAPI = new APIClient();
  const statusResponse = await clientAPI.client.get<LinkStatuses[]>(
    '/link-statuses/',
  );
  return statusResponse.data;
};

export default getAllStatus;
