import { UserProjectSites } from './Projects';

export interface LogIn {
  username: string;
  password: string;
  remember?: boolean;
}

export interface LoginGoogle {
  googleUser: { email: string; token: string };
  user: LogIn;
}

export interface ProfileUser {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  profile: {
    send_profile_setup_link: boolean;
    status_effective: string;
    cannibalization: string;
    assign_link: string;
    account: string | null;
    longevity: number;
    difficulty_minimum: number;
    difficulty_goal: number;
    preferred_name: string;
    status_notes: string[];
    role: string | null;
    is_account_manager: boolean;
    effective_start_date: string;
    effective_end_date: string | null;
    unlimited_exceptions: boolean;
    employee_title: string | null;
    monthly_exceptions: number;
    training_env_allowed: boolean;
    projects: {
      id: number;
      name: string;
      status: string;
      date_effective: string | null;
    }[];
    sites: {
      id: number;
      name: string;
      status: string;
      date_effective: string | null;
    }[];
    can_override_links: boolean;
    manager: null | { id: number; username: string; email: string };
    account_manager: null | { id: number; username: string; email: string };
  };
}

interface Groups {
  name: string;
  permissions: string[];
}

interface UserResponseProfile {
  role: any;
  training_env_allowed: boolean;
  avatar: string;
  preferred_name?: string;
  upgrade_allowed: boolean;
}

export interface UserResponse {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  is_superuser: boolean;
  avatar: string;
  is_staff: boolean;
  is_active: boolean;
  groups: Groups[];
  profile: UserResponseProfile;
  token: string;
  email: string;
  user_permissions: boolean[];
  total_sites: number;
  total_projects: number;
  total_allocated_sites: number;
  role_name: string;
  real_role?: string;
}

export type UserContextType = {
  UserContext: UserResponse;
  setUserContext: (value: UserResponse) => void;
};

export interface UserRequest {
  count: number;
  next: string;
  previous: string | null;
  results: UserResponse[];
}

export const DefaultAuth: UserResponse = {
  id: 0,
  username: '',
  first_name: '',
  last_name: '',
  is_superuser: false,
  is_staff: false,
  is_active: false,
  groups: [],
  profile: {
    role: 'linkbuilder',
    training_env_allowed: false,
    avatar: '',
    upgrade_allowed: true,
  },
  avatar: '',
  token: '',
  email: '',
  user_permissions: [],
  total_sites: 0,
  total_projects: 0,
  total_allocated_sites: 0,
  role_name: '',
};

export interface Owners {
  id: number;
  username: string;
}

interface MyProfileInfo {
  send_profile_setup_link: boolean;
  avatar: string;
  status_effective: string;
  cannibalization: string;
  assign_link: string;
  contractor: string;
  role: string;
  longevity: number;
  preferred_name: string;
  difficulty_minimum: number;
  difficulty_goal: number;
  training_env_allowed: boolean;
}

export interface MyProfile {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  profile: MyProfileInfo;
}

export interface FavoriteFilter {
  name: string;
  type: string;
  body: string;
}

export interface ListFavoriteFilter extends FavoriteFilter {
  id: number;
  user_profile: number;
}

export interface CreateUserInt {
  username: string;
  first_name: string;
  last_name: string;
  text_note: string;
  email: string;
  groups: number[];
  role_name: string;
  profile: {
    preferred_name: string;
    account: string;
    site_ids: UserProjectSites[];
    project_ids: UserProjectSites[];
    manager: number;
    status_effective: string;
    difficulty_minimum: number;
    difficulty_goal: number;
    training_env_allowed: boolean;
  };
}

export interface EditUserInt {
  username?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  email?: string;
  groups?: number[];
  is_active?: boolean;
  text_note?: string;
  role_name?: string;
  reassign_user_id?: number;
  profile?: {
    preferred_name?: string;
    account?: string;
    site_ids?: UserProjectSites[];
    employee_title?: string;
    unlimited_exceptions?: boolean;
    monthly_exceptions?: number;
    project_ids?: UserProjectSites[];
    account_manager?: number;
    manager?: number;
    status_effective?: string;
    difficulty_minimum?: number;
    difficulty_goal?: number;
    training_env_allowed?: boolean;
  };
}

export interface UserEditObj {
  first_name: string;
  last_name: string;
  preferred_name: string;
  email: string;
  username: string;
  groups: Groups[];
  employee_title: string;
  account: string;
  account_manager: number | null;
  manager: number;
  role: string;
  diff_min: string;
  diff_goal: string;
  avatar: string;
  status: string;
  status_effective_date: string;
  contractor: boolean;
  can_override_links: boolean;
  unlimited_exceptions: boolean;
  monthly_exceptions: string;
  assign_links: boolean;
  send_profile: boolean;
  training_env_allowed: boolean;
}
