import { Box, Toolbar } from '@mui/material';
import React from 'react';
import TitleButton from '../../components/Basic/TitleButton';
import SettingsOptions from '../../components/Settings/SettingsOptions';

interface Props {
  children?: React.ReactNode;
  option: number;
  title: string;
  button: boolean;
  buttonText?: string;
  buttonFunction?: () => void;
}

const SettingsStructure = ({
  children,
  option,
  title,
  button,
  buttonText,
  buttonFunction,
}: Props) => {
  return (
    <div className="grid_container_settings">
      <div className="left_columns">
        <SettingsOptions option={option} />
      </div>
      <div className="right_columns">
        <Box>
          <Toolbar />
          <Box sx={{ pt: 4 }}>
            <Box sx={{ pl: 3, pr: 4 }}>
              <TitleButton
                title={title}
                button={button}
                buttonFunction={buttonFunction}
                buttonText={buttonText}
              />
            </Box>
            {children}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default SettingsStructure;
