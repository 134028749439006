import { useEffect, useState } from 'react';
import { HistoryReq } from '../models/Notes';
import { fetchClientsHistory } from '../helpers/clients';
import { fetchCategoryHistory } from '../helpers/category';
import { fetchProjectHistory } from '../helpers/projects';
import { fetchSiteHistory } from '../helpers/sites';
import { fetchLinkHistory } from '../helpers/links';
import { fetchUserHistory } from '../helpers/user';

interface Props {
  id: number;
  type: 'category' | 'client' | 'project' | 'site' | 'link' | 'user';
}

const useHistoryTab = ({ id, type }: Props) => {
  const [history, setHistory] = useState<HistoryReq[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshHistory = (newId: number) => {
    if (newId === 0) return;
    setIsLoading(true);
    if (type === 'category') {
      fetchCategoryHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    } else if (type === 'client') {
      fetchClientsHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    } else if (type === 'project') {
      fetchProjectHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    } else if (type === 'link') {
      fetchLinkHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    } else if (type === 'user') {
      fetchUserHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    } else {
      fetchSiteHistory(newId)
        .then(resp => setHistory(resp))
        .catch(err => setHistory([]))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refreshHistory(id);
  }, []);

  return { history, isLoading, refreshHistory };
};

export default useHistoryTab;
