import { ClientsRequest, CreateClientInt } from '../models/Clients';
import { HistoryReq, NotesReq } from '../models/Notes';
import APIClient from '../services/APIClient';

export const fetchClients = async (filter?: boolean) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.get<ClientsRequest[]>(
    `/clients/${filter ? '?status=active' : ''}`,
  );
  return clientsResponse.data;
};

export const fetchSingleClient = async (id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.get<ClientsRequest>(
    `/clients/${id}`,
  );
  return clientsResponse.data;
};

export const createClient = async (obj: CreateClientInt) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.post<ClientsRequest>(
    '/clients/',
    obj,
  );
  return clientsResponse.data;
};

export const updateClientStatus = async (
  obj: { status: string; text_note: string },
  id: number,
) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.put<ClientsRequest>(
    `/clients/${id}/`,
    obj,
  );
  return clientsResponse.data;
};

export const updateClient = async (obj: CreateClientInt, id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.put<ClientsRequest>(
    `/clients/${id}/`,
    obj,
  );
  return clientsResponse.data;
};

export const deleteClient = async (id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.delete(`/clients/${id}/`);
  return clientsResponse.data;
};

export const deactivateClient = async (id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.post(
    `/clients/${id}/change-status/`,
  );
  return clientsResponse.data;
};

export const addNoteClient = async (obj: { text_note: string }, id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.post(
    `/clients/${id}/add-note/`,
    obj,
  );
  return clientsResponse.data;
};

export const fetchClientsNotes = async (id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.get<NotesReq[]>(
    `/clients/${id}/list-general-note/?ordering=-created`,
  );
  return clientsResponse.data;
};

export const fetchClientsHistory = async (id: number) => {
  const clientAPI = new APIClient();
  const clientsResponse = await clientAPI.client.get<HistoryReq[]>(
    `/clients/${id}/list-status-note/?ordering=-created`,
  );
  return clientsResponse.data;
};
