export const CLOSE_TYPE_OPTIONS = [
  { value: 'site', label: 'Site' },
  { value: 'project', label: 'Project' },
  { value: 'global', label: 'Global' },
  { value: 'invalid contact', label: 'Invalid Contact' },
];

export const SITE_REASONS = [
  { value: 'Content Quality', label: 'Content Quality' },
  { value: 'Content Out of Date', label: 'Content Out of Date' },
];

export const INVALID_CONTACT_REASONS = [
  { value: 'Email Bounced', label: 'Email Bounced' },
  { value: 'Former Employee', label: 'Former Employee' },
  { value: 'Broken Form', label: 'Broken Form' },
  { value: 'Wrong Point of Contact', label: 'Wrong Point of Contact' },
];

export const PROJECT_REASONS = [
  { value: 'Non-Profit', label: 'Non-Profit' },
  { value: 'Law Firm', label: 'Law Firm' },
  { value: 'Not .edu/.gov', label: 'Not .edu/.gov' },
  { value: 'Wrong Audience', label: 'Wrong Audience' },
];

export const GLOBAL_REASONS = [
  { value: 'Verbal DNC', label: 'Verbal DNC' },
  { value: 'Cease & Desist', label: 'Cease & Desist' },
  { value: 'Use Of Profanity', label: 'Use of Profanity' },
  { value: 'Reciprocal Linking', label: 'Reciprocal Linking' },
  { value: 'Pay to Advertise', label: 'Pay to Advertise' },
  { value: 'No external links', label: 'No external links' },
];

const optionsReasons = {
  site: SITE_REASONS,
  project: PROJECT_REASONS,
  global: GLOBAL_REASONS,
  'invalid contact': INVALID_CONTACT_REASONS,
};

export const SPECIFIC_USER = [
  'admin',
  'dev admin',
  'administrator',
  'manager',
  'trainer',
];

export const OPTIONS_SELECT = (option: string) => {
  if (option === '') return [];
  const key = option as keyof typeof optionsReasons;
  return optionsReasons[key];
};

export const MODAL_STYLE = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};
