/* eslint-disable react/no-array-index-key */
import { TableRow, TableCell, Skeleton, Box } from '@mui/material';
import React from 'react';

interface Props {
  quant: number;
  cells: number;
  type: string;
}

const LoadingTable = ({ quant, cells, type }: Props) => {
  return (
    <>
      {[...Array(quant)].map((item, index) => (
        <TableRow key={index}>
          <TableCell padding="none" component="th" scope="row">
            {type !== 'user' && (
              <Box
                sx={{
                  paddingTop: 2.3,
                  paddingBottom: 2.3,
                  paddingRight: 1,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  className="skeleton_first-column"
                />
              </Box>
            )}
            {type === 'user' && (
              <Box
                sx={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingRight: 5,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  className="skeleton_first-column-user"
                />
              </Box>
            )}
          </TableCell>
          {[...Array(cells)].map((item2, indexId) => (
            <TableCell key={indexId + quant}>
              <Skeleton variant="rectangular" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default LoadingTable;
