import { Box } from '@mui/material';
import React, { useState } from 'react';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar.tsx/Sidebar';

interface Props {
  children: React.ReactNode;
}

const GlobalNavigation = ({ children }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar open={openDrawer} />
      <Sidebar open={openDrawer} setOpen={setOpenDrawer} />
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
};

export default GlobalNavigation;
