/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface Props {
  min_id: string;
  max_id: string;
  min_value: string | null;
  max_value: string | null;
  updateDates: (id: string, value: string | null) => void;
  disableInput: boolean;
  onEnter?: () => void;
}

const calendarIcon = () => {
  return (
    <CalendarMonthIcon
      sx={{
        fontSize: '22px',
        color: 'rgba(0, 0, 0, 0.2)',
        '&:hover': { color: 'rgba(0, 0, 0, 0.4)' },
      }}
    />
  );
};

const DateFilters = ({
  min_id,
  min_value,
  max_id,
  max_value,
  updateDates,
  disableInput,
  onEnter,
}: Props) => {
  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography fontSize={13} marginBottom={1}>
          From
        </Typography>
        <DatePicker
          disabled={disableInput}
          onAccept={value => {
            updateDates(min_id, dayjs(value).format('YYYY-MM-DD'));
          }}
          value={min_value}
          components={{ OpenPickerIcon: calendarIcon }}
          InputProps={{
            sx: { flexDirection: 'row-reverse', fontSize: 14 },
            readOnly: true,
          }}
          renderInput={params => (
            <Box position="relative" display="inline-block">
              <TextField
                onKeyDown={e => {
                  if (e.key === 'Enter' && onEnter) onEnter();
                }}
                {...params}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '7px 10px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #D7D7D7',
                      borderRadius: '6px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ABABAB',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ABABAB',
                    },
                  },
                }}
              />
              {min_value && (
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '.1px',
                    margin: 'auto',
                    right: '.2rem',
                    fontSize: 10,
                  }}
                  onClick={() => updateDates(min_id, null)}
                >
                  <ClearIcon sx={{ fontSize: 18 }} />
                </IconButton>
              )}
            </Box>
          )}
          onChange={newValue => {
            if (newValue === null) {
              updateDates(min_id, null);
              return;
            }
            updateDates(min_id, dayjs(newValue).format('YYYY-MM-DD'));
          }}
        />
        <Typography fontSize={13} marginBottom={1} marginTop={1.4}>
          To
        </Typography>
        <DatePicker
          disabled={disableInput}
          value={max_value}
          components={{ OpenPickerIcon: calendarIcon }}
          InputProps={{
            sx: { flexDirection: 'row-reverse', fontSize: 14 },
            readOnly: true,
          }}
          onAccept={value => {
            updateDates(max_id, dayjs(value).format('YYYY-MM-DD'));
          }}
          renderInput={params => (
            <Box position="relative" display="inline-block">
              <TextField
                {...params}
                onKeyDown={e => {
                  if (e.key === 'Enter' && onEnter) onEnter();
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '7px 10px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #D7D7D7',
                      borderRadius: '6px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ABABAB',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ABABAB',
                    },
                  },
                }}
              />
              {max_value && (
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '.1px',
                    margin: 'auto',
                    right: '.2rem',
                    fontSize: 10,
                  }}
                  onClick={() => updateDates(max_id, null)}
                >
                  <ClearIcon sx={{ fontSize: 18 }} />
                </IconButton>
              )}
            </Box>
          )}
          onChange={newValue => {
            if (newValue === null) {
              updateDates(max_id, null);
              return;
            }
            updateDates(max_id, dayjs(newValue).format('YYYY-MM-DD'));
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DateFilters;
