import React from 'react';
import { Box, Toolbar } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const RightColumns = ({ children }: Props) => {
  return (
    <Box sx={{ marginBottom: 10 }}>
      <Toolbar />
      <Box sx={{ pt: 4, pl: 2, pr: 4 }}>{children}</Box>
    </Box>
  );
};

export default RightColumns;
