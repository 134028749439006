import { create } from 'zustand';
import { FiltersClaimsInterface, filtersObjMaster } from '../../models/Filters';

interface FiltersMasterStore {
  filterValuesDefault: FiltersClaimsInterface;
  filterValuesChange: FiltersClaimsInterface;
  updateFiltersChange: (obj: FiltersClaimsInterface) => void;
  updateFiltersDefault: (obj: FiltersClaimsInterface) => void;
}

const useFiltersMaster = create<FiltersMasterStore>(set => ({
  filterValuesDefault: filtersObjMaster,
  filterValuesChange: filtersObjMaster,
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersMaster;
