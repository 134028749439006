import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props {
  project: string;
  sites: { id: number; name: string; status: string }[];
}

const RowSite = ({ project, sites }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: 600, fontSize: 13 }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {project}
        </TableCell>
        <TableCell align="center" />
        <TableCell align="right" />
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: 1 }}>
              <Table aria-label="purchases">
                <TableBody>
                  {sites.map(site => (
                    <TableRow key={site.id}>
                      <TableCell component="th" scope="row">
                        {site.name}
                      </TableCell>
                      <TableCell>Admin, Rol, Test</TableCell>
                      <TableCell align="right">08/22/2023</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowSite;
