import { GenericAbortSignal } from 'axios';
import {
  LinksAssignedResult,
  ProspectFieldsAssign,
  ProspectingRequest,
} from '../models/Prospecting';
import APIClient from '../services/APIClient';

export const fetchProspects = async (
  page: number,
  page_size: number,
  site_id: number,
  past_year?: boolean,
  filter?: string,
  search?: string,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const prospectReponse = await clientAPI.client.get<ProspectingRequest>(
    `/prospecting/link-status/?page=${page}&page_size=${page_size}&site_id=${site_id}${
      past_year ? `&filter_last_year=${past_year}` : ''
    }${filter ? `&status=${filter}` : ''}${search ? `&q=${search}` : ''}`,
    { signal },
  );
  return prospectReponse.data;
};
export const fetchProspectsUser = async (
  filter: string,
  page: number,
  page_size: number,
  site_id: number,
  order: string,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const prospectReponse = await clientAPI.client.get<LinksAssignedResult>(
    `/prospecting/list-assignments/?page=${page}&page_size=${page_size}&ordering=${order}&site_id=${site_id}${filter}`,
    { signal },
  );
  return prospectReponse.data;
};

export const fetchProspectsUserNotes = async (
  linkId: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const prospectReponse = await clientAPI.client.get<number[]>(
    `/notes/?link=${linkId}&type=note_general`,
    { signal },
  );
  return prospectReponse.data.length;
};

export const submitProspect = async (obj: ProspectFieldsAssign[]) => {
  const clientAPI = new APIClient();
  const prospectReponse = await clientAPI.client.post(
    '/prospecting/link-assignment/',
    obj,
  );
  return prospectReponse.data;
};

export const getAssignedToMeCount = async (userId: number, site_id: number) => {
  const clientAPI = new APIClient();
  const prospectCount = await clientAPI.client.get<{
    assignments_count: number;
  }>(
    `/prospecting/count-assignments/?user_id=${userId}&site_id=${site_id}&status=assigned`,
  );
  return prospectCount.data;
};

export const bulkUpdateStatus = async (obj: {
  status: string;
  ids: number[];
}) => {
  const clientAPI = new APIClient();
  const statusResponse = await clientAPI.client.put(
    '/prospecting/bulk-update-status/',
    obj,
  );
  return statusResponse.data;
};
