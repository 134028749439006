import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { Box, FormControlLabel, InputLabel, Typography } from '@mui/material';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import BoxAddSettings from '../BoxAddSettings';
import { TextFormField, BasicSelect, SelectFormInput } from '../FormInputs';
import { DefaultCheckbox } from '../../inputs';
import { CreateUserInt, UserContextType } from '../../../models/User';
import { onlyNumbers } from '../../../helpers/validateInputs';
import { MainButton } from '../../buttons';
import ProjectsSitesModal from './ProjectsSites/ProjectsSitesModal';
import {
  ProjectSitesFinalSave,
  UserProjectSites,
} from '../../../models/Projects';
import TableSiteAccess from './ProjectsSites/TableSiteAccess';
import RowProjectSitesShow from './RowProjectSitesShow';
import {
  ACCOUNTS_USER,
  ROLES_USER,
  ROLES_USER_ACCOUNT_MANAGER,
  ROLES_USER_MANAGERS,
} from '../../../constants/user';
import DateInput from '../DateInput';
import useOwners from '../../../customHooks/useOwners';
import { SeverityType, BasicArray } from '../../../models/General';
import { createUser } from '../../../helpers/user';
import Context from '../../../context/UserContext';
import useProjects from '../../../customHooks/useProjects';
import Spinner from '../../Loading/Spinner';

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showMessage: (message: string, severity: SeverityType) => void;
  reloadData: () => void;
}

const justAdmin = ['administrator', 'dev admin'];

const AddUser = ({ setOpenDrawer, showMessage, reloadData }: Props) => {
  const { projects, loading: isLoadingProjects } = useProjects({});
  const { UserContext } = useContext(Context) as UserContextType;
  const [loadingSave, setLoadingSave] = useState(false);
  const { users } = useOwners({
    withFields: false,
    fullName: true,
    role: 'manager,administrator',
  });
  const [openModal, setOpenModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [userForm, setUserForm] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    groups: [],
    account: '',
    account_manager: 0,
    send_profile: false,
    role: '',
    preferred_name: '',
    training_environment: false,
    contractor: false,
    can_override_links: false,
    diff_min: '',
    diff_goal: '',
    employee_title: '',
    status_effective_date: dayjs().format('MM/DD/YYYY'),
    training_env_allowed: false,
  });
  const [projectsSites, setProjectsSites] = useState<ProjectSitesFinalSave[]>(
    [],
  );
  const [projectSitesIds, setProjectSitesIds] = useState<{
    site_ids: UserProjectSites[];
    project_ids: UserProjectSites[];
  }>({
    site_ids: [],
    project_ids: [],
  });

  const handleSaveProjects = (
    arr: ProjectSitesFinalSave[],
    site_ids: UserProjectSites[],
    project_ids: UserProjectSites[],
  ) => {
    setProjectSitesIds({ site_ids, project_ids });
    setProjectsSites(arr);
    setOpenModal(false);
  };

  const getRolesOptions = () => {
    if (UserContext.profile.role === 'manager') return ROLES_USER_MANAGERS;
    if (UserContext.profile.role === 'account manager')
      return ROLES_USER_ACCOUNT_MANAGER;
    return ROLES_USER;
  };

  const inputsArr = [
    {
      id: 0,
      value: userForm.first_name,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="first-name"
          label="First Name"
          value={userForm.first_name}
          error={false}
          errorText=""
          placeholder="First Name"
          onChange={(value: string) =>
            setUserForm({ ...userForm, first_name: value })
          }
        />
      ),
    },
    {
      id: 1,
      value: userForm.last_name,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="last-name"
          label="Last Name"
          value={userForm.last_name}
          error={false}
          errorText=""
          placeholder="Last Name"
          onChange={(value: string) =>
            setUserForm({ ...userForm, last_name: value })
          }
        />
      ),
    },
    {
      id: 15,
      value: userForm.preferred_name,
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={false}
          nameInput="preferred-name"
          label="Preferred Name"
          value={userForm.preferred_name}
          error={false}
          errorText=""
          placeholder="Preferred Name"
          onChange={(value: string) =>
            setUserForm({ ...userForm, preferred_name: value })
          }
        />
      ),
    },
    {
      id: 2,
      value: userForm.username,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="username"
          label="Username"
          value={userForm.username}
          error={false}
          errorText=""
          placeholder="Username"
          onChange={(value: string) =>
            setUserForm({ ...userForm, username: value })
          }
        />
      ),
    },
    {
      id: 35,
      value: userForm.employee_title,
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required={false}
          disabled={false}
          nameInput="employee_title"
          label="Employee Title"
          value={userForm.employee_title}
          error={false}
          errorText=""
          placeholder="Employee Title"
          onChange={(value: string) =>
            setUserForm({ ...userForm, employee_title: value })
          }
        />
      ),
    },
    {
      id: 3,
      value: userForm.email,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="email"
          label="Email"
          value={userForm.email}
          error={false}
          errorText=""
          placeholder="Email"
          onChange={(value: string) =>
            setUserForm({ ...userForm, email: value })
          }
        />
      ),
    },
    {
      id: 4,
      value: userForm.send_profile ? 'true' : 'false',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-15px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={false}
              sx={{ mt: '-15px', ml: 1 }}
              size="medium"
              checked={userForm.send_profile}
              onClick={() =>
                setUserForm({
                  ...userForm,
                  send_profile: !userForm.send_profile,
                })
              }
            />
          }
          label="Send Profile Setup Link"
        />
      ),
    },
    {
      id: 20,
      value: userForm.training_env_allowed ? 'true' : 'false',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-15px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={false}
              sx={{ mt: '-15px', ml: 1 }}
              size="medium"
              checked={userForm.training_env_allowed}
              onClick={() =>
                setUserForm({
                  ...userForm,
                  training_env_allowed: !userForm.training_env_allowed,
                })
              }
            />
          }
          label="Training Env Allowed"
        />
      ),
    },
    {
      id: 12,
      value: userForm.account,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <BasicSelect
          label="Account"
          required={false}
          options={ACCOUNTS_USER}
          nameInput="account"
          disabled={false}
          value={userForm.account}
          onChange={(value: string) =>
            setUserForm({ ...userForm, account: value })
          }
        />
      ),
    },
    {
      id: 5,
      value: userForm.role,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <BasicSelect
          label="Role"
          required
          options={getRolesOptions()}
          nameInput="role"
          disabled={false}
          value={userForm.role}
          onChange={(value: string) => {
            if (value === 'trainer')
              setUserForm({
                ...userForm,
                role: value,
                training_env_allowed: true,
              });
            else
              setUserForm({
                ...userForm,
                role: value,
              });
          }}
        />
      ),
    },

    // {
    //   id: 7,
    //   value: userForm.training_environment ? 'true' : 'false',
    //   fullSpace: true,
    //   required: false,
    //   validate: false,
    //   validation: [],
    //   body: (
    //     <FormControlLabel
    //       label="Training Environment Access"
    //       sx={{
    //         '& .MuiFormControlLabel-label': {
    //           fontSize: 14,
    //           color: '#000000',
    //           mt: '-15px',
    //         },
    //       }}
    //       control={
    //         <DefaultCheckbox
    //           disabled={false}
    //           sx={{ mt: '-15px', ml: 1 }}
    //           size="medium"
    //           checked={userForm.training_environment}
    //           onClick={() =>
    //             setUserForm({
    //               ...userForm,
    //               training_environment: !userForm.training_environment,
    //             })
    //           }
    //         />
    //       }
    //     />
    //   ),
    // },
    {
      id: 9,
      value: userForm.can_override_links ? 'true' : 'false',
      fullSpace: true,
      required: false,
      validate: false,
      validation: [],
      body: (
        <FormControlLabel
          label="Override Links"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
              mt: '-25px',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={false}
              sx={{ mt: '-25px', ml: 1 }}
              size="medium"
              checked={userForm.can_override_links}
              onClick={() =>
                setUserForm({
                  ...userForm,
                  can_override_links: !userForm.can_override_links,
                })
              }
            />
          }
        />
      ),
    },
    {
      id: 13,
      value: userForm.status_effective_date,
      fullSpace: true,
      required: true,
      validate: [],
      validation: [],
      body: (
        <Box>
          <InputLabel
            htmlFor="status_effective_date"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
              marginTop: '-10px',
            }}
          >
            Status Effective Date
          </InputLabel>
          <DateInput
            value={userForm.status_effective_date}
            handleChangeDate={(date: string) =>
              setUserForm({ ...userForm, status_effective_date: date })
            }
          />
        </Box>
      ),
    },
    {
      id: 10,
      value: userForm.diff_min,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="difficult-min"
          label="Difficulty Minimum"
          value={userForm.diff_min.toString()}
          error={false}
          errorText=""
          placeholder="Difficulty Minimum"
          onChange={(value: string) =>
            setUserForm({
              ...userForm,
              diff_min: onlyNumbers(value),
            })
          }
        />
      ),
    },
    {
      id: 11,
      value: userForm.diff_goal,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={false}
          nameInput="difficult-goal"
          label="Difficulty Goal"
          value={userForm.diff_goal.toString()}
          error={false}
          errorText=""
          placeholder="Difficulty Goal"
          onChange={(value: string) =>
            setUserForm({
              ...userForm,
              diff_goal: onlyNumbers(value),
            })
          }
        />
      ),
    },
  ];

  const validateInputs = () => {
    const errors = inputsArr.filter(
      input => input.required && input.value === '',
    );
    if (
      errors.length > 0 ||
      (projectSitesIds.project_ids.length === 0 &&
        projectSitesIds.site_ids.length === 0)
    ) {
      setDisabledButton(true);
      return true;
    }
    setDisabledButton(false);
    return false;
  };

  const submitUser = async (objectUser: CreateUserInt) => {
    try {
      await createUser(objectUser);
      showMessage('User created successfully', 'success');
      reloadData();
      setOpenDrawer(false);
    } catch (err) {
      showMessage('Error while creating the user', 'error');
    } finally {
      setLoadingSave(false);
    }
  };

  const handleSubmit = () => {
    setLoadingSave(true);
    const resp = validateInputs();
    if (resp) {
      setLoadingSave(false);
      return;
    }
    const objectUser: CreateUserInt = {
      username: userForm.username,
      first_name: userForm.first_name,
      last_name: userForm.last_name,
      email: userForm.email,
      text_note: 'New User',
      groups: [],
      role_name: userForm.role,
      profile: {
        account: userForm.account,
        preferred_name: userForm.preferred_name,
        manager: userForm.account_manager,
        status_effective: userForm.status_effective_date,
        difficulty_minimum: parseInt(userForm.diff_min, 10),
        difficulty_goal: parseInt(userForm.diff_goal, 10),
        project_ids: projectSitesIds.project_ids,
        site_ids: projectSitesIds.site_ids,
        training_env_allowed: userForm.training_env_allowed,
      },
    };
    submitUser(objectUser);
  };

  useEffect(() => {
    validateInputs();
  }, [userForm, projectSitesIds]);

  return (
    <>
      <HeaderDrawer
        title="Add User"
        threeButtons={false}
        loading={loadingSave}
        closeDrawer={setOpenDrawer}
        disabled={disabledButton}
        saveFunction={handleSubmit}
      />
      <BoxAddSettings title="User Info" mb={0}>
        <div className="form-grids">
          {inputsArr.slice(0, 10).map(input => (
            <div
              className={`mb-1 ${input.fullSpace ? 'whole-grid_three' : ''}`}
              key={input.id}
            >
              {input.body}
            </div>
          ))}

          <div className="mb-1 whole-grid_three">
            <SelectFormInput
              required
              clearIcon
              options={users}
              disabled={
                users.length === 0 ||
                !justAdmin.includes(UserContext.profile.role)
              }
              placeholder={
                users.length === 0 ? 'Loading Managers...' : 'Manager'
              }
              value={
                users.find(item => item.id === userForm.account_manager) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) => {
                setUserForm({
                  ...userForm,
                  account_manager: value ? (value.id as number) : 0,
                });
              }}
            />
          </div>
        </div>
      </BoxAddSettings>
      <BoxAddSettings title="Goal Settings" pt={3} mb={0}>
        <div className="form-grids">
          {inputsArr.slice(12).map(input => (
            <div
              className={`mb-1 ${input.fullSpace ? 'whole-grid_three' : ''}`}
              key={input.id}
            >
              {input.body}
            </div>
          ))}
        </div>
      </BoxAddSettings>
      <BoxAddSettings
        title="Project & Site Access"
        mb={4}
        pt={3}
        rightTitle={
          projectsSites.length > 0
            ? { title: 'Manage', onClick: () => setOpenModal(true) }
            : undefined
        }
      >
        {isLoadingProjects && <Spinner />}
        {projectsSites.length === 0 && !isLoadingProjects && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={250}
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
            borderRadius="4px"
          >
            <Typography
              color="rgba(0, 0, 0, 0.7)"
              fontSize={14}
              marginBottom={1}
            >
              No Projects/sites have been added yet
            </Typography>
            <MainButton
              variant="contained"
              sx={{
                background: 'rgba(0, 0, 0, 0.1)',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => setOpenModal(true)}
            >
              Add New
            </MainButton>
          </Box>
        )}
        {projectsSites.length > 0 && (
          <TableSiteAccess>
            {projectsSites.map(projectSite => (
              <RowProjectSitesShow key={projectSite.id} obj={projectSite} />
            ))}
          </TableSiteAccess>
        )}
      </BoxAddSettings>
      <ProjectsSitesModal
        open={openModal}
        projects={projects}
        closeModal={setOpenModal}
        sitesSelected={projectsSites}
        handleSaveProjects={handleSaveProjects}
      />
    </>
  );
};

export default AddUser;
