import { useState, useEffect } from 'react';
import { BasicArray } from '../models/General';
import { fetchAudiences } from '../helpers/audience';

const useAudience = () => {
  const [audiences, setAudiences] = useState<BasicArray[]>([]);
  const [loading, setLoading] = useState(false);

  const getAudiencesList = () => {
    setLoading(true);
    fetchAudiences()
      .then(res =>
        setAudiences(
          res.map((item: { id: number; name: string }) => {
            return { id: item.id, name: item.name };
          }),
        ),
      )
      .catch(err => setAudiences([]))
      .finally(() => setLoading(false));
  };

  useEffect(getAudiencesList, []);

  return { audiences, getAudiencesList, loading };
};

export default useAudience;
