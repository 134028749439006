import { GenericAbortSignal } from 'axios';
import { AddAffinityObj, AffinityUpdateResponse } from '../models/Affinities';
import { BasicProps } from '../models/Sites';
import APIClient from '../services/APIClient';

export const fetchAffinities = async (
  alphabet?: string,
  order?: string,
  status?: string,
  sites_ids?: number,
) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.get(
    `/v2/affinities/?ordering=${order ?? 'name'}${
      alphabet !== undefined ? `&alphabet_page=${alphabet}` : ''
    }${status ? `&status=${status}` : ''}${
      sites_ids ? `&sites_ids=${sites_ids}` : ''
    }`,
  );
  return affinitiesResponse.data;
};

export const fetchAffinitiesSearch = async (query: string, letter: string) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.get(
    `/v2/affinities/alphabet-search/?name=${query}${
      letter ? `&alphabet_page=${letter}` : ''
    }`,
  );
  return affinitiesResponse.data;
};

export const fetchAffinitiesCounter = async () => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.get(
    '/v2/affinities/alphabet-counter/',
  );
  return affinitiesResponse.data;
};

export const fetchAffinitiesForForm = async (
  aud: number,
  site_id: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.get<BasicProps[]>(
    `/v2/affinities/?audiences_related=${aud}&sites_ids=${site_id}&ordering=name`,
    { signal },
  );
  return affinitiesResponse.data;
};

export const fetchAffinityCount = async (id: number) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.get<{
    linksCount: number;
    templatesCount: number;
    sitesCount: number;
  }>(`/v2/affinities/${id}/count-associated/`);
  return affinitiesResponse.data;
};

export const addAffinity = async (obj: AddAffinityObj) => {
  const clientAPI = new APIClient();
  const affinitiesResponse =
    await clientAPI.client.post<AffinityUpdateResponse>(`/v2/affinities/`, obj);
  return affinitiesResponse.data;
};

export const updateAffinity = async (
  obj: { status: 'active' | 'inactive' },
  id: number,
) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.put<AffinityUpdateResponse>(
    `/v2/affinities/${id}/`,
    obj,
  );
  return affinitiesResponse.data;
};

export const updateAffinityStatus = async (obj: { ids: number[] }) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.post<string>(
    `/v2/affinities/change-status/`,
    obj,
  );
  return affinitiesResponse.data;
};

export const deleteAffinity = async (id: number, newId: number) => {
  const clientAPI = new APIClient();
  const affinitiesResponse = await clientAPI.client.delete(
    `/v2/affinities/${id}/${newId !== 0 ? `?affinity_id=${newId}` : ''}`,
  );
  return affinitiesResponse.data;
};
