import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectFormProspecting } from '../../../components/Forms/FormInputs';
import { BasicArray, SeverityType } from '../../../models/General';
import { MainButton } from '../../../components/buttons';
import { FlexBox } from '../../../components/tableItems';
import { bulkUpdateStatus } from '../../../helpers/prospecting';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface BulkProspectProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  ids: number[];
  showMessage: (message: string, severity: SeverityType) => void;
  refreshInfo: () => void;
}

const statusOptions = [
  { id: 'Assigned', name: 'Assigned' },
  { id: 'Owned', name: 'Owned' },
  { id: 'Rejected', name: 'Rejected' },
];

const ChangeStatus = ({
  open,
  closeModal,
  ids,
  showMessage,
  refreshInfo,
}: BulkProspectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  const sendRequest = async () => {
    setIsLoading(true);
    try {
      await bulkUpdateStatus({ status, ids });
      showMessage('Status was updated succesfully', 'success');
      refreshInfo();
      closeModal(false);
    } catch (err) {
      showMessage('Error while updating status, try again', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setStatus('');
    };
  }, [ids]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Update Status
          </Typography>
          {ids.length > 0 && (
            <Typography marginTop={2} fontSize={14} textAlign="center">
              Are you sure you want to update the status of
              {ids.length === 1 ? ' this link' : ' these links'}?
            </Typography>
          )}
          <Box marginTop={1}>
            <SelectFormProspecting
              required={false}
              width
              options={statusOptions}
              label="Status"
              disabled={statusOptions.length === 0}
              placeholder="Select status"
              value={
                statusOptions.find(item => item.id === status) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setStatus(value ? (value.id as string) : '')
              }
            />
          </Box>
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={sendRequest}
                disabled={status === '' || ids.length === 0}
                loading={isLoading}
                sx={{ marginLeft: 1 }}
              >
                Confirm
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default ChangeStatus;
