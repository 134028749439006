import { Typography, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { PropsAudienceAffinityForm } from '../../../models/Forms';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import BoxAddSettings from '../BoxAddSettings';
import { TextFormField } from '../FormInputs';
import { addAudience, fetchAudiences } from '../../../helpers/audience';
import { AddAudienceObj } from '../../../models/Audience';
import FeedbackMessage from '../../FeedbackMessage';
import { addAffinity, fetchAffinities } from '../../../helpers/affinities';

const AddAudienceAffinity = ({
  setOpenDrawer,
  option,
  showMessage,
}: PropsAudienceAffinityForm) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [showMatches, setShowMatches] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [options, setOptions] = useState<{ id: number; name: string }[]>([]);
  const [matches, setMatches] = useState<{ id: number; name: string }[]>([]);
  const [addForm, setAddForm] = useState({ name: '' });

  const handleAdd = async () => {
    setLoadingAdd(true);
    const obj: AddAudienceObj = { name: addForm.name, status: 'active' };
    try {
      if (option === 'Audience') await addAudience(obj);
      if (option === 'Affinity') await addAffinity(obj);
      setOpenDrawer(false);
      showMessage(`${option} added successfully`, option, 'success');
    } catch (err) {
      setOpenMessage(true);
    } finally {
      setLoadingAdd(false);
    }
  };

  useEffect(() => {
    if (addForm.name !== '') {
      const searchMatches = options.filter(
        item =>
          item.name.substring(0, addForm.name.length).toUpperCase() ===
          addForm.name.trim().toUpperCase(),
      );
      setMatches(searchMatches);
      setDuplicated(
        searchMatches
          .map(item => item.name.toLowerCase())
          .includes(addForm.name.toLowerCase()),
      );
    }
  }, [addForm.name]);

  const getOptionsArray = async () => {
    try {
      if (option === 'Audience') {
        const resp = await fetchAudiences(undefined, 'name');
        setOptions(resp);
        return;
      }
      const resp = await fetchAffinities(undefined, 'name');
      setOptions(resp);
    } catch (err) {
      setOptions([]);
    }
  };

  useEffect(() => {
    getOptionsArray();
  }, []);

  return (
    <>
      <HeaderDrawer
        title={`Add ${option}`}
        threeButtons={false}
        closeDrawer={setOpenDrawer}
        disabled={duplicated || addForm.name === ''}
        loading={loadingAdd}
        saveFunction={handleAdd}
      />
      <BoxAddSettings title={`${option} Info`}>
        <Typography fontSize={13} fontWeight={500} color="#787878">
          An {option} name cannot be duplicated.
        </Typography>
        <Box marginTop={3.5}>
          <TextFormField
            required
            disabled={false}
            nameInput="name"
            onFocus={() => setShowMatches(true)}
            onBlurValidate={() => setShowMatches(false)}
            label="Name"
            error={false}
            value={addForm.name}
            errorText=""
            placeholder="Name"
            onChange={(value: string) =>
              setAddForm({ ...addForm, name: value })
            }
          />
          {addForm.name !== '' && (
            <Typography
              color="#787878"
              fontSize={12}
              fontWeight={500}
              fontStyle="italic"
              marginTop={1}
              marginLeft={1}
              display="flex"
              alignItems="center"
            >
              {duplicated && !showMatches && (
                <>
                  <span>Already Exists</span>
                  <CancelIcon fontSize="inherit" sx={{ marginLeft: 0.5 }} />
                </>
              )}
              {!duplicated && !showMatches && (
                <>
                  <span>Successful</span>
                  <CheckCircleIcon
                    fontSize="inherit"
                    sx={{ marginLeft: 0.5 }}
                  />
                </>
              )}
              {matches.length === 0 && showMatches && (
                <>
                  <span>Successful</span>
                  <CheckCircleIcon
                    fontSize="inherit"
                    sx={{ marginLeft: 0.5 }}
                  />
                </>
              )}
            </Typography>
          )}
          {addForm.name !== '' && showMatches && matches.length > 0 && (
            <Box
              border="2px solid #D7D7D7"
              borderRadius="5px"
              paddingX={1.5}
              paddingY={1}
            >
              <Typography
                color="#787878"
                fontSize={12}
                fontWeight={500}
                fontStyle="italic"
              >
                Search current {option} tags
              </Typography>
              {matches.slice(0, 5).map(match => (
                <Typography marginTop={1} fontSize={14} key={match.id}>
                  <span className="bold">
                    {match.name.substring(0, addForm.name.length)}
                  </span>
                  {match.name.substring(addForm.name.length)}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </BoxAddSettings>
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity="error"
        message="There was an error please try again"
      />
    </>
  );
};

export default AddAudienceAffinity;
